import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'

function Cartao (props) {

    function teste (e){
        props.callback(e)
    }
    return(
    <>
        <Cards
            number={props.numero? props.numero.replace(/(\D)/,'').replace(/(\D)/,'').replace(/(\D)/,'').replace(/(\D)/,'') : ''}
            name={props.nome == ''?  'NOME' : props.nome}
            expiry={props.validade}
            cvc={props.cvc}
            focused={props.focused}
            callback={teste}
        />
    
    </>)
}

export default Cartao