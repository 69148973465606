import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import download from 'downloadjs'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Boletos(props) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [fk_ctr, setFk_ctr] = useState()
  const [enableDel, setenableDel] = useState('N')

  const columns = [
    { id: 'formatdata_emissao', numeric: false, label: 'Data Emissão', align: 'left', minWidth: 200 },
    { id: 'descricao', numeric: false, label: 'Descrição', align: 'left', minWidth: 150 },
    { id: 'Status', numeric: false, label: 'Status', align: 'left', minWidth: 150 },
    { id: 'formatdata_vencimento', numeric: false, label: 'Data Vencimento', align: 'left', minWidth: 150 },
    { id: 'formatdata_liquidacao', numeric: false, label: 'Data Liquidação', align: 'left', minWidth: 150 },
    { id: 'formatValor', numeric: false, label: 'Valor', align: 'left', minWidth: 150 },
    { id: 'formatLiquidado', numeric: false, label: 'Valor Liquidado', align: 'left', minWidth: 150 },
    { id: 'id_banco', numeric: false, label: 'Nosso Número', align: 'left', minWidth: 150 },
  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setenableAdd(await api.getAcessos('Boletos', 'Inclusao'))
      setenableEdit(await api.getAcessos('Boletos', 'Alteracao'))
      setenableDel(await api.getAcessos('Boletos', 'Exclusao'))

      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      console.log(query)
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        getData(event)
      }
    }

    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      console.log(window.location)
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/celg/contas_receber/boletos?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/boletos', { params: queryObject }).then(r => {
        setData(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function baixar(e, id, table){
    e.preventDefault()

    
    api.post('/boletos/downloadBoleto', {id:77}, {responseType: 'blob'}).then((response) => {
      var blob = new Blob([response.data], {
        type: 'application/pdf'
      });
      console.log(response.data)
      download(blob, 'boleto.pdf')
    }).catch( (e) => {
      if (e){
        swal.fire('Boleto nao encontrado na base Unicred!', 'Esse boleto não foi encontrado na base de dados, gere um novo boleto.', 'error')
      }
    })

    
  
      
      
  
}

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/celg/boletos');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  function handleCancel(e){
    e.preventDefault();
    window.history.back()
  }

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Boletos</h3>
          <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
          <FrTextInput
            maxLength='100'
            value={filter.fk_ctr || ''}
            onChange={handleChange}
            name="fk_ctr"
            id="fk_ctr"
            color="#389492"
            label="Nome"
            variant="outlined"
            size="small"
            style={{ width: 300 }}
            hidden
          />
           
          </form>
        </div>
        <br />
        <div className={"tableDiv" + (props.frame && data.length > 0 ? '-frame' : '')}>
          <FrTable regName="boletos" baixarBoleto={true} cancelarBoleto={true} emitirBoleto={true} emailBoleto frame={props.frame} columns={columns} searched={filtered} codeName="pk_bol" page={'Boletos'} data={data} enableEdit={false} width={150} enableDel={false} />
        </div>
        {/* <div className="regButtonsDiv">
          <button onClick={baixar}>Testar Boleto</button>
        </div> */}
        <div className="regButtonsDiv">
          <button onClick={handleCancel}>Voltar</button>
        </div>
        {props.frame ? (<div>

        </div>) : <FooterDelphus />}
      </div>
    </div>
  );
}

export default Boletos;