import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { coinDollar } from 'react-icons-kit/icomoon/coinDollar'
import { fileExcel } from 'react-icons-kit/icomoon/fileExcel'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ReactExport from "react-data-export";
import FrameF2 from '../components/FrameF2'

import download from 'downloadjs'
import Dialog from '@material-ui/core/Dialog';
import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import {mCPF} from '../utils/masks'
import FooterDelphus from '../components/FooterDelphus'
import { colors } from '../utils/vars';

import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, getUserCode } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';
import Bandeira_Cartao from './Bandeira_Cartao';

const swal = withReactContent(Swal)

function Contas_Receber(props) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [selected, setSelected] = useState([])
  const [data_venc_boleto, setData_venc_boleto] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [vencimento, setVencimento] = useState()
  const [fk_cta, setFk_cta] = useState()
  const [ass, setAss] = useState()
  const [envia_email, setEnvia_email] = useState()
  const [liquidado, setLiquidado] = useState()
  const [enableDel, setenableDel] = useState('N')
  const [comboAss, setComboAss] = useState([{ value: '', display: '' }])
  const [comboCta, setComboCta] = useState([{ value: '', display: '' }])
  const [comboFpg, setComboFpg] = useState([{ value: '', display: '' }])
  const [comboSit, setComboSit] = useState([{ value: 'L', display: 'Liquidado' }, { value: 'P', display: 'Pendente' }])
  const [comboCancelado, setComboCancelado] = useState([{ value: 'S', display: 'Sim' }, { value: 'N', display: 'Não' }])
  const [comboBoletos, setComboBoletos] = useState([{ value: 'S', display: 'Sim' }, { value: 'N', display: 'Não' }])
  const [dataPlanilha, setDataPlanilha] = useState([])  
  const [dataCancelados, setDataCancelados] = useState([])  
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const columns = [
    { id: 'numdoc', numeric: false, label: 'Número doc.', align: 'left', minWidth: 200 },
    { id: 'associado', numeric: false, label: 'Associado', align: 'left', minWidth: 200 },
    { id: 'tem_boleto', numeric: false, label: 'Boleto Gerado', align: 'left', minWidth: 100 },
    { id: 'formatdata_emissao', numeric: false, label: 'Dt. Emissão', align: 'left', minWidth: 200, order: 'data_emissao' },
    { id: 'formatdata_vencimento', numeric: false, label: 'Dt. Vencimento', align: 'left', minWidth: 200, order: 'data_vencimento' },
    { id: 'formapgto', numeric: false, label: 'Forma de Pagamento', align: 'left', minWidth: 200 },
    { id: 'formatValor', numeric: false, label: 'Valor', align: 'left', minWidth: 100 },
    { id: 'formatValor_pendente', numeric: false, label: 'Valor Pendente', align: 'left', minWidth: 200 },
    { id: 'formatdata_liquidacao', numeric: false, label: 'Dt. liquidação', align: 'left', minWidth: 150, order: 'data_liquidacao' },
  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setComboAss(await api.getComboData('associados'))
      setComboCta(await api.getComboData('contas'))
      setComboFpg(await api.getComboData('forma_pagamento'))

      setenableAdd(await api.getAcessos('Contas a Receber', 'Inclusao'))
      setenableEdit(await api.getAcessos('Contas a Receber', 'Alteracao'))
      setenableDel(await api.getAcessos('Contas a Receber', 'Exclusao'))


      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      query = {...query , cancelado: 'N', situacao: 'P'}
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        getData(event)
      }
    }

    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      console.log(filter.somente_boletos)
      queryObject.buscar = true
      queryObject.somente_boletos = filter.somente_boletos
      console.log(queryObject.somente_boletos)
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/celg/contas_receber?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      console.log(queryObject)
      api.get('/contas_receber', { params: queryObject }).then(r => {
        setData(r.data)
        setDataPlanilha(r.data.filter((i)=> {return i.cancelado != 'S' && i.situacao == 'P'} ))
        setDataCancelados(r.data.filter((i) => {return i.cancelado == 'S'}))
        console.log(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/celg/contas_receber');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    switch(e.target.name) {
      case 'somente_boletos': auxValues.somente_boletos = (auxValues.somente_boletos== 'S' ? 'N' : 'S')
      break;
      case 'fk_ass': auxValues.fk_ass = e.target.value;
        setAss(e.target.value)
      break;

      default: auxValues[e.target.name] = e.target.value;
    }
    setFilter(auxValues);
    console.log(e.target.name, e.target.value)
  };

  function handleChangeModal(e) {
    switch (e.target.name) {
      case 'vencimento': setVencimento(e.target.value)
        break;
      
      case 'fk_cta': setFk_cta(e.target.value)
        break;

      case 'envia_email': setEnvia_email((envia_email== 'S' ? 'N' : 'S'))
        break;
      
    }

    console.log(envia_email)
    
  }

  async function mostraModal(e){
    e.preventDefault()
    var ctr = []
    var erros = {
      naoSelecionado: function () {
        return swal.fire({
          title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
          html: `<p style='color:${colors.info900}'>Nenhum registro selecionado!</p>`,
          icon: 'warning',
        })
      },

      liquidados: function () {
        return swal.fire({
          title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
          html: `<p style='color:${colors.info900}'>Registro liquidado selecionado!</p>`,
          icon: 'warning',
        })
      },
      semValor: function () {
        return swal.fire({
          title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
          html: `<p style='color:${colors.info900}'>Registro sem valor selecionado!</p>`,
          icon: 'warning',
        })
      },
      cancelados: function () {
        return swal.fire({
          title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
          html: `<p style='color:${colors.info900}'>Registro cancelado!</p>`,
          icon: 'warning',
        })
      }

    }
    if(selected.length === 0){
      return erros.naoSelecionado()
    }
    api.get('/contas_receber?ctrs='+selected).then(res => {
      if (res.data.length>0){
        setLiquidado(res)
        let erro='certo'
        res.data.forEach( (r, i) => {
          if (r.valor - r.valor_desconto + r.valor_juros + r.valor_multa - r.valor_liquidado <= 0){
            erro = 'liquidados'
          }
          if (r.valor == 0){
            erro = 'semValor'
          }
          if (r.cancelado == 'S'){
            erro = 'cancelados'
          }
          console.log(selected)
          console.log(Number(r.valor), Number(r.valor_desconto) , Number(r.valor_juros) , Number(r.valor_multa) , Number(r.valor_liquidado), r.pk_ctr)
          if(erro != 'certo'){
            erro = erros[erro]
            return erro()
            
          } else {
            if(res.data.length-1 == i){
              setShowModal(true)
    
            }
          }
        })
  
      }

    })

  }

  const geraBoleto = async(e) => {
    e.preventDefault()
    setShowModal(false)
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    if(vencimento && fk_cta){
      api.post('/contas_receber/geraBoleto',{ctrs: [...liquidado.data], fk_usu: getUserCode(), vencimento: vencimento, fk_cta: fk_cta, envia_email: envia_email}).then( r=> {
        console.log(r.data)
        if(r.data && r.data.naoGerados.length == 0){
          swal.fire({
            title: `<h2 style='color: ${colors.info900}'>Sucesso!</h2>`,
            html: `<p style='color:${colors.info900}'>Boleto Gerado!</p>`,
            icon: 'success',
          })
        } else if (r.data.naoGerados.length > 0){
          swal.fire({
            title: `<h2 style='color: ${colors.info900}'>Falha na comunicação!</h2>`,
            html: `<p style='color:${colors.info900}'>Boletos nº ${r.data.naoGerados} não foram emitidos!</p>`,
            icon: 'warning',
          })
        }
        
        else {
          swal.fire({
            title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
            html: `<p style='color:${colors.info900}'>Erro no registro do boleto!</p>`,
            icon: 'warning',
          })
        }
      })

    } else {

      swal.fire('Dado não informado!', 'Informe a data de vencimento e a conta.', 'warning').then(r=>{
        if(r.value){
          setShowModal(true)
        }
      })
    }

  }

  function escondeModal(e){
    e.preventDefault()
    setShowModal(false)
  }


  const testaApi = (e) => {
    e.preventDefault()
    
    // api.get('/contas_receber/testeApi?pk='+selected.join()).then(r=>{
    //   console.log(r.data)
    // })
    e.preventDefault()
    api.post('/contas_receber/testeApi?pk='+selected.join(), {}, {responseType: 'blob'}).then((response) => {
      var blob = new Blob([response.data], {
        type: 'application/pdf'
      });
      download(blob, 'boleto.pdf')
    })

      // e.preventDefault()
      // const url = api.backendUrl + 'api/contas_receber/testeApi?pk='+selected.join();
      // console.log(url)
      // var link = document.createElement("a");
      // link.setAttribute('download', 'boleto.pdf');
      // link.href = url;
      // document.body.appendChild(link);
      // link.click();
      // link.remove();
      
      
      
  }

  const validaData = (e) => {
    let pattern = /^\d{4}-\d{2}-\d{2}$/;
    if(!pattern.test(vencimento)){
        setShowModal(false)
      swal.fire('Data Inválida!', 'Confira formatação da data.', 'warning').then(r =>{
        setShowModal(true)
      })
    };
  }

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Contas a Receber</h3>
          <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
              <>
                <FrDatePicker
                  value={(filter.data_emis_ini || '').split('T')[0]}
                  name="data_emis_ini"
                  id="data_emis_ini"
                  color="#528b46"
                  label="Período de Emissão"
                  variant="outlined"
                  size="small"
                  style={{ width: (190), flex: ('') }}
                  onChange={handleChange}
                />

                <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                <FrDatePicker
                  value={(filter.data_emis_fim || '').split('T')[0]}
                  name="data_emis_fim"
                  id="data_emis_fim"
                  color="#528b46"
                  variant="outlined"
                  size="small"
                  style={{ width: 190, flex:  '' }}
                  onChange={handleChange}

                />
              </>
              <>
                <FrDatePicker
                  value={(filter.data_venc_ini || '').split('T')[0]}
                  name="data_venc_ini"
                  id="data_venc_ini"
                  color="#528b46"
                  label="Período de Vencimento"
                  variant="outlined"
                  size="small"
                  style={{ width: (190), flex: ('') }}
                  onChange={handleChange}
                />

                <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                <FrDatePicker
                  value={(filter.data_venc_fim || '').split('T')[0]}
                  name="data_venc_fim"
                  id="data_venc_fim"
                  color="#528b46"
                  variant="outlined"
                  size="small"
                  style={{ width: 190, flex:  '' }}
                  onChange={handleChange}

                />
              </>
              <>
                <FrDatePicker
                  value={(filter.data_liq_ini || '').split('T')[0]}
                  name="data_liq_ini"
                  id="data_liq_ini"
                  color="#528b46"
                  label="Período de Liquidação"
                  variant="outlined"
                  size="small"
                  style={{ width: (190), flex: ('') }}
                  onChange={handleChange}
                />

                <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                <FrDatePicker
                  value={(filter.data_liq_fim || '').split('T')[0]}
                  name="data_liq_fim"
                  id="data_liq_fim"
                  color="#528b46"
                  variant="outlined"
                  size="small"
                  style={{ width: 190, flex:  '' }}
                  onChange={handleChange}

                />
              </>
              <br/>
              <div class="row" style={{marginLeft: '0px'}}>
                <FrSelect
                  value={ass || ''}
                  onChange={handleChange}
                  name="fk_ass"
                  id="fk_ass"
                  label='Associado'
                  data={comboAss}
                  style={{ width: 320 }}
                />
                <FrameF2 tipoModal={'Associados'} data={data} ass={ass} setAss={setAss} ></FrameF2>
  
              </div>
              <FrSelect
                value={filter.fk_fpg || ''}
                style={{ width: 200 }}
                name="fk_fpg"
                id="fk_fpg"
                label='Forma de Pagamento'
                data={comboFpg}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.cancelado || ''}
                style={{ width: 170 }}
                name="cancelado"
                id="cancelado"
                label='Cancelado'
                data={comboCancelado}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.situacao || ''}
                style={{ width: 170 }}
                name="situacao"
                id="situacao"
                label='Situação'
                data={comboSit}
                onChange={handleChange}
              />
              
              <FrSelect
                value={filter.somente_boletos || ''}
                style={{ width: 170 }}
                name="somente_boletos"
                id="somente_boletos"
                label='C/Boleto Gerado'
                data={comboBoletos}
                onChange={handleChange}
              />



            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              <ExcelFile element={<button>
                <Icon icon={fileExcel} size={18} />
                Exportar</button>}>
                <ExcelSheet data={dataPlanilha} name="Contas Receber">
                  <ExcelColumn label="Número Doc." value="numdoc" />
                  <ExcelColumn label="Dt. Emissão" value="formatdata_emissao" />
                  <ExcelColumn label="Associado" value="associado" />
                  <ExcelColumn label="Forma de Pagamento" value="formapgto" />
                  <ExcelColumn label="Dt. Vencimento" value="formatdata_vencimento" />
                  <ExcelColumn label="Valor" value="formatValor" />
                  <ExcelColumn label="Dt. Liquidação" value="formatdata_liquidacao" />
                  <ExcelColumn label="Valor Liquidado" value="formatValor_liquidado" />
                  <ExcelColumn label="ID Banco" value="id_banco" />
                  <ExcelColumn label="Conta" value="conta" />
                </ExcelSheet>
              </ExcelFile>  
              
              <button onClick={mostraModal}>
                <Icon icon={coinDollar} size={18} style={{paddingBotom: '2px'}} />
                Gerar Boleto
              </button>
              {/* <button onClick={testaApi}>
                <Icon icon={ic_delete} size={18} />
                Testa Api
              </button> */}
              {enableAdd !== 'S' ? '' :
                <Link to="/celg/contas_receber/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>
                </Link>}
            </div>
            <div>
                  <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={false} maxWidth={'lg'} >
                    <div style={{ padding: '20px', paddingTop: '40px', backgroundColor:'var(--cor-tema)'  }}>

                      <div className="defaultHeader">

                        <h3>Geração de Boleto</h3>
                      </div>
                      <br />


                      <div style={{ display: 'flex', flex: 1, marginTop: '20px', flexDirection: 'column', alignItems: 'flex-start', backgroundColor:'var(--cor-tema)' }}>

                        <FrDatePicker
                          name="vencimento"
                          id="vencimento"
                          color="#528b46"
                          label="Data de Vencimento"
                          variant="outlined"
                          size="small"
                          onBlur={validaData}
                          style={{ width: 190 }}
                          unclear={true}
                          value={vencimento}
                          onChange={handleChangeModal}
                        />
                        <FrSelect
                          style={{ width: 300 }}
                          name="fk_cta"
                          id="fk_cta"
                          label='Conta'
                          data={comboCta}
                          value={fk_cta}
                          onChange={handleChangeModal}
                        />

                      
                        <FormControlLabel 
                          className='FrCheckBoxLabel'
                          color=''
                          // style={{marginLeft: 1}}
                          control={
                            <Checkbox
                            checked={envia_email == 'S'}
                              value={envia_email}
                              name='envia_email'
                              id='envia_email'
                              size='small'
                              color="primary"
                              onChange={handleChangeModal}
                            />
                          }
                          label={'Enviar por e-mail'}
                        />
                        

                      </div>
                    </div>
                    <div className="FrModalFooter">
                      <div className="FrModalButtonsDiv" >
                        <button onClick={geraBoleto}> Confirmar </button>
                        <button onClick={escondeModal}> Sair </button>
                      </div>
                    </div>
                  </Dialog>
                </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable regName="contas_receber" mostraCheck verBoletos={true} width={150} columns={columns} selected={selected} setSelected={setSelected} searched={filtered} codeName="pk_ctr" page={'Contas_Receber'} data={data} enableEdit={enableEdit} enableDel={enableDel} />
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Contas_Receber;
