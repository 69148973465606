import Login from '../pages/Login';
import Home from '../pages/Home';
import ChangePw from '../pages/ChangePw';
import ForgotPw from '../pages/ForgotPw';
import Usuarios from '../pages/Usuarios';
import RegUsuarios from '../pages/RegUsuarios';
import Grupo_Usuarios from '../pages/Grupo_Usuarios';
import RegGrupo_Usuarios from '../pages/RegGrupo_Usuarios';
import Paises from '../pages/Paises';
import RegPaises from '../pages/RegPaises';
import Estados from '../pages/Estados';
import RegEstados from '../pages/RegEstados';
import Cidades from '../pages/Cidades';
import RegCidades from '../pages/RegCidades';
import Categoria_Associado from '../pages/Categoria_Associado';
import RegCategoria_Associado from '../pages/RegCategoria_Associado';
import Tipo_Despesa from '../pages/Tipo_Despesa';
import RegTipo_Despesa from '../pages/RegTipo_Despesa';
import Tipo_Mensalidade from '../pages/Tipo_Mensalidade';
import RegTipo_Mensalidade from '../pages/RegTipo_Mensalidade';
import Tipo_Servico from '../pages/Tipo_Servico';
import RegTipo_Servico from '../pages/RegTipo_Servico';
import Bancos from '../pages/Bancos';
import RegBancos from '../pages/RegBancos';
import Especialidade from '../pages/Especialidade';
import RegEspecialidade from '../pages/RegEspecialidade';
import Bandeira_Cartao from '../pages/Bandeira_Cartao';
import RegBandeira_Cartao from '../pages/RegBandeira_Cartao';
import Conselho from '../pages/Conselho';
import RegConselho from '../pages/RegConselho';
import Situacao_Associado from '../pages/Situacao_Associado';
import RegSituacao_Associado from '../pages/RegSituacao_Associado';
import Associados from '../pages/Associados';
import RegAssociados from '../pages/RegAssociados';
import TipoFornecedor from '../pages/Tipo_Fornecedor';
import RegTipoFornecedor from '../pages/RegTipo_Fornecedor';
import Classes from '../pages/Classes';
import RegClasses from '../pages/RegClasses';
import TipoCobranca from '../pages/Tipo_Cobranca';
import RegTipoCobranca from '../pages/RegTipo_Cobranca';
import Contas from '../pages/Contas';
import RegContas from '../pages/RegContas';
import Situacao_Fornecedor from '../pages/Situacao_Fornecedor';
import RegSituacao_Fornecedor from '../pages/RegSituacao_Fornecedor';
import Fornecedores from '../pages/Fornecedores';
import RegFornecedores from '../pages/RegFornecedores';
import Forma_Pagamento from '../pages/Forma_Pagamento';
import RegForma_Pagamento from '../pages/RegForma_Pagamento';
import Itens_Cobranca from '../pages/Itens_Cobranca';
import RegItens_Cobranca from '../pages/RegItens_Cobranca';
import Associado_Plano from '../pages/Associado_Plano';
import RegAssociado_Plano from '../pages/RegAssociado_Plano';
import Contas_Receber from '../pages/Contas_Receber';
import RegContas_Receber from '../pages/RegContas_Receber';
import Segmentos from '../pages/Segmentos';
import RegSegmentos from '../pages/RegSegmentos';
import Centro_Custo from '../pages/Centro_Custo';
import RegCentro_Custo from '../pages/RegCentro_Custo';
import Contas_Pagar from '../pages/Contas_Pagar';
import RegContas_Pagar from '../pages/RegContas_Pagar'
import LiquidarTit from '../pages/LiquidarTit'
import ReabrirTit from '../pages/ReabrirTit'
import LiquidacaoLoteTit from '../pages/LiquidacaoLoteTit'
import Associado_Curso from '../pages/Associado_Curso';
import RegAssociado_Curso from '../pages/RegAssociado_Curso';
import Cursos from '../pages/Cursos';
import RegCursos from '../pages/RegCursos';
import LiquidarCtr from '../pages/LiquidarCtr';
import ReabrirCtr from '../pages/ReabrirCtr';
import Boletos from '../pages/Boletos';
import RelContas_Receber from '../pages/RelContas_Receber';
import RelContas_Pagar from '../pages/RelContas_Pagar';

// types{
//     'cadastro':      String
//     'auxiliares':    String
//     'gerais':        String
//     'operacional':   String
//     'financeiros':   String
//     'relatorios':    String 
//     '':              String
// }

export const routes = [
    {
        path: '/forgotPw',
        component: ForgotPw,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },

    {
        path: '/changePw',
        component: ChangePw,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },

    {
        path: '/login',
        component: Login,
        protected: false,
        exact: true,
        checkPermission: false,
        register: false
    },
    {
        path: '/home',
        component: Home,
        protected: true,
        exact: true,
        checkPermission: false,
        register: false
    },
    {
        path: '/usuarios',
        component: Usuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Usuários',
        
    },
    {
        path: '/usuarios/registro',
        component: RegUsuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/grupo_usuarios',
        component: Grupo_Usuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Grupos de Usuários'
    },
    {
        path: '/grupo_usuarios/registro',
        component: RegGrupo_Usuarios,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/paises',
        component: Paises,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Paises',
        type: 'gerais',
        caption: 'Países',
    },
    {
        path: '/paises/registro',
        component: RegPaises,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/estados',
        component: Estados,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Estados',
        type: 'gerais',
    },
    {
        path: '/estados/registro',
        component: RegEstados,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/cidades',
        component: Cidades,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Cidades',
        type: 'gerais',
    },
    {
        path: '/cidades/registro',
        component: RegCidades,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/categoria_associado',
        component: Categoria_Associado,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Categoria Associados',
        type: 'auxiliares',
        caption: 'Categoria de Associados'
    },
    {
        path: '/categoria_associado/registro',
        component: RegCategoria_Associado,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tipo_despesa',
        component: Tipo_Despesa,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipo Despesa',
        type: 'financeiros',
        caption: 'Tipo de Despesa'
    },
    {
        path: '/tipo_despesa/registro',
        component: RegTipo_Despesa,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tipo_mensalidade',
        component: Tipo_Mensalidade,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipo Mensalidade',
        type: 'financeiros',
        caption: 'Tipo de Mensalidade'
    },
    {
        path: '/tipo_mensalidade/registro',
        component: RegTipo_Mensalidade,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tipo_servico',
        component: Tipo_Servico,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipo Servico',
        type: 'financeiros',
        caption: 'Tipo de Serviço'
    },
    {
        path: '/tipo_servico/registro',
        component: RegTipo_Servico,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/bancos',
        component: Bancos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Bancos',
        type: 'financeiros',
    },
    {
        path: '/bancos/registro',
        component: RegBancos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/especialidade',
        component: Especialidade,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Especialidade',
        type: 'auxiliares',
    },
    {
        path: '/especialidade/registro',
        component: RegEspecialidade,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/situacao_associado',
        component: Situacao_Associado,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Situacao Associado',
        type: 'auxiliares',
        caption: 'Situação Associado'
    },
    {
        path: '/situacao_associado/registro',
        component: RegSituacao_Associado,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/bandeira_cartao',
        component: Bandeira_Cartao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Bandeira Cartao',
        type: 'financeiros',
        caption: 'Bandeiras de Cartões'
    },
    {
        path: '/bandeira_cartao/registro',
        component: RegBandeira_Cartao,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/conselho',
        component: Conselho,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Conselho',
        type: 'auxiliares',
        caption: 'Conselhos Profissionais'
    },
    {
        path: '/conselho/registro',
        component: RegConselho,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/associados',
        component: Associados,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Associados',
        type: 'cadastro',
    },
    {
        path: '/associados/registro',
        component: RegAssociados,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tipo_fornecedores',
        component: TipoFornecedor,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipo Fornecedores',
        type: 'financeiros',
        caption: 'Tipo Fornecedor'
    },
    {
        path: '/tipo_fornecedores/registro',
        component: RegTipoFornecedor,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/classes',
        component: Classes,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Classes',
        type: 'financeiros',
    },
    {
        path: '/classes/registro',
        component: RegClasses,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/tipo_cobranca',
        component: TipoCobranca,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Tipo de Cobrança',
        type: 'financeiros',
    },
    {
        path: '/tipo_cobranca/registro',
        component: RegTipoCobranca,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/contas',
        component: Contas,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Contas Bancárias',
        type: 'financeiros',
    },
    {
        path: '/contas/registro',
        component: RegContas,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/situacao_fornecedor',
        component: Situacao_Fornecedor,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Situacao Fornecedor',
        type: 'financeiros',
        caption: 'Situação Fornecedor'
    },
    {
        path: '/situacao_fornecedor/registro',
        component: RegSituacao_Fornecedor,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/fornecedores',
        component: Fornecedores,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Fornecedores',
        type: 'cadastro',
    },
    {
        path: '/fornecedores/registro',
        component: RegFornecedores,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/forma_pagamento',
        component: Forma_Pagamento,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Formas de Pagamento',
        type: 'financeiros',
    },
    {
        path: '/forma_pagamento/registro',
        component: RegForma_Pagamento,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/associado_plano',
        component: Associado_Plano,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Termo de Associacao',
        type: 'operacional',
        caption: 'Termo de Associação'
    },
    {
        path: '/associado_plano/registro',
        component: RegAssociado_Plano,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    
    {
        path: '/itens_cobranca',
        component: Itens_Cobranca,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Itens de Cobranca',
        type: 'operacional',
    },
    {
        path: '/itens_cobranca/registro',
        component: RegItens_Cobranca,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/contas_receber',
        component: Contas_Receber,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Contas a Receber',
        type: 'operacional',
    },
    {
        path: '/contas_receber/registro',
        component: RegContas_Receber,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/segmentos',
        component: Segmentos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Segmentos',
        type: 'auxiliares',
    },
    {
        path: '/segmentos/registro',
        component: RegSegmentos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    
    {
        path: '/centro_custo',
        component: Centro_Custo,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Centros de Custo',
        type: 'financeiros',
        
    },
    {
        path: '/centro_custo/registro',
        component: RegCentro_Custo,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/contas_pagar',
        component: Contas_Pagar,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Contas a Pagar',
        type: 'operacional',
        
    }, 
    {
        path: '/contas_pagar/registro',
        component: RegContas_Pagar,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/contas_pagar/liquidar',
        component: LiquidarTit,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/contas_pagar/liquidacaoLote',
        component: LiquidacaoLoteTit,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },

    {
        path: '/contas_pagar/reabrir',
        component: ReabrirTit,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/associado_curso',
        component: Associado_Curso,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Matrículas',
        type: 'operacional',
        
    }, 
    {
        path: '/associado_curso/registro',
        component: RegAssociado_Curso,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/cursos',
        component: Cursos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Cursos',
        type: 'cadastro',
        
    }, 
    {
        path: '/cursos/registro',
        component: RegCursos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/contas_receber/liquidar',
        component: LiquidarCtr,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/contas_receber/reabrir',
        component: ReabrirCtr,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/contas_receber/boletos',
        component: Boletos,
        protected: true,
        exact: true,
        checkPermission: true,
        register: true
    },
    {
        path: '/rel_contas_receber',
        component: RelContas_Receber,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Relatório Contas Receber',
        type: 'relatorios',
        
    },
    {
        path: '/rel_contas_pagar',
        component: RelContas_Pagar,
        protected: true,
        exact: true,
        checkPermission: true,
        register: false,
        name: 'Relatório Contas Pagar',
        type: 'relatorios',
        
    },

];

export const fallbackRoute = Home

