import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import NavBar from '../components/NavBar'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrTable from '../components/FrTable'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';

const swal = withReactContent(Swal)

function ReabrirTit(props) {
  const [data, setData] = useState([])


  const columns = [
    { id: 'nomeusu', numeric: false, label: 'Usuário', align: 'left', minWidth: 100 },
    { id: 'format_data_liquidacao', numeric: false, label: 'Data Liquidação', align: 'left', minWidth: 100 },
    { id: 'format_valor', numeric: false, label: 'Valor', align: 'left', minWidth: 100 },
    { id: 'format_desconto', numeric: false, label: 'Desconto', align: 'left', minWidth: 100 },
    { id: 'format_juros', numeric: false, label: 'Juros', align: 'left', minWidth: 100 },
    { id: 'nome_agencia', numeric: false, label: 'Conta', align: 'left', minWidth: 100 },
    { id: 'nomefpg', numeric: false, label: 'Forma de Pagamento', align: 'left', minWidth: 100 },
    { id: 'observacao', numeric: false, label: 'Observação', align: 'left', minWidth: 100 },
    { id: 'pk_bcp', numeric: false, label: 'Código Interno', align: 'left', minWidth: 100 },

  ]


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
// alert(query.codigo)

      if (query.codigo > 0) {
        await api.get('/contas_pagar/liquidacoes?pk_ctp=' + query.codigo).then(r => {
          if (r.data) {
            setData(r.data)
          }

        })

        swal.close()
      } else {
        swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      }

    }

    fetchData()
  }, [])



  function handleCancel(e) {
    e.preventDefault();
    window.history.back()
  }



  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>Liquidações</h3>
          <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
        </div>
        <br />
        <div className="tableDiv">
          <FrTable regName="contas_pagar/liquidar" columns={columns} searched={false} codeName="pk_bcp" page={''} data={data}  enableDel={'S'} mensagemSemRegistro={'Título sem liquidações'} mostraCheck={false}/>
        </div>

        <div className="regButtonsDiv">
          <button onClick={handleCancel}>Fechar</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default ReabrirTit;
