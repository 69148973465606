import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {mCAR} from '../../utils/masks'
import api from '../../services/api'
import Cartao from '../Cartao'
import FrSelect from '../FrSelect'
import FrTextInput from '../FrTextInput'
import { colors } from '../../utils/vars'


const swal = withReactContent(Swal)



function DadosCartao(props){
    const [focused, setFocused] = useState('')
    const [comboMes, setComboMes] = useState(
            [{value: '01', display: 'Janeiro'},
            {value: '02', display: 'Fevereiro'},
            {value: '03', display: 'Março'},
            {value: '04', display: 'Abril'},
            {value: '05', display: 'Maio'},
            {value: '06', display: 'Junho'},
            {value: '07', display: 'Julho'},
            {value: '08', display: 'Agosto'},
            {value: '09', display: 'Setembro'},
            {value: '10', display: 'Outubro'},
            {value: '11', display: 'Novembro'},
            {value: '12', display: 'Dezembro'},])
    const [comboAno, setComboAno] = useState(
            [{value: '22', display: '2022'},
            {value: '23', display: '2023'},
            {value: '24', display: '2024'},
            {value: '25', display: '2025'},
            {value: '26', display: '2026'},
            {value: '27', display: '2027'},
            {value: '28', display: '2028'},
            {value: '29', display: '2029'},
            {value: '30', display: '2030'},
            {value: '31', display: '2031'},
            {value: '32', display: '2032'},
            {value: '33', display: '2033'},])


 

    const handleFocusCartao = (e) => {
        e.preventDefault()
        if(e.target.name=='codigo_seguranca'){
          setFocused('cvc')
        } else {
          setFocused(e.target.name)
        }
    }

    const handleChange = (e) => {
        e.preventDefault()
        switch (e.target.name){
            case 'fk_bac': props.setFk_bac(Number(e.target.value))
            break;
            case 'numero_cartao': props.setNumero_cartao(e.target.value)
            break;
            case 'nome_cartao': props.setNome_cartao(e.target.value)
            break;
            case 'mes_valid_cartao': props.setMes_valid_cartao(e.target.value)
            break;
            case 'ano_validade_Cartao': props.setAno_validade_Cartao(e.target.value)
            break;
            case 'codigo_seguranca': props.setCodigo_seguranca(e.target.value)
            break;
        }
    }

    function handleBlur(e){
        e.preventDefault()
        if(e.target.value.includes('X')){
          swal.fire({
            title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
            html: `<p style='color: ${colors.info900}'>Dado incompleto, verifique o número do cartão!</p>`,
            icon: 'warning',
          }).then(r=>{
            document.getElementById(e.target.id).focus()
          })
        }
      }

    function testaCallBack (e) {
        props.callback(e)
    }

    return (
        <div style={{display: 'flex', flexDirection:'row'}} >
            {props.fpg == 'C' ?
            
                <div style={{marginRight: '5px'}}>
                    <Cartao 
                        numero={props.numero_cartao}
                        cvc={props.codigo_seguranca}
                        validade={props.mes_valid_cartao + '/' + props.ano_validade_Cartao}
                        nome={props.nome_cartao}
                        focused={focused}
                        callback={testaCallBack}
                    />

                </div>
                :
                <></>
            }
            <div >

                <FrSelect
                    value={props.fk_bac || ''}
                    onChange={handleChange}
                    name="fk_bac"
                    id="fk_bac"
                    label='Bandeira Cartão'
                    required
                    onFocus={handleFocusCartao}
                    hidden={props.fpg ? props.fpg != 'C' : false}
                    data={props.comboBac? props.comboBac : [{ value: '', display: '' }]}
                    style={{ width: 140 }}
                />

                <FrTextInput
                    maxLength='19'
                    value={props.numero_cartao ? mCAR(props.numero_cartao) : ''}
                    onChange={handleChange}
                    name="numero_cartao"
                    id="numero_cartao"
                    color="#389492"
                    label="Número Cartão"
                    variant="outlined"
                    hidden={props.fpg ? props.fpg != 'C' : false}
                    onFocus={handleFocusCartao}
                    onBlur={handleBlur}
                    required
                    size="small"
                    style={{ width: 300 }}
                />
                <br/>

                <FrTextInput
                    maxLength='80'
                    value={props.nome_cartao || ''}
                    onChange={handleChange}
                    name="nome_cartao"
                    id="nome_cartao"
                    color="#389492"
                    label="Nome Cartão"
                    variant="outlined"
                    onFocus={handleFocusCartao}
                    hidden={props.fpg ? props.fpg != 'C' : false}
                    onBlur={handleBlur}
                    required
                    size="small"
                    style={{ width: 445 }}
                />
                <br/>
                
                <FrSelect
                    value={props.mes_valid_cartao || ''}
                    onChange={handleChange}
                    name="mes_valid_cartao"
                    id="mes_valid_cartao"
                    label='Mês Validade'
                    onFocus={handleFocusCartao}
                    hidden={props.fpg ? props.fpg != 'C' : false}
                    data={comboMes}
                    required
                    style={{ width: 140 }}
                />

                <FrSelect
                    value={props.ano_validade_Cartao || ''}
                    onChange={handleChange}
                    name="ano_validade_Cartao"
                    id="ano_validade_Cartao"
                    label='Ano Validade'
                    onFocus={handleFocusCartao}
                    hidden={props.fpg ? props.fpg != 'C' : false}
                    required
                    data={comboAno}
                    style={{ width: 140 }}
                />

                <FrTextInput
                    maxLength='10'
                    value={props.codigo_seguranca || ''}
                    onChange={handleChange}
                    name="codigo_seguranca"
                    id="codigo_seguranca"
                    onFocus={handleFocusCartao}
                    color="#389492"
                    label="Código Segurança"
                    variant="outlined"
                    hidden={props.fpg ? props.fpg != 'C' : false}
                    required
                    size="small"
                    style={{ width: 153 }}
                />

            
            </div>

        </div>
    )
}

export default DadosCartao