import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';


const CssTextField = withStyles({
  root: {
    '& .MuiInputLabel-formControl': {
      top:'-2px',
      color:'var(--cor-placeholder)'
    },
    '& label.Mui-focused': {
      color: 'var(--cor-label-focused)',
    },
    '& .MuiInput-underline:after': {
      borderColor: '#FFFFFF',
      borderRadius: '4px'
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '5px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#389492',
        borderRadius: '4px',
      },
      '&:hover fieldset': {
        borderColor: '#389492',
        borderRadius: '4px',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#389492',
        borderRadius: '4px',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
        color:'var(--cor-label-focused)',
      },
      height: '30px',
      maxHeight: '30px',
      '&.MuiInputBase-multiline': {
        height: 'auto',
        borderRadius: '4px',
        maxHeight: 'none',
      }
    },
    '& .MuiInputBase-root':{
      color:'var(--cor-fonte-hover)',
    },
    borderRadius: '4px',
  },

})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    marginRight: '6px',
    marginBottom: '10px'
  },
}));

export default function CustomizedInputs(props) {
  const classes = useStyles();

  return (
    <>
      <CssTextField
        className={classes.margin}
        label={props.label}
        variant={props.variant}
        id={props.id}
        size={props.size}
        name={props.name}
        autoFocus={false}
        inputProps={{
          maxLength: props.maxLength,
          pattern: props.pattern,
        }}
        InputProps= {{ 
          startAdornment: props.startAdornment ? <InputAdornment position="start">{props.startAdornment}</InputAdornment> : '',
          endAdornment: props.endAdornment ? <InputAdornment position="end">{props.endAdornment}</InputAdornment> : '',
        }}
        {...props}
      />
    </>
  );
}