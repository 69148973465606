export function mTel(tel) {
  tel=tel.replace(/\D/g,"")
  tel=tel.replace(/^(\d)/,"($1")
  tel=tel.replace(/(.{3})(\d)/,"$1)$2")
  if(tel.length === 9) {
    tel=tel.replace(/(.{1})$/,"-$1")
  } else if (tel.length === 10) {
    tel=tel.replace(/(.{2})$/,"-$1")
  } else if (tel.length === 11) {
    tel=tel.replace(/(.{3})$/,"-$1")
  } else if (tel.length === 12) {
    tel=tel.replace(/(.{4})$/,"-$1")
  } else if (tel.length > 12) {
    tel=tel.replace(/(.{4})$/,"-$1")
  }
  return tel;
}

export function mCNPJ(cnpj){
  cnpj=cnpj.replace(/\D/g,"")
  cnpj=cnpj.replace(/^(\d{2})(\d)/,"$1.$2")
  cnpj=cnpj.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
  cnpj=cnpj.replace(/\.(\d{3})(\d)/,".$1/$2")
  cnpj=cnpj.replace(/(\d{4})(\d)/,"$1-$2")
  return cnpj
}

export function mCPF(cpf){
  cpf=cpf.replace(/\D/g,"")
  cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2")
  cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2")
  cpf=cpf.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
  return cpf
}

export function mCEP(cep){
  cep=cep.replace(/\D/g,"")
  cep=cep.replace(/^(\d{2})(\d)/,"$1.$2")
  cep=cep.replace(/\.(\d{3})(\d)/,".$1-$2")
  return cep
}

export function mNum(num){
  num=num.replace(/\D/g,"")
  return num
}

export function mHora(hora) {
  hora = hora.replace(/\D/g, "")
  hora = hora.substr(0,2)+":"+hora.substr(2,2)

  return hora;
}


String.prototype.reverse = function(){
  return this.split('').reverse().join(''); 
};

// export function mVAL(val){
//   var valor  =  String(val).replace(/[^\d]+/gi,'').reverse();
//   var resultado  = "";
//   var mascara = "##.###.###,##".reverse();
//   for (var x=0, y=0; x<mascara.length && y<valor.length;) {
//     if (mascara.charAt(x) != '#') {
//       resultado += mascara.charAt(x);
//       x++;
//     } else {
//       resultado += valor.charAt(y);
//       y++;
//       x++;
//     }
//   }
//   valor = resultado.reverse();
//   return valor
// }

export function mVAL(val) {

  var v = String(val).replace(/\D/g,"");
  
  v = (v/100).toFixed(2) + "";
  
  v = v.replace(".", ",");
  
  v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
  
  v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
  
  return v;
  
  }

  export function mCAR(car) {

    // car=car.replace(/\D/g,"")
  
    car=car.replace(/(\w{4})(\w)/,"$1-$2")
    car=car.replace(/(\w{4})(\w)/,"$1-$2")
    car=car.replace(/(\w{4})(\w)/,"$1-$2")
    car=car.replace(/(\w{4})(\w)/,"$1-$2")
    // while(car.length<19){
    //   car= car+'X'
    //   car=car.replace(/(\w{4})(\w)/,"$1-$2")
    //   car=car.replace(/(\w{4})(\w)/,"$1-$2")
    //   car=car.replace(/(\w{4})(\w)/,"$1-$2")
    //   car=car.replace(/(\w{4})(\w)/,"$1-$2")
    // }
    return car
    }


// export function mVAL( int )
// {
//     int = int.toString().replace('.','').replace('.','')
//     int = int.toString().replace(',','.')
//     int = Number(int)
//     int = int.toString().replace('.',',')
//     console.log(int)
//     var tmp = int+'';
//     console.log(tmp)
//     tmp=tmp.replace(/\D/g,"")
   
//     tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
//     console.log(tmp.length)
//     if( tmp.length > 6 ){
//       tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
      
//     }else if(tmp.length> 9) {
//       tmp = tmp.replace(/([0-9]{3}),([0-9]{3}),([0-9]{2}$)/g, ".$1.$2,$3");
//     }

//     console.log(tmp)

    
   
//     return tmp;
//     int = int.toString().replace('.',',')
// }

export function mPERC( int )
{

  var tmp = int+'';
  tmp=tmp.replace(/\D/g,"")
  tmp = Number(tmp)
  tmp = String(tmp)
  if(tmp.length == 1){
    console.log(tmp)
    tmp = tmp.replace(/([0-9]{1})$/g, "0,0$1");
  }
    else if(tmp.length == 2){
    tmp = tmp.replace(/([0-9]{2})$/g, "0,$1");
  }
  else if(tmp.length>2){
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
  }
  if( tmp.length > 6 )
    tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");


  // console.log(tmp)
  return tmp;
}



