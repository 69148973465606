import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import CardActionArea from '@material-ui/core/CardActionArea';

import FrLoading from '../FrLoading'
import api from '../../services/api'
import FrTableReg from '../FrTableReg'
import './index.css';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 15,
    marginLeft: 0,
    minWidth: 345,
    '&:first-child': {
      marginLeft: 15,
    },
  }
})

function TableDetail(props) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const classes = useStyles();

  const columns = [{ id: 'id_sufixo', numeric: false, label: 'Id. Leitura', align: 'left', minWidth: 250 },
  { id: 'data', numeric: false, label: 'Data Leitura', align: 'left', minWidth: 150 },
  { id: 'nomepro', numeric: false, label: 'Produto', align: 'left', minWidth: 150 },
  //{ id: 'intervalo', numeric: false, label: 'Data Pedido', align: 'left', minWidth: 150 }

  ]

  let history = useHistory();

  const redirect = (e, codigo) => {
    e.preventDefault();
    // history.push('/sistema/lotes/registro?codigo=' + codigo)
  }

  useEffect(() => {
    const fetchData = async () => {
      if (props.isOpen) {
        setLoading(true)
        console.log(props.detail.fk_lot)
        api.get('/caixa/temLeitura?fk_lot=' + props.detail.fk_lot).then(r => {
          console.log(r.data)
          setData(r.data)
          setLoading(false)
        })
      }
    }

    fetchData()
  }, [props.isOpen])

  if (loading) {
    return (
      <>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
            <Box margin={3} >
              <FrLoading text="Buscando dados..." />
            </Box>
          </Collapse>
        </TableCell>
      </>
    )
  } else if (data.length > 0) {
    return (
      <>

        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>

            <FrTableReg readonly={true} detail regName="" columns={columns} searched={false} codeName="display" page={''} data={data} />

            {/* <Box margin={3} >
              <Typography variant="h6" gutterBottom component="div">
                Container
                    </Typography>
              <div className="DetailScrollWrapper">
                <div className="DetailOfetasList">
                  {data.map((item) => {
                    return (
                      <Card className={classes.root}>
                        <CardActionArea onClick={(e) => redirect(e, item.pk_lot)}>
                          <CardMedia
                            component="img"
                            alt="Foto Destaque"
                            height="140"
                            image={item.foto_destaque ? api.backendUrl + '/static/upload/lotes/' + item.pk_lot + '/' + item.foto_destaque : api.backendUrl + '/static/upload/lotes/no-image.jpg'}
                            title="Contemplative Reptile"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                              {item.cod_oper}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              Qtd de Peças: {item.qtd_pecas}<br />
                                  Volume: {item.volume}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    )
                  })}
                </div>
              </div>
            </Box> */}
          </Collapse>
        </TableCell>
      </>
    )
  } else {
    return (
      <>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
            <Box margin={3}>
              Não há registros para detalhar
                  </Box>
          </Collapse>
        </TableCell>
      </>
    )
  }

}

export default TableDetail;