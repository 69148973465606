export const colors={
    primary100: "#DAF9EE",
    primary200: "#B7F4E2",
    primary300: "#8BDECE",
    primary400: "#65BEB5",
    primary500: "#389492",
    primary600: "#28797F",
    primary700: "#1C5E6A",
    primary800: "#114555",
    primary900: "#0A3347",
    success100: "#CEFBCF",
    success200: "#9FF8A9",
    success300: "#6CEB87",
    success400: "#46D874",
    success500: "#13BF5A",
    success600: "#0DA45A",
    success700: "#098957",
    success800: "#066E50",
    success900: "#035B4B",
    info100   : "#CAFDF9",
    info200   : "#96FBFB",
    info300   : "#61E9F4",
    info400   : "#3ACFE9",
    info500   : "#00ABDB",
    info600   : "#0085BC",
    info700   : "#00649D",
    info800   : "#00477F",
    info900   : "#003369",
    warning100: "#FFF2CD",
    warning200: "#FFE29B",
    warning300: "#FFCD6A",
    warning400: "#FFB845",
    warning500: "#FF9707",
    warning600: "#DB7805",
    warning700: "#B75C03",
    warning800: "#934402",
    warning900: "#7A3301",
    danger100 : "#FDE5D3",
    danger200 : "#FBC4A8",
    danger300 : "#F59A7B",
    danger400 : "#EC7359",
    danger500 : "#E03826",
    danger600 : "#C01E1B",
    danger700 : "#A1131C",
    danger800 : "#810C1D",
    danger900 : "#6B071E",
}


/// ----------- dark ------------ >>>>>   #222626