

import { ic_chevron_right } from 'react-icons-kit/md/ic_chevron_right'
import Tooltip from '@material-ui/core/Tooltip';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import './index.css';
import { Icon } from 'react-icons-kit'
import {colors} from '../../utils/vars.js'
import api from '../../services/api'
import FrLoading from '../FrLoading'

const swal = withReactContent(Swal)

export function ImportaDados(props){

    const importaCsv = (e) => {
        e.preventDefault()
        swal.fire({
            title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
            html: `<p style='color: ${colors.info900}'>Essa rotina importa todos os dados de associados do arquivo .csv no servidor localizado em ./static/associados/registros.csv<p>
                   <p style='color: ${colors.info900}'>Confirma ação? </p>
                   <p style='color: ${colors.info900}'>(Pode levar alguns minutos...)</p>`,
            icon: 'warning',
            color: colors.info900,
            showCancelButton: true,
            confirmButtonColor: colors.success500,
            cancelButtonColor: colors.danger500,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then((result) => {
            if (result.value) {
                swal.fire({
                    html: <FrLoading text="Carregando..." />,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  })
              api.get('/associados/importacaoCadastro').then(r=> {
                if(r.data == 'funcionou'){
                    swal.fire({
                        title: `<h2 style='color:${colors.info900}'>Registros Adicionados</h2>`,
                        html: `<p style='color: ${colors.info900}'>Os registros foram adicionados com sucesso!</p>`,
                        icon: 'success',
                      })
                }else {
                    swal.fire({
                        title: `<h2 style='color:${colors.info900}'>Erro!</h2>`,
                        html:  `<p style='color: ${colors.info900}'>Houve algum problema no processo!</p>
                                <p style='color: ${colors.info900}'>Verifique o banco de dados e tente novamente.</p>`,
                        icon: 'warning',
                      })
                }

              })
            }
          })
    }

    return props.fk_usu == 6 ?(
         <Tooltip className={(props.hide ? 'hide-' : '') + "toggleMenuItem"} title=""> 
            <div style={{paddingLeft: 14, paddingBottom: '1rem'}} onClick={importaCsv}>
                <Icon icon={ic_chevron_right} size={15} />
                {props.hide ? '' : <strong style={{ paddingLeft: '3px' }}>Importar dados</strong>}
            </div>
            </Tooltip>) : (<></>)
}


