import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import './index.css'
import InputBase from '@material-ui/core/InputBase';
import MenuItem  from '@material-ui/core/MenuItem';

const BootstrapInput = withStyles((theme) => ({
  root: {
   
    'label + &': {
      marginTop: theme.spacing(0),
      backgroundColor: 'var(--cor-tema)',
      transitionDuration:'0.4s',
    },
    'label + &.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.38)',
      backgroundColor: 'var(--cor-tema)',
      transitionDuration:'0.4s',
    },
  },
  
  input: {
    borderRadius: '4px',
    position: 'relative',
    backgroundColor: 'var(--cor-tema)',
    transitionDuration:'0.4s',
    color:'var(--cor-fonte-hover)',
    border: '1px solid #389492',
    // fontSize: '0.9rem',
    // padding: '10px 26px 10px 12px',
    height: '15px',
    paddingLeft: '10px',
    '.MuiInputBase-root:hover &:disabled' : {
      border: '1px solid #389492',
      borderRadius: '4px',
      transition: 'none',
      height: '15px',
    },
    '&:focus': {
      borderRadius: '4px',
      borderColor: '#389492',
      borderWidth: '2px',
      backgroundColor: 'var(--cor-tema)',
      transitionDuration:'0.4s',
      height: '13px',
      paddingTop: '5px',
      paddingLeft: '9px'
    },
    '&.Mui-disabled': {
      border: '1px solid #389492',
      color: 'var(--cor-fonte-hover)',
      '&:hover': {
        borderColor: '#389492',
        borderRadius: '4px',
      },
    },
   
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    marginRight: '6px',
    marginBottom: '10px'
  },

  label: {
    margin: theme.spacing(-1.5),
    marginLeft: theme.spacing(1.5),
    backgroundColor: 'var(--cor-tema)',
    transitionDuration:'0.4s',
    color: 'var(--cor-fonte-hover)',
    zIndex: 3,
    top: '-2px',
    '&.MuiInputLabel-shrink': {
      padding: '5px',
      top: '0',
      color: 'var(--cor-placeholder)',
      backgroundColor: 'var(--cor-tema)',
      transitionDuration:'0.4s',
    },
    
  },

}));

export default function CustomizedSelects(props) {
  const classes = useStyles();
  const corTema=document.documentElement.style.getPropertyValue('--cor-tema')
  return (
    <>
      <FormControl className={classes.margin} style={props.style}>
        <InputLabel htmlFor="demo-customized-select-native" className={classes.label} disabled={props.disabled} hidden={props.hidden}>{props.label}</InputLabel>
        <NativeSelect
          id={props.id}
          value={props.value}
          onChange={props.handleChange}
          name={props.name}
          input={<BootstrapInput/>}
          {... props}
        >
          {/* <MenuItem aria-labelledby='Nenhum' value="">{''} </MenuItem> */}
          <option aria-label='Nenhum' value={''} style={{backgroundColor:corTema}}></option>
          {
            props.data?.map((item, key) => {
              return (<option value={item.value} style={{backgroundColor: corTema}} key={item.value}> {item.display} </option>)
            })
          }

        </NativeSelect>
      </FormControl>
    </>
  );
}