import React, {useEffect, useState} from 'react';
import ProtectedRoute from './components/ProtectedRoute';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { routes, fallbackRoute } from './routes'

import './App.css';

function App() {
	const [auth, setAuth]  = useState(JSON.parse(localStorage['authcelg'] || JSON.stringify({ authenticated: false, user: 0, residente: 0 })));
	const [temaEscuro, setTemaEscuro] = useState(auth.temaEscuro || false)


	function setColor() {
		if(!temaEscuro){
			document.documentElement.style.setProperty('--cor-tema', "#F7EFEF");
			document.documentElement.style.setProperty('--cor-tabela', "#FFFFFF");
			document.documentElement.style.setProperty('--cor-tabela-hover', "rgba(0, 0, 0, 0.04)");
			document.documentElement.style.setProperty('--cor-tabela-selected', "rgba(133, 130, 130, 0.5)");
			document.documentElement.style.setProperty('--cor-tabela-selected-hover', "rgba(133, 130, 130, 0.8)");
			document.documentElement.style.setProperty('--cor-fonte', "white");
			document.documentElement.style.setProperty('--cor-fonte-hover', "black");
			document.documentElement.style.setProperty('--cor-switch', 'rgb(211 195 102)');
			document.documentElement.style.setProperty('--cor-label-focused', '#389492');
			document.documentElement.style.setProperty('--cor-label', "#389492");
			document.documentElement.style.setProperty('--cor-placeholder','rgba(0, 0, 0, 0.65)');
		} else {
			document.documentElement.style.setProperty('--cor-tema', "#222626");
			document.documentElement.style.setProperty('--cor-tabela', "#424242");
			document.documentElement.style.setProperty('--cor-tabela-hover', "#686868");
			document.documentElement.style.setProperty('--cor-tabela-selected', "#222222");
			document.documentElement.style.setProperty('--cor-tabela-selected-hover', "#2c2c2c");
			document.documentElement.style.setProperty('--cor-fonte', "black");
			document.documentElement.style.setProperty('--cor-fonte-hover', "white");
			document.documentElement.style.setProperty('--cor-switch', 'rgb(14 0 80)');
			document.documentElement.style.setProperty('--cor-label-focused', 'white');
			document.documentElement.style.setProperty('--cor-label', "#F7EFEF");
			document.documentElement.style.setProperty('--cor-placeholder','rgba(255, 255, 255, 0.65)');
		}


	}
	useEffect(()=>{
	}, [temaEscuro])


	const routeComponents = routes.map((route, key) => {
		setColor()
		if (route.protected) {
			return <ProtectedRoute path={'/celg'+route.path} exact={route.exact} component={route.component} temaEscuro={temaEscuro} setTemaEscuro={setTemaEscuro} key={key}/>
		} else {
			return <Route path={'/celg'+route.path} exact={route.exact} component={route.component} temaEscuro={temaEscuro} setTemaEscuro={setTemaEscuro} key={key}/>
		}
	});

	return (
		<div className="App">
			<Router>
				<Switch>
					{routeComponents}
					<ProtectedRoute component={fallbackRoute} temaEscuro={temaEscuro} setTemaEscuro={setTemaEscuro}/>
				</Switch>
			</Router>
		</div>
	);
}

export default App;
