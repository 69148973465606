import React, { useState } from 'react';
import { Icon } from 'react-icons-kit'
import { ic_chevron_right } from 'react-icons-kit/md/ic_chevron_right'
import { ic_chevron_left } from 'react-icons-kit/md/ic_chevron_left'
import { ic_assignment } from 'react-icons-kit/md/ic_assignment'
import { ic_desktop_mac } from 'react-icons-kit/md/ic_desktop_mac'
import { Scrollbars } from 'react-custom-scrollbars';
import { ic_work } from 'react-icons-kit/md/ic_work'
import { ic_build } from 'react-icons-kit/md/ic_build'
import { coinDollar } from 'react-icons-kit/icomoon/coinDollar'

import Nav from 'react-bootstrap/Nav'
import Collapse from 'react-bootstrap/Collapse'
import Tooltip from '@material-ui/core/Tooltip';
import './index.css';
import {GeracaoAnuidades} from '../GeracaoAnuidades'
import icon from '../../imgs/logo-celg-white.png';
import logo from '../../imgs/logo-celg-white.png';
import { NavBarLink } from '../NavBarLink';
import { routes } from '../../routes';
import {getUserCode} from '../../utils/functions.js'
import { ImportaDados } from '../ImportaDados';

function NavBar(props) {
    const [hide, setHide] = useState(true);
    const [animationStart, setAnimationStart] = useState(false)
    const [openCadastros, setOpenCadastros] = useState(false)
    const [openSistema, setOpenSistema] = useState(false)
    const [openOperacional, setOpenOperacional] = useState(false)
    const [openRelatorios, setOpenRelatorios] = useState(false)
    const [openAuxiliares, setOpenAuxiliares] = useState(false)
    const [openGerais, setOpenGerais] = useState(false)
    const [openFinanceiros, setOpenFinanceiros] = useState(false)
    const [fk_usu, setFk_usu] = useState(getUserCode())
    const cadastros = routes.filter(item =>{
        return item.type == 'cadastro'
    }).sort((a, b) => {
        if(a.name.toUpperCase() > b.name.toUpperCase()){
            return 1;
        } 
        if (a.name.toUpperCase() < b.name.toUpperCase()){
            return -1;
        }
        return 0;
    })
    const operacional = routes.filter(item =>{
        return item.type == 'operacional'
    }).sort((a, b) => {
        if(a.name.toUpperCase() > b.name.toUpperCase()){
            return 1;
        } 
        if (a.name.toUpperCase() < b.name.toUpperCase()){
            return -1;
        }
        return 0;
    })
    const financeiros = routes.filter(item =>{
        return item.type == 'financeiros'
    }).sort((a, b) => {
        if(a.name.toUpperCase() > b.name.toUpperCase()){
            return 1;
        } 
        if (a.name.toUpperCase() < b.name.toUpperCase()){
            return -1;
        }
        return 0;
    })
    const gerais = routes.filter(item =>{
        return item.type == 'gerais'
    }).sort((a, b) => {
        if(a.name.toUpperCase() > b.name.toUpperCase()){
            return 1;
        } 
        if (a.name.toUpperCase() < b.name.toUpperCase()){
            return -1;
        }
        return 0;
    })
    const auxiliares = routes.filter(item =>{
        return item.type == 'auxiliares'
    }).sort((a, b) => {
        if(a.name.toUpperCase() > b.name.toUpperCase()){
            return 1;
        } 
        if (a.name.toUpperCase() < b.name.toUpperCase()){
            return -1;
        }
        return 0;
    })
    const relatorios = routes.filter(item =>{
        return item.type == 'relatorios'
    }).sort((a, b) => {
        if(a.name.toUpperCase() > b.name.toUpperCase()){
            return 1;
        } 
        if (a.name.toUpperCase() < b.name.toUpperCase()){
            return -1;
        }
        return 0;
    })

    function toggleNavBarHide(e, item) {
        e.preventDefault();

        if (!hide) {
            setOpenCadastros(false)
            setOpenOperacional(false)
            setOpenSistema(false)
            setOpenAuxiliares(false)
            setOpenRelatorios(false)
        }
        setAnimationStart(true)
        setHide(!hide)
    }

    function abreCadastro(e, item) {
        e.preventDefault();
        setHide(false)
        setAnimationStart(true)
        setOpenCadastros(!openCadastros)

    }

    function abreOperacional(e, item) {
        e.preventDefault();
        setAnimationStart(true)
        setHide(false)
        setOpenOperacional(!openOperacional)

    }

    function abreFinanceiros(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenFinanceiros(!openFinanceiros)

    }

    function abreGerais(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenGerais(!openGerais)

    }

    function abreAuxiliares(e, item) {
        e.preventDefault();
        setHide(false)
        setOpenAuxiliares(!openAuxiliares)

    }
    function abreSistema(e, item) {
        e.preventDefault();
        // setAnimationStart(true)
        setHide(false)
        setOpenSistema(!openSistema)

    }
    function abreRelatorios(e, item) {
        e.preventDefault();
        // setAnimationStart(true)
        setHide(false)
        setOpenRelatorios(!openRelatorios)

    }

    return (
        <div className={"Navbar" + (animationStart ? '-a': '')+(hide ? '-hide no-print' : ' no-print')}>
            
            <div className={'fixedBar' + (hide ? '-hide ' : '')}>


                <Scrollbars renderTrackVertical={props => <div {...props} className="track-vertical" />}
                            renderThumbVertical={props => <div {...props} className="thumb-vertical" />}
                            renderView={props => <div {...props} className="view" />}
                            autoHide style={{ width: "100%", height: "100%" }}>
                    <button onClick={toggleNavBarHide} className="toggle_navbar">
                        {hide ? <Icon icon={ic_chevron_right} size={40} /> : <Icon icon={ic_chevron_left} size={40} />}
                    </button>
                    <Nav justify defaultActiveKey="/celg/home" className="flex-column">
                        <Tooltip title="Tela Principal">
                            <Nav.Link href="/celg/home">
                                {hide ? <img alt='Expandir' src={icon} className={"fr_icon_navbar"+ (animationStart ? '-a': '')} /> : <img alt='Encolher' src={logo} className={"fr_logo_navbar"+ (animationStart ? '-a': '')} />}
                            </Nav.Link>
                        </Tooltip>

                        <button onClick={abreCadastro} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openCadastros ? " Open" : "")}>

                            <Icon icon={ic_assignment} size={20} />
                            {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Cadastros'}</strong>}
                        </button>
                        <Collapse in={openCadastros}>

                            <div className="toggleMenu">
                                {cadastros.map((item, key) =>{
                                    return <NavBarLink caption={item.caption || item.name} path={item.path} hide={hide} key={key}/>
                                })}

                                <button onClick={abreFinanceiros} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openFinanceiros ? " Open" : "")}>

                                    <Icon icon={coinDollar} size={20} />
                                    {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Financeiros'}</strong>}
                                </button>
                                <Collapse in={openFinanceiros}>
                                    <div className="toggleMenu">
                                        {financeiros.map((item, key) =>{
                                            return <NavBarLink caption={item.caption || item.name} path={item.path} hide={hide} key={key}/>
                                        })}

                                    </div>
                                </Collapse>

                                <button onClick={abreGerais} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openGerais ? " Open" : "")}>

                                    <Icon icon={ic_build} size={20} />
                                    {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Gerais'}</strong>}
                                </button>
                                <Collapse in={openGerais}>
                                    <div className="toggleMenu">
                                        {gerais.map((item, key) =>{
                                            return <NavBarLink caption={item.caption || item.name} path={item.path} hide={hide} key={key}/>
                                        })}

                                    </div>
                                </Collapse>
                            
                                <button onClick={abreAuxiliares} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openAuxiliares ? " Open" : "")}>

                                    <Icon icon={ic_assignment} size={20} />
                                    {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Auxiliares'}</strong>}
                                </button>
                                <Collapse in={openAuxiliares}>
                                    <div className="toggleMenu">
                                        {auxiliares.map((item, key) =>{
                                            return <NavBarLink caption={item.caption || item.name} path={item.path} hide={hide} key={key}/>
                                        })}

                                    </div>
                                </Collapse>
                                
                            </div>
                        </Collapse>

                        <button onClick={abreOperacional} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openOperacional ? " Open" : "")}>

                            <Icon icon={ic_work} size={20} />
                            {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Operacional'}</strong>}
                        </button>
                        <Collapse in={openOperacional}>
                            <div className="toggleMenu">
                                {operacional.map((item, key) =>{
                                    return <NavBarLink caption={item.caption || item.name} path={item.path} hide={hide} key={key}/>
                                })}
                                <GeracaoAnuidades fk_usu={fk_usu}/>
                                <button onClick={abreRelatorios} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openRelatorios ? " Open" : "")}>

                                    <Icon icon={ic_assignment} size={20} />
                                    {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Relatórios'}</strong>}
                                </button>
                                <Collapse in={openRelatorios}>
                                    <div className="toggleMenu">
                                        {relatorios.map((item, key) =>{
                                            return <NavBarLink caption={item.caption || item.name} path={item.path} hide={hide} key={key}/>
                                        })}

                                    </div>
                                </Collapse>
                            </div>
                        </Collapse>

                        <button onClick={abreSistema} className={(hide ? 'hide-' : '') + "toggleMenuButton" + (openSistema ? " Open" : "")}>
                            <Icon icon={ic_desktop_mac} size={20} />
                            {hide ? '' : <strong style={{ paddingLeft: '10px' }}>{'Sistema'}</strong>}
                        </button>
                        <Collapse in={openSistema}>
                            <div className="toggleMenu">
                                
                                <NavBarLink caption='Grupo de Usuários' path='/grupo_usuarios' hide={hide}/>
                                <NavBarLink caption='Usuários' path='/usuarios' hide={hide}/>
                                
                                <ImportaDados fk_usu={fk_usu} />
                            
                            </div>
                        </Collapse>

                    </Nav>
                </Scrollbars>
            </div>    

        </div>
    );
}

export default NavBar;