import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import { Icon } from 'react-icons-kit'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrameF2 from '../components/FrameF2'
import {colors} from '../utils/vars'
import ReactExport from "react-data-export";

import api from '../services/api'
import { paramsToObject } from '../utils/functions'
import Dialog from '@material-ui/core/Dialog';

import './styles/DefaultReg.css';
import FrDatePicker from '../components/FrDatePicker'
import FooterDelphus from '../components/FooterDelphus';
import consultaCep from '../services/consultaCep'
import {getUserCode} from '../utils/functions'
import {mVAL} from '../utils/masks'
import { Hidden } from '@material-ui/core';
import FrTable from '../components/FrTable'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import FrTableReg from '../components/FrTableReg'
import { MDBContainer, MDBIframe } from "mdbreact";

const swal = withReactContent(Swal)

function Contas_Pagar(props) {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [selected, setSelected] = useState({});
  const [atualiza, setAtualiza] = useState(false)
  const [modal, setModal] = useState(false)
  const [valorTotal, setValorTotal] = useState(0)
  const [valor, setValor] = useState(0)
  const [percentual, setPercentual] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [updatemodal, setUpdateModal] = useState(false)
  const [cancelado, setCancelado] = useState('')
  const [comboCancelado, setComboCancelado] = useState([{ value: 'S', display: 'Sim' }, { value: 'N', display: 'Não' }])
  const [itens, setItens] = useState([])
  const [pkModal, setPkModal] = useState()
  const [enableEdit, setEnableEdit] = useState('N')
  const [showModalAvulso, setShowModalAvulso] = useState(false)
  const [comboTde, setComboTde] = useState([{ value: '', display: '' }])
  const [comboCla, setComboCla] = useState([{ value: '', display: '' }])
  const [comboFpg, setComboFpg] = useState([{ value: '', display: '' }])
  const [comboCta, setComboCta] = useState([{ value: '', display: '' }])
  const [comboFor, setComboFor] = useState([{ value: '', display: '' }])
  const [comboCst, setComboCst] = useState([{ value: '', display: '' }])
  const [dataCst, setDataCst] = useState([{value: '', nomecst: ''}])
  const [fk_cst, setFk_cst] = useState({})
  const [forn, setForn] = useState([])

  const columns = [
    { id: 'value', numeric: false, label: 'Código', align: 'left', minWidth: 60 },
    { id: 'nomecst', numeric: false, label: 'Centro de Custo', align: 'left', minWidth: 300 },
    { id: 'valor', numeric: false, label: 'Valor', align: 'left', minWidth: 300 },
    { id: 'percentual', numeric: false, label: '%', align: 'left', minWidth: 300 },
   
    // { id: 'formatdata_vencimento', numeric: false, label: 'Dt. Vencimento', align: 'right', minWidth: 100 },
    // { id: 'valor_r', numeric: false, label: 'Valor Realizado', align: 'right', minWidth: 100 },
  ]
  
 

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      
      setComboTde(await api.getComboData('tipo_despesa'))
      setComboFpg(await api.getComboData('forma_pagamento'))
      setComboCst(await api.getComboData('centro_custo'))
      setComboCla(await api.getComboData('classes'))      
      setComboCta(await api.getComboData('contas'))
      setComboFor(await api.getComboData('fornecedores'))

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Itens de Cobrança', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Itens de Cobrança', 'Inclusao'))
      }


      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/contas_pagar?pk_ctp=' + query.codigo).then(r => {
          if (r.data[0]) {
            let auxData={...r.data[0]}
           
            if(auxData.data_emissao){
              auxData.data_emissao=auxData.data_emissao.split("T")[0]
            }
            if(auxData.data_vencimento) {
              auxData.data_vencimento=auxData.data_vencimento.split("T")[0]
            }
            if(auxData.data_cancelamento){
              auxData.data_cancelamento=auxData.data_cancelamento.split("T")[0]
            }
            if(auxData.data_liquidacao){
              auxData.data_liquidacao=auxData.data_liquidacao.split("T")[0]
            }
            api.get(`/ctp_rateio?fk_ctp=${query.codigo}`).then(res => {
              setDataCst(res.data)
              auxData.valor = mVAL(auxData.valor*100)
              let aux
              let v = 0
              aux = (res.data.map((i,ind) => {
                
                v += i.valor
                if (ind + 1 == res.data.length){
                  return v
                }
              }))
              console.log(aux)
              setValorTotal(aux[1])
              setData(auxData)
              swal.close()
            })

          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else {
        let auxData={}
        let date = new Date()
        auxData.data_emissao= date.toISOString().split("T")[0]
        auxData.valor = '0,00'
        auxData.multa = '0,00'
        auxData.juros = '0,00'
        auxData.desconto = '0,00'
        auxData.acrescimo = '0,00'
        console.log(date.toISOString())
        setData(auxData)
        swal.close()
      }
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'fk_for',
      'data_emissao',
      'data_vencimento',
      'fk_fpg',
      'valor',
    ]


    let errors = []

    if(data.cancelado=='S'){
      required.push('data_cancelamento')
    }

    required.forEach((curr, index) => {
      let fieldName = curr
      if(fieldName == 'data_emissao') { fieldName= 'Data de Emissão'}
      if(fieldName == 'data_vencimento') { fieldName= 'Data de Vencimento'}
      if(fieldName == 'fk_for') { fieldName= 'Fornecedor'}
      if(fieldName == 'fk_fpg') { fieldName= 'Forma de Pagamento'}
      if(fieldName == 'valor') { fieldName = 'Valor'}

      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function cancelou(canc){
    let res = false
    if (canc == cancelado){
      res = false
    } else if ( canc != cancelado && canc == 'S'){
      res = true
    }

    return res
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color:${colors.info900}'>Deseja salvar ${updateType ? 'as alterações' : 'a inclusão'} do registro?</p>`,
      // html: `<p style='color:${colors.info900}'>Deseja salvar ${updateType ? 'as alterações' : 'a inclusão'} do registro?</p>`,
      color: colors.info900,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: colors.success500,
      cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.host = window.location.href;
        auxValues.dataIcb=[...itens]
        console.log(dataCst)
        auxValues.dataCst = [...dataCst]
        auxValues.fk_usu = getUserCode()
        if(cancelou(auxValues.cancelado)){
          auxValues.fk_usu_cancela = getUserCode()
        }
        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/contas_pagar/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Registro Alterado<h2>`,
                  html: `<p style='color: ${colors.info900}'>O registro foi alterado com sucesso!</p>`,
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                  html: `<p style='color: ${colors.info900}'></p>`,
                  
                  icon: 'warning',
                })
              }
            })
          } else {
            try {
              api.post('/contas_pagar/add', auxValues).then(r => {
                console.log(r.status)
                if (r.status === 200) {
                  swal.fire({
                    title: 'Registro Adicionado',
                    text: "O registro foi adicionado com sucesso!",
                    icon: 'success',
                  }).then((result) => {
                    if (result.value) {
                      window.history.back()
                    }
                  })
                } else {
                  swal.fire({
                    title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                    text: "O registro não foi adicionado!",
                    icon: 'warning',
                  })
                }
              })
            } catch {
              swal.fire({
                title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })

            }

          }
        } else {
          swal.fire({
            title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
            html: <p style={{color: colors.info900}}>Verifique {errors.length>1?'os campos': 'o campo'} {errors.join(', ')}</p>,
            icon: 'warning',
          })
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color: ${colors.info900}'>Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?<p>`,
      icon: 'warning',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
      cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    // auxValues[e.target.name] = e.target.value;
    switch (e.target.name) {
      case 'valor':
        auxValues[e.target.name] = mVAL(e.target.value);
        break;
      case 'juros':
        auxValues[e.target.name] = mVAL(e.target.value);
        break;
      case 'multa':
        auxValues[e.target.name] = mVAL(e.target.value);
        break;
      case 'acrescimo':
        auxValues[e.target.name] = mVAL(e.target.value);
        break  ;
      case 'desconto':
        auxValues[e.target.name] = mVAL(e.target.value);
        break;
      case 'data_emissao' :
        auxValues[e.target.name] = e.target.value.split("T")[0];
        break;
      case 'data_vencimento' :
        auxValues[e.target.name] = e.target.value.split("T")[0];
        break;
      case 'data_cancelamento' :
        auxValues[e.target.name] = e.target.value.split("T")[0];
        break;
      
      case 'data_liquidacao' :
        auxValues[e.target.name] = e.target.value.split("T")[0];
        break;
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  useEffect(() => {
    api.get('/contas?pk_cta='+ data.fk_cta).then(r=> {
      if(r.data.length>0){
        let auxValues = {...data}
        auxValues.conta = r.data[0].conta
        auxValues.agencia = r.data[0].agencia
        console.log(auxValues)
        setData(auxValues)
      }
    })

    
  }, [data.fk_cta])

  const handleChangeModal = (e) => {
    e.preventDefault()
    if (e.target.name == 'fk_cst') {
      setFk_cst(e.target.value)
    } else if (e.target.name=='valor_cst'){
      setValor(mVAL(e.target.value))
    } else if (e.target.name == 'percentual'){
      setPercentual(mVAL(e.target.value))
    }
  }

  function mostraModal(e) {
    e.preventDefault();
    setFk_cst(null)
    setValor(null)
    setPercentual(null)
    setModal(true);
    setUpdateModal(false)
    setPkModal(0)
  }

  const closeModal = (e) => {
    e.preventDefault()
    setFk_cst(0)
    setModal(false)
  }

  function handleSubmitModal(e) {
    e.preventDefault();
    let valCtr = Number(data.valor.replace('.','').replace('.','').replace(',','.'))
    console.log(valorTotal)
    let valTotRateio = Number(valorTotal) + Number(valor.replace('.','').replace('.','').replace(',','.'))
    
      if ((!fk_cst)) {
        swal.fire({
          title: "Erro!",
          text: "Informe todos os campos!",
          icon: "warning",
        });
      } else {
        console.log(valCtr, valTotRateio)
        if (valCtr < valTotRateio){
          swal.fire({
            title: "Erro!",
            text: "Valor total nao pode ser menor que a soma dos valores do centro de custo!",
            icon: "warning",
          });
          } else {
            if (!updatemodal) {  
              incluiInsert(e);
            }
            else updateInsert(e);
          }

      }
      closeModal(e);
      console.log(dataCst)
    
  }

  
  function updateInsert(e) {
    e.preventDefault();

    let item = [];
    let i;
    let nome=''

    let duplicidade = false;
  

    if (!duplicidade) {
      api.get('/centro_custo?fk_cst='+fk_cst).then(r => {
        nome = r.data[0].descricao
        for (i = 0; i < dataCst.length; i++) {
          if (dataCst[i].pk == pkModal) {
            item.push({
              value: fk_cst,
              valor: valor,
              percentual: percentual,
              nomecst: nome,
              pk: pkModal,
  
            });
          } else {
            item.push({
              value: dataCst[0].value,
              valor: dataCst[0].valor,
              percentual: dataCst[0].percentual,
              nomecst: dataCst[0].nomecst,
              pk: dataCst[0].pk
  
            });
          }
          setAtualiza(true)
          if (i + 1 == dataCst.length) setDataCst(item);
        }
      })
    } else
      swal.fire({
        title: "Erro!",
        text: "Material/Medicamento já lançado! Não é permitido lançar em duplicidade!",
        icon: "warning",
      });
  }


  const incluiInsert = (e) =>{
    e.preventDefault();
    let tot = valorTotal
    const auxValues = { ...data };
    let pk = 1
    let nome=''
    tot = tot + Number(valor.replace('.','').replace('.','').replace(',','.'))
    if (tot > Number(data.valor.replace('.','').replace('.','').replace(',','.'))) {
      swal.fire({
        title: "Erro!",
        text: "Valor total do centro de custo não pode ultrapassar o total do registro!",
        icon: "warning",
      })
    } else {

      api.get('/centro_custo?pk_cst='+fk_cst).then(r=>{
        nome=r.data[0].descricao
        console.log(typeof dataCst[0].nomecst === "undefined")
        if (typeof dataCst[0].nomecst === "undefined") {
          console.log('entrou if')
          setAtualiza(true)
          setDataCst([{
            value: fk_cst,
            valor: valor,
            percentual: percentual,
            nomecst: nome,
            pk: pk
    
          }])
          pk++
        } else {
    
          console.log('entrou else')
          let item = [{
            value: dataCst[0].value,
            valor: dataCst[0].valor,
            percentual: dataCst[0].percentual,
            nomecst: dataCst[0].nomecst,
            pk: pk
    
          }]
          pk++
          var i;
          for (i = 1; i < dataCst.length; i++) {
            item.push({
              value: dataCst[i].value,
              valor: dataCst[0].valor,
              percentual: dataCst[0].percentual,
              nomecst: dataCst[i].nomecst,
              pk: pk
    
            })
            pk++
          }
          item.push({
            value: fk_cst,
            valor: valor,
            percentual: percentual,
            nomecst: nome,
            pk: pk
    
          })
          pk++
          setAtualiza(true)
          setValorTotal(tot)
          setDataCst(item.filter(r=> {return r.value != ''}))
        }    
      })
    }

  
    
  }

  const handleBlurVal = (e) => {
    e.preventDefault()
    let tot = Number(data.valor.replace('.','').replace('.','').replace(',','.'))
    console.log(Number(data.valor.replace('.','').replace('.','').replace(',','.')), valor)
    if (Number(data.valor.replace('.','').replace('.','').replace(',','.')) > 0)
      setPercentual(mVAL(Number(valor.replace('.','').replace('.','').replace(',','.')) * 10000 / tot))
  }

  


  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Contas a Pagar</h3>
          <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <div class="row" style={{marginLeft: '0px'}}>

            <FrSelect
              value={data.fk_for || ''}
              onChange={handleChange}
              name="fk_for"
              id="fk_for"
              label='Fornecedor'
              data={comboFor}
              style={{ width: 320 }}
            />
            <FrameF2 tipoModal={'Fornecedores'} data={data} forn={forn} setFor={setForn} ></FrameF2>

          </div>
          <FrTextInput
            maxLength='15'
            value={data.numdoc || ''}
            onChange={handleChange}
            name="numdoc"
            id="numdoc"
            color="#389492"
            label="Nr. Documento"
            variant="outlined"
            size="small"
            required
            style={{ width: 150 }}
          />
          <FrDatePicker
            name="data_emissao"
            id="data_emissao"
            color="#389492"
            label="Data de Emissão"
            variant="outlined"
            size="small"
            disabled
            style={{ width: 180 }}
            value={(data.data_emissao || '').split("T")[0]}
            onChange={handleChange}
          />

          <FrDatePicker
            name="data_vencimento"
            id="data_vencimento"
            color="#389492"
            label="Data de Vencimento"
            variant="outlined"
            size="small"
            style={{ width: 180 }}
            value={(data.data_vencimento || '').split("T")[0]}
            onChange={handleChange}
          /> 
          <FrSelect
            value={data.fk_fpg || ''}
            onChange={handleChange}
            name="fk_fpg"
            id="fk_fpg"
            label='Forma de Pagamento'
            data={comboFpg}
            style={{ width: 190 }}
          />

          <FrSelect
            value={data.fk_tde || ''}
            onChange={handleChange}
            name="fk_tde"
            id="fk_tde"
            label='Tipo de Despesa'
            data={comboTde}
            style={{ width: 190 }}
          />

         
          <FrSelect
            value={data.fk_cla || ''}
            onChange={handleChange}
            name="fk_cla"
            id="fk_cla"
            label='Classe'
            data={comboCla}
            style={{ width: 190 }}
          />
          <FrSelect
          value={data.fk_cta || ''}
          onChange={handleChange}
          name="fk_cta"
          id="fk_cta"
          label='Conta'
          data={comboCta}
          style={{ width: 190 }}
        />
        <FrTextInput
            maxLength='15'
            value={data.conta || ''}
            onChange={handleChange}
            name="conta"
            id="conta"
            color="#389492"
            label="Conta"
            variant="outlined"
            size="small"
            required
            disabled
            style={{ width: 150 }}
          />
          <FrTextInput
            maxLength='15'
            value={data.agencia || ''}
            onChange={handleChange}
            name="agencia"
            id="agencia"
            color="#389492"
            label="Agência"
            variant="outlined"
            size="small"
            required
            disabled
            style={{ width: 150 }}
          />

          <br/>
          <FrTextInput
            maxLength='15'
            value={data.valor || ''}
            onChange={handleChange}
            name="valor"
            id="valor"
            color="#389492"
            label="Valor"
            variant="outlined"
            size="small"
            required
            style={{ width: 150 }}
          />

          <FrTextInput
            maxLength='15'
            value={data.desconto || ''}
            onChange={handleChange}
            name="desconto"
            id="desconto"
            color="#389492"
            label="Desconto"
            variant="outlined"
            size="small"
            style={{ width: 150 }}
          />

          <FrTextInput
            maxLength='15'
            value={data.juros || ''}
            onChange={handleChange}
            name="juros"
            id="juros"
            color="#389492"
            label="Juros"
            variant="outlined"
            size="small"
            style={{ width: 150 }}
          />

          <FrTextInput
            maxLength='15'
            value={data.multa || ''}
            onChange={handleChange}
            name="multa"
            id="multa"
            color="#389492"
            label="Multa"
            variant="outlined"
            size="small"
            style={{ width: 150 }}
          />

          <FrTextInput
            maxLength='15'
            value={data.acrescimo || ''}
            onChange={handleChange}
            name="acrescimo"
            id="acrescimo"
            color="#389492"
            label="Acréscimo"
            variant="outlined"
            size="small"
            style={{ width: 150 }}
          />


          
{/* 
          <FrDatePicker
            name="data_liquidacao"
            id="data_liquidacao"
            color="#389492"
            label="Data de Liquidação"
            variant="outlined"
            size="small"
            style={{ width: 180 }}
            value={(data.data_liquidacao || '').split("T")[0]}
            onChange={handleChange}
          />   */}

         
         
          <br/>
          
          

          

 

          <FrTextInput
            maxLength='200'
            value={data.observacoes || ''}
            onChange={handleChange}
            name="observacoes"
            id="observacoes"
            color="#389492"
            label="Observações"
            variant="outlined"
            size="small"
            style={{ width: 500 }}
          />

          <div className="grupoEdits">
            <button onClick={(e) => mostraModal(e)}>
              <Icon icon={ic_add_box} size={18} />
              Incluir
            </button>

            <div >
              <FrTableReg setPkModal={setPkModal}
                setUpdateModal={setUpdateModal}
                setModal={setModal}
                setFk_cst={setFk_cst}
                editar={false}
                setDataGrid={setDataCst}
                detail 
                regName="clienteUsu"
                columns={columns}
                searched={false}
                updateType={updateType}
                // setPage={setPage}
                codeName="display"
                data={dataCst}
              />



              <div>
                <Dialog aria-labelledby="simple-dialog-title" open={modal} fullWidth={false} maxWidth={'lg'}>
                  <div className="FrModalFooter">
                    <div className="FrModalButtonsDiv">
                      <MDBContainer className="text-center">

                        <div className="fields">
                          <FrSelect
                            value={fk_cst}
                            onChange={handleChangeModal}
                            name="fk_cst"
                            id="fk_cst"
                            label="Centro de Custo"
                            data={comboCst}
                            style={{ width: 300 }}
                          />
                          <FrTextInput
                            maxLength='15'
                            value={valor || ''}
                            onChange={handleChangeModal}
                            name="valor_cst"
                            id="valor_cst"
                            color="#389492"
                            label="Valor"
                            onBlur={handleBlurVal}
                            variant="outlined"
                            size="small"
                            required
                            style={{ width: 150 }}
                          />
                          <FrTextInput
                            maxLength='15'
                            value={percentual || ''}
                            onChange={handleChangeModal}
                            name="percentual"
                            id="percentual"
                            color="#389492"
                            label="Percentual"
                            variant="outlined"
                            size="small"
                            required
                            style={{ width: 150 }}
                          />

                          
                        </div>
                      </MDBContainer>
                      <button onClick={handleSubmitModal}>Salvar</button>
                      <button onClick={closeModal}>Cancelar</button>
                    </div>
                  </div>
                </Dialog>
              </div>
            </div>
          </div>  



        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Contas_Pagar;
