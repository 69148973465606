import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import Cartao from '../components/Cartao'
import FrTextInput from '../components/FrTextInput'
import FrTextField from '../components/FrTextField'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import Dialog from '@material-ui/core/Dialog';
import {mCPF, mCEP, mTel, mCNPJ} from '../utils/masks'
import {colors} from '../utils/vars'
import FrDatePicker from '../components/FrDatePicker'
import consultaCep from '../services/consultaCep'
import Tabs from '../components/Tabs';
import FrTable from '../components/FrTable'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import { validarCNPJ, validarCPF } from '../utils/functions';
import { mCAR } from '../utils/masks';
import { Hidden } from '@material-ui/core';
import { SketchPicker } from 'react-color';
import DadosCartao from '../components/DadosCartao';

const swal = withReactContent(Swal)

function Associados(props) {
  const [data, setData] = useState({})
  const [dataPla, setDataPla] = useState({})
  const [pk, setPk] = useState(0)
  const [invalido, setInvalido] = useState(false)
  const [duplicado, setDuplicado] = useState(false)
  const [updateType, setUpdateType] = useState(false)
  const [categoria, setCategoria] = useState('')
  const [fk_bac, setFk_bac] = useState(null)
  const [selected, setSelected] = useState([])
  const [showModalCtr, setShowModalCtr] = useState()
  const [dataCtr, setDataCtr] = useState()
  const [codigo_seguranca, setCodigo_seguranca] = useState(null)
  const [nome_cartao, setNome_cartao] = useState(null)
  const [mes_valid_cartao, setMes_valid_cartao] = useState(null)
  const [ano_validade_Cartao, setAno_validade_Cartao] = useState(null)
  const [numero_cartao, setNumero_cartao] = useState(null)
  const [comboSexo, setComboSexo] = useState([{value: 'M', display: 'Masculino'},{value: 'F', display: 'Feminino'},{value: 'O', display: 'Outro'},])
  const [comboCidNat, setComboCidNat] = useState([{ value: '', display: '' }])
  const [comboCon, setComboCon] = useState([{ value: '', display: '' }])
  const [comboEsp, setComboEsp] = useState([{ value: '', display: '' }])
  const [comboCat, setComboCat] = useState([{ value: '', display: '' }])
  const [mudaTipo, setMudaTipo] = useState('F')
  const [focused, setFocused] = useState('')
  const [comboSit, setComboSit] = useState([{ value: '', display: '' }])
  const [comboBac, setComboBac] = useState([{ value: '', display: '' }])
  const [comboSeg, setComboSeg] = useState([{ value: '', display: '' }])
  const [comboTipo, setComboTipo] = useState([{ value: 'F', display: 'Física' },{ value: 'J', display: 'Jurídica' }])
  const [fpg, setFpg] = useState('N')
  const [comboBco, setComboBco] = useState([{ value: '', display: '' }])
  // const [comboAno, setComboAno] = useState([{ value: '', display: '' }])
  const [enableEdit, setEnableEdit] = useState('N')
  const [comboMes, setComboMes] = useState(
    [{value: '01', display: 'Janeiro'},
     {value: '02', display: 'Fevereiro'},
     {value: '03', display: 'Março'},
     {value: '04', display: 'Abril'},
     {value: '05', display: 'Maio'},
     {value: '06', display: 'Junho'},
     {value: '07', display: 'Julho'},
     {value: '08', display: 'Agosto'},
     {value: '09', display: 'Setembro'},
     {value: '10', display: 'Outubro'},
     {value: '11', display: 'Novembro'},
     {value: '12', display: 'Dezembro'},])
  const [comboAno, setComboAno] = useState(
      [{value: '22', display: '2022'},
       {value: '23', display: '2023'},
       {value: '24', display: '2024'},
       {value: '25', display: '2025'},
       {value: '26', display: '2026'},
       {value: '27', display: '2027'},
       {value: '28', display: '2028'},
       {value: '29', display: '2029'},
       {value: '30', display: '2030'},
       {value: '31', display: '2031'},
       {value: '32', display: '2032'},
       {value: '33', display: '2033'},])

       const columns = [
        { id: 'associado', numeric: false, label: 'Associado', align: 'left', minWidth: 200 },
        { id: 'tipo_mensalidade', numeric: false, label: 'Tipo Mensalidade', align: 'left', minWidth: 150 },
        { id: 'formatdata_associacao', numeric: false, label: 'Data Associação', align: 'left', minWidth: 150 },
        { id: 'status', numeric: false, label: 'Status', align: 'left', minWidth: 200 },
    
      ]

      const columnsCtr = [
        { id: 'associado', numeric: false, label: 'Associado', align: 'left', minWidth: 200 },
        { id: 'formatValor_pendente', numeric: false, label: 'Valor Pendente', align: 'left', minWidth: 200 },
        { id: 'formatdata_vencimento', numeric: false, label: 'Dt. Vencimento', align: 'left', minWidth: 200, order: 'data_vencimento' },
      ]


  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      console.log(props)
      let codigo = props.pk_ass ? props.pk_ass : query.codigo

      
      setComboCidNat(await api.getComboData('cidades'))
      setComboCon(await api.getComboData('conselho'))
      setComboEsp(await api.getComboData('especialidade'))
      setComboSeg(await api.getComboData('segmentos'))
      setComboCat(await api.getComboData('categoria_associado'))
      setComboSit(await api.getComboData('situacao_associado'))
      setComboBco(await api.getComboData('bancos'))
      setComboBac(await api.getComboData('bandeira_cartao'))

      if (codigo > 0) {
        setEnableEdit(await api.getAcessos('Associados', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Associados', 'Inclusao'))
      }


      if (codigo > 0) {
        setPk(codigo)
        setUpdateType(true)
        api.get('/associados?pk_ass=' + codigo).then(r => {
          if (r.data[0]) {
            console.log(r.data[0])
            let auxValues = {...r.data[0]}
            auxValues.data_nascimento = (r.data[0].data_nascimento ? r.data[0].data_nascimento.split("T")[0]: null)
            auxValues.data_prev_graduacao = (r.data[0].data_prev_graduacao ? r.data[0].data_prev_graduacao.split("T")[0]: null)
            // auxValues.data_cadastro = r.data[0].data_cadastro.split("T")[0]
            setFk_bac(r.data[0].fk_bac)
            setNome_cartao(r.data[0].nome_cartao)
            setAno_validade_Cartao(r.data[0].ano_validade_Cartao)
            setMes_valid_cartao(r.data[0].mes_valid_cartao)
            setNumero_cartao(r.data[0].numero_cartao)
            setCodigo_seguranca(r.data[0].codigo_seguranca)
            if(r.data[0].cpf_cnpj.length>11){
              auxValues.cpf_cnpj = mCNPJ(r.data[0].cpf_cnpj)
              auxValues.tipo_pessoa = 'J'
            } else {
              auxValues.cpf_cnpj = mCPF(r.data[0].cpf_cnpj)
              auxValues.tipo_pessoa = 'F'
            }
            
            setData(auxValues)
            setCategoria(r.data[0].fk_cat)
            api.get('/associados/buscaFormaPagamento?fk_ass='+codigo).then(re=>{
              console.log(re.data[0])
              if(re.data.length>0){
                setFpg(re.data[0].dados_exigidos)
              }
              api.get('/associado_plano?fk_ass='+codigo).then(r => {
                setDataPla(r.data)
                console.log(r.data)
                swal.close()
              })
            })
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'nome',
      'cpf_cnpj',
      'fk_sas',
      'fk_cat',
      'email_particular',
     
    ]
    if(fpg == 'C'){
      // required.push('fk_bac')
      // required.push('mes_valid_cartao')
      // required.push('ano_validade_Cartao')
      // required.push('codigo_seguranca')
    }
    if(fpg == 'B'){
      // required.push('fk_bco')
      // required.push('conta')
      // required.push('agencia')
    }
   

    if(data.tipo_pessoa=='F'){
      // required.push('cep_residencial')
      // required.push('fk_esp')
      // required.push('sexo')
      // required.push('fk_con')
      // required.push('matricula_conselho')
      // required.push('endereco_residencial')
      // required.push('bairro_residencial')
      // required.push('fk_cid_residencial')
      // required.push('fone_residencial')
    } else if (data.tipo_pessoa=='J'){
      // required.push('cep_comercial')
      // required.push('endereco_comercial')
      // required.push('bairro_comercial')
      // required.push('fk_cid_comercial')
      // required.push('fone_comercial')
    }
    

    
    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if(fieldName == 'fk_cid_comercial') { fieldName= 'Cidade Comercial'}
      if(fieldName == 'fk_cid_residencial') { fieldName= 'Cidade Residencial'}
      if(fieldName == 'fk_cid_naturalidade') { fieldName= 'Cidade Naturalidade'}
      if(fieldName == 'fk_con') { fieldName= 'Conselho'}
      if(fieldName == 'fk_esp') { fieldName= 'Especialidade'}
      if(fieldName == 'fk_cat') { fieldName= 'Categoria'}
      if(fieldName == 'fk_sas') { fieldName= 'Situação'}
      if(fieldName == 'cep_residencial') { fieldName= 'CEP Residencial'}
      if(fieldName == 'cep_comercial') { fieldName= 'CEP Comercial'}
      if(fieldName == 'endereco_residencial') { fieldName= 'Endereço Residencial'}
      if(fieldName == 'email_particular') { fieldName= 'Email Particular'}
      if(fieldName == 'bairro_residencial') { fieldName= 'Bairro Residencial'}
      if(fieldName == 'endereco_comercial') { fieldName= 'Endereço Comercial'}
      if(fieldName == 'bairro_comercial') { fieldName= 'Bairro Comercial'}
      if(fieldName == 'matricula_conselho') { fieldName= 'Matrícula Conselho'}
      if(fieldName == 'fone_comercial') { fieldName= 'Telefone Comercial'}
      if(fieldName == 'fone_residencial') { fieldName= 'Telefone Residencial'}
      if(fieldName == 'fk_bac') { fieldName= 'Bandeira Cartão'}
      if(fieldName == 'ano_validade_Cartao') { fieldName= 'Ano Validade'}
      if(fieldName == 'mes_valid_cartao') { fieldName= 'Mês Validade'}
      if(fieldName == 'cpf_cnpj' && (data.tipo_pessoa == 'F' || !data.tipo_pessoa)) {fieldName = 'CPF'}
      if(fieldName == 'cpf_cnpj' && data.tipo_pessoa == 'J' ) {fieldName = 'CNPJ'}
      if(fieldName == 'token_cartao') { fieldName= 'Token Cartão'}
      if(fieldName == 'codigo_seguranca') { fieldName= 'Código Segurança'}
      if(fieldName == 'fk_bco') { fieldName= 'Banco'}
      if(fieldName == 'agencia') { fieldName= 'Agência'}
      if(fieldName == 'conta') { fieldName= 'Conta'}
     

      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function verContas (e) {
    e.preventDefault();
    if ((updateType) && (data.fk_cat != categoria)) {
      api.get('/contas_receber?fk_ass=' + data.pk_ass+'&situacao=P' ).then(r => {
        if (r.data[0]) {

          swal.fire({
            title: "ATENÇÃO",
            text: 'Residente tem Mensalidades em Aberto, deseja baixar?',
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
          })
            .then(result => {
              if (result.value) {
                setDataCtr(r.data)
                setShowModalCtr(true)
              } else
                handleSubmit(e)
            })
        } else
          handleSubmit(e)

      })
    } else handleSubmit(e)
  }

  
  function escondeModalCtr(e) {
    e.preventDefault()
    setSelected([])
    setShowModalCtr(false)

  }

  function confirmaModalCtr(e) {
    e.preventDefault()
    setShowModalCtr(false)
    handleSubmit(e)
  }


  function handleSubmit(e) {
    e.preventDefault();
    
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color:${colors.info900}'>Deseja salvar ${updateType ? 'as alterações' : 'a inclusão'} do registro?</p>`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: colors.success500,
      cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        console.log(fk_bac)
        auxValues.host = window.location.href;
        auxValues.fk_bac = fk_bac
        auxValues.numero_cartao = numero_cartao
        auxValues.ano_validade_Cartao = ano_validade_Cartao
        auxValues.mes_valid_cartao = mes_valid_cartao
        auxValues.nome_cartao = nome_cartao
        auxValues.codigo_seguranca = codigo_seguranca
        
        auxValues.selected = selected
        let errors = consistData(auxValues, e)
        console.log(errors.length)
        if (errors.length === 0 || duplicado || invalido) {
          if (updateType) {
            api.post('/associados/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Registro Alterado<h2>`,
                  html: `<p style='color: ${colors.info900}'>O registro foi alterado com sucesso!</p>`,
                  icon: 'success',
                }).then((result) => {
                  if (result) {
                    if (props.modal)
                      props.setShowModal(false)
                    else
                      window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                  html: `<p style='color: ${colors.info900}'></p>`,
                  icon: 'warning',
                })
              }
            })
          } else {
            try {
              api.post('/associados/add', auxValues).then(r => {
                console.log(r)
                if (r.status === 200) {
                  if (props.modal) {
                    swal.fire({
                      title: `<p style='color:${colors.info900}'Registro Adicionado</p>`,
                      html: `<p style='color: ${colors.info900}'>O registro foi adicionado com sucesso!</p>`,
                      icon: 'success',
                    }).then((result) => {
                      if (result) {
                        if (props.modal) {
                          if (props.setShowModal)
                            props.setShowModal(false)
                        } 
                      }
                    })

                  } else {
                    swal.fire({
                      title: `<p style='color:${colors.info900}'Registro Adicionado</p>`,
                      html: `<p style='color: ${colors.info900}'>Deseja incluir o Termo de Associação?</p>`,
                      icon: 'success',
                      showCancelButton: true,
                      confirmButtonColor: colors.success500,
                      cancelButtonColor: colors.danger500,
                      confirmButtonText: 'Sim',
                      cancelButtonText: 'Não'
                    }).then(result => {
                        window.location.href = '/celg/associados?buscar=true&nome=' + data.nome + '&newreg=' + r.data.insertId                       
                    })
                  }
                } else {
                  swal.fire({
                    title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                    text: "O registro não foi adicionado!",
                    icon: 'warning',
                  })
                }
              })
            } catch {
              swal.fire({
                title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })

            }

          }
        } else {
          if(errors.length>0){
            swal.fire({
              title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
              html: <p style={{color: colors.info900}}>Verifique {errors.length>1?'os campos': 'o campo'} {errors.join(', ')}</p>,
              // text: "Verifique o(s) campo(s) " + errors.join(','),
              icon: 'warning',
            })

          } else if(invalido){
            swal.fire({
              title: `<h2 style='color: ${colors.info900}'>Documento inválido!</h2>`,
              html: <p style={{color: colors.info900}}>Verifique o CPF/CNPJ</p>,
              // text: "Verifique o(s) campo(s) " + errors.join(','),
              icon: 'warning',
            })
          } else if (duplicado) {
            swal.fire({
              title: `<h2 style='color: ${colors.info900}'>Documento duplicado!</h2>`,
              html: <p style={{color: colors.info900}}>CPF/CNPJ já cadastrado no sistema</p>,
              // text: "Verifique o(s) campo(s) " + errors.join(','),
              icon: 'warning',
            })
          }
        }
      }
    })
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color: ${colors.info900}'>Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?<p>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.success500,
        cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result) {
        if (props.modal)
          props.setShowModal(false)
        else
          window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    // auxValues[e.target.name] = e.target.value;
    switch (e.target.name) {
      case 'numero_cartao': auxValues[e.target.name]= mCAR(e.target.value)
      
      default: auxValues[e.target.name] = e.target.value;

    }
    console.log(mCAR(auxValues[e.target.name]))
    setData(auxValues);
  };

  const handleCep = (e) => {
    e.preventDefault()
    const auxValues = { ...data };
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    if(e.target.name == 'cep_residencial'){
      consultaCep((e.target.value).replace('.','').replace('-','')+'/json').then(r =>{
        if(!r.data.erro){
          auxValues.endereco_residencial = r.data.logradouro
          auxValues.complemento_residencial = r.data.complemento
          auxValues.bairro_residencial = r.data.bairro
          // setData(auxValues)
          api.get('/cidades?cod_ibge='+r.data.ibge).then(res =>{ 
            if (res.data.length>0){
              console.log(res.data[0])

              auxValues.fk_cid_residencial = res.data[0].pk_cid
            }
            setData(auxValues)
            swal.close()
          })
          

        } else{
          swal.close()
          swal.fire('CEP Inválido!', 'CEP Não encontrado nos registros!', 'warning')
        }
      })

    }
    if(e.target.name == 'cep_comercial'){
      consultaCep((e.target.value).replace('.','').replace('-','')+'/json').then(r =>{
        if(!r.data.erro){
          auxValues.endereco_comercial = r.data.logradouro
          auxValues.complemento_comercial = r.data.complemento
          auxValues.bairro_comercial = r.data.bairro
          api.get('/cidades?cod_ibge='+r.data.ibge).then(res =>{ 
            if (res.data.length>0){
              auxValues.fk_cid_comercial = res.data[0].pk_cid
            }
            swal.close()
            setData(auxValues)
          })
        } else{
          swal.close()
          swal.fire('CEP Inválido!', 'CEP Não encontrado nos registros!', 'warning')
        }
      })
    }

  }

  const validaCpfCnpj = (e) =>{
    e.preventDefault()
    console.log(e.target.value)
    let id=e.target.id
    setInvalido(false)
    setDuplicado(false)
    let value = e.target.value
    if(e.target.value.length<1){
      return
    }
    api.get('/associados?cpf_cnpj='+data.cpf_cnpj).then(res=> {
      if((res.data.length>0)){
        if(!updateType){
          return swal.fire({
            title: `<h2 style='color: ${colors.info900}'>ATENÇÃO!</h2>`,
            html: `<p style='color: ${colors.info900}'>Documento já cadastrado no sistema</p>`,
            icon: 'warning',
          }).then(r=>{
            // document.getElementById('cpf_cnpj').focus()
            setInvalido(true)
            console.log('chamou updateType 2x')
          })

        } else  {
          res.data.forEach(data=>{
            console.log(data.pk_ass)
            if(data.pk_ass != pk){
              swal.fire({
                title: `<h2 style='color: ${colors.info900}'>ATENÇÃO!</h2>`,
                html: `<p style='color: ${colors.info900}'>Documento já cadastrado no sistema</p>`,
                icon: 'warning',
              }).then(r=>{
                // document.getElementById('cpf_cnpj').focus()           
                setInvalido(true)    
                console.log(data.pk_ass)
              })
            }
          })
        }
      } else{
        if (data.tipo_pessoa == 'F'){
          console.log()
          if(!validarCPF(value)){
            swal.fire({
              title: `<h2 style='color: ${colors.info900}'>ATENÇÃO!</h2>`,
              html: `<p style='color: ${colors.info900}'>CPF Inválido</p>`,
              icon: 'warning',
            }).then(r=>{
              // document.getElementById('cpf_cnpj').focus()
              setDuplicado(true)
            })
          } 
        } else if(data.tipo_pessoa == 'J'){
          if(!validarCNPJ(value)){
            swal.fire({
              title: `<h2 style='color: ${colors.info900}'>ATENÇÃO!</h2>`,
              html: `<p style='color: ${colors.info900}'>CNPJ Inválido</p>`,
              icon: 'warning',
            }).then(r=>{
              setDuplicado(true)
              // document.getElementById('cpf_cnpj').focus()
            })
          } 
        }
      }
    })
  }

  const handleFocusCartao = (e) => {
    e.preventDefault()
    if(e.target.name=='codigo_seguranca'){
      setFocused('cvc')

    } else {
      setFocused(e.target.name)
    }
  }

  useEffect(()=>{
    setMudaTipo(data.tipo_pessoa)
  }, [data.tipo_pessoa])

  function handleBlur(e){
    e.preventDefault()
    if(e.target.value.includes('X')){
      swal.fire({
        title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
        html: `<p style='color: ${colors.info900}'>Dado incompleto, verifique o número do cartão!</p>`,
        icon: 'warning',
      }).then(r=>{
        document.getElementById(e.target.id).focus()
      })
    }
  }

  function handleBlurEmailParticular (e) {
    if(data.email_particular.split("@").length== 1){
      swal.fire({
        title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
        html: `<p style='color: ${colors.info900}'>Email invalido e/ou obrigatório!</p>`,
        icon: 'warning',
      }).then(r=>{
        document.getElementById('email_particular').focus()
      })
    }
  }
  function handleBlurEmailComercial (e) {
    if(data.email_particular.split("@").length== 1){
      swal.fire({
        title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
        html: `<p style='color: ${colors.info900}'>Email invalido e/ou obrigatório!</p>`,
        icon: 'warning',
      }).then(r=>{
        document.getElementById('email_comercial').focus()
      })
    }
  }

  function handleBandeira (e) {
    console.log(e.issuer)
    console.log(comboBac)
    let value = comboBac.filter((i)=> i.display == e.issuer.toUpperCase())
    if(value.length == 1){
      setFk_bac(value[0].value)
    } else {
      setFk_bac(4)
    }
    console.log(comboBac.filter((i)=> i.display == e.issuer.toUpperCase()))
  }

  return (
    <div className="defaultReg">
    {!props.modal ? <NavBar /> : <></>}
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Associados</h3> 
          {!props.modal ? <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} /> : <></>}
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

        <Tabs aba1_caption="Cadastro" aba1_hidden={false} Aba1={
        <div>
<div style={{borderBottom: '2px solid #389492'}}>
            Dados Gerais
            <br/>
            <br/>
            <FrSelect
              value={data.tipo_pessoa || ''}
              onChange={handleChange}
              name="tipo_pessoa"
              id="tipo_pessoa"
              label='Tipo Pessoa *'
              data={comboTipo}
              style={{ width: 200 }}
            />

           

            
            {mudaTipo == 'J' ?
            <FrTextInput
            maxLength='18'
            value={data.cpf_cnpj ? mCNPJ(data.cpf_cnpj) : ''}
            onChange={handleChange}
            name="cpf_cnpj"
            id="cpf_cnpj"
            color="#389492"
            label="CNPJ"
            variant="outlined"
            size="small"
            onBlur={validaCpfCnpj}
            required
            style={{ width: 160 }}
          /> : 
          <FrTextInput
              maxLength='14'
              value={data.cpf_cnpj ? mCPF(data.cpf_cnpj) : ''}
              onChange={handleChange}
              name="cpf_cnpj"
              id="cpf_cnpj"
              color="#389492"
              label="CPF"
              onBlur={validaCpfCnpj}
              variant="outlined"
              size="small"
              required
              style={{ width: 160 }}
            />}
             <FrTextInput
              maxLength='100'
              value={data.nome || ''}
              onChange={handleChange}
              name="nome"
              id="nome"
              color="#389492"
              label={data.tipo_pessoa == 'J' ? 'Nome Fantasia' :"Nome"}
              variant="outlined"
              size="small"
              required
              style={{ width: 420 }}
            />
            


            <FrDatePicker
              name="data_cadastro"
              id="data_cadastro"
              color="#389492"
              label="Data de Cadastro"
              variant="outlined"
              size="small"
              style={{ width: 180 }}
              value={(data.data_cadastro || '').split("T")[0]}
              onChange={handleChange}
            />

            

            <FrSelect
              value={data.fk_seg || ''}
              onChange={handleChange}
              name="fk_seg"
              id="fk_seg"
              label='Segmento'
              data={comboSeg}
              style={{ width: 200 }}
            />
            <FrSelect
              value={data.fk_cat || ''}
              onChange={handleChange}
              name="fk_cat"
              id="fk_cat"
              label='Categoria'
              data={comboCat}
              style={{ width: 220 }}
            />

            <FrSelect
              value={data.fk_sas || ''}
              onChange={handleChange}
              name="fk_sas"
              id="fk_sas"
              label='Situação'
              data={comboSit}
              style={{ width: 220 }}
            />
      
            <FrTextInput
              maxLength='150'
              value={data.email_particular || ''}
              onChange={handleChange}
              name="email_particular"
              id="email_particular"
              color="#389492"
              label="Email Particular"
              variant="outlined"
              size="small"
              onBlur={handleBlurEmailParticular}
              required
              style={{ width: 450 }}
            />
            <FrDatePicker
              name="data_prev_graduacao"
              id="data_prev_graduacao"
              color="#389492"
              label="Previsão graduação"
              variant="outlined"
              size="small"
              style={{ width: 180 }}
              value={(data.data_prev_graduacao || '').split("T")[0]}
              onChange={handleChange}
            />
            <br/>
           

            
            
          </div>
            <br/>
          <div style={{borderBottom: '2px solid #389492'}}>
            Dados Particulares
            <br/>
            <br/>
            <FrSelect
              value={data.sexo || ''}
              onChange={handleChange}
              name="sexo"
              id="sexo"
              label='Sexo'
              data={comboSexo}
              style={{ width: 120 }}
            />


            <FrDatePicker
              name="data_nascimento"
              id="data_nascimento"
              color="#389492"
              label="Data de Nascimento"
              variant="outlined"
              size="small"
              required={data.tipo_pessoa == 'F'}
              style={{ width: 180 }}
              value={(data.data_nascimento || '').split("T")[0]}
              onChange={handleChange}
            />

            
            <br/>

            <FrTextInput
              maxLength='10'
              value={data.cep_residencial ? mCEP(data.cep_residencial) : ''}
              onChange={handleChange}
              name="cep_residencial"
              id="cep_residencial"
              color="#389492"
              label="CEP"
              variant="outlined"
              size="small"
              onBlur={handleCep}
              required={data.tipo_pessoa == 'F'}
              style={{ width: 110 }}
            />

            <FrTextInput
              maxLength='100'
              value={data.endereco_residencial || ''}
              onChange={handleChange}
              name="endereco_residencial"
              id="endereco_residencial"
              color="#389492"
              label="Endereço"
              variant="outlined"
              size="small"
              required={data.tipo_pessoa == 'F'}
              style={{ width: 450 }}
            />

            <FrTextInput
              maxLength='5'
              value={data.nr_endereco_residencial || ''}
              onChange={handleChange}
              name="nr_endereco_residencial"
              id="nr_endereco_residencial"
              color="#389492"
              label="Número"
              variant="outlined"
              size="small"
              required={data.tipo_pessoa == 'f'}
              style={{ width: 100 }}
            />

            <FrTextInput
              maxLength='20'
              value={data.compl_residencial || ''}
              onChange={handleChange}
              name="compl_residencial"
              id="compl_residencial"
              color="#389492"
              label="Complemento"
              variant="outlined"
              size="small"
              style={{ width: 120 }}
            />
            <FrTextInput
              maxLength='100'
              value={data.bairro_residencial || ''}
              onChange={handleChange}
              name="bairro_residencial"
              id="bairro_residencial"
              color="#389492"
              label="Bairro"
              variant="outlined"
              size="small"
              required={data.tipo_pessoa == 'F'}
              style={{ width: 250 }}
            />

            <FrSelect
              value={data.fk_cid_residencial || ''}
              onChange={handleChange}
              name="fk_cid_residencial"
              id="fk_cid_residencial"
              label='Cidade'
              data={comboCidNat}
              style={{ width: 200 }}
              />

            <FrTextInput
              maxLength='15'
              value={data.fone_residencial ? mTel(data.fone_residencial) : ''}
              onChange={handleChange}
              name="fone_residencial"
              id="fone_residencial"
              color="#389492"
              label="Telefone Residencial"
              variant="outlined"
              size="small"
              required={data.tipo_pessoa == 'F'}
              style={{ width: 180 }}
            />

            <FrTextInput
              maxLength='15'
              value={data.celular_particular ? mTel(data.celular_particular) : ''}
              onChange={handleChange}
              name="celular_particular"
              id="celular_particular"
              color="#389492"
              label="Celular Particular"
              variant="outlined"
              size="small"
              style={{ width: 180 }}
            />

            <FrTextInput
              maxLength='45'
              value={data.matricula_conselho || ''}
              onChange={handleChange}
              name="matricula_conselho"
              id="matricula_conselho"
              color="#389492"
              label="Matrícula Conselho"
              variant="outlined"
              size="small"
              required={data.tipo_pessoa == 'F'}
              style={{ width: 270 }}
            />

            <FrSelect
              value={data.fk_con || ''}
              onChange={handleChange}
              name="fk_con"
              id="fk_con"
              label='Conselho'
              data={comboCon}
              style={{ width: 220 }}
            />

            <FrSelect
              value={data.fk_esp || ''}
              onChange={handleChange}
              name="fk_esp"
              id="fk_esp"
              label='Especialidade'
              data={comboEsp}
              style={{ width: 220 }}
            />
          </div>
              <br/>
          <div style={{borderBottom: '2px solid #389492'}}>
            Dados Comerciais
            <br/>
            <br/>

            <FrTextInput
              maxLength='10'
              value={data.cep_comercial ? mCEP(data.cep_comercial) : ''}
              onChange={handleChange}
              name="cep_comercial"
              id="cep_comercial"
              color="#389492"
              label="CEP"
              variant="outlined"
              size="small"
              onBlur={handleCep}
              required={data.tipo_pessoa == 'J'}
              style={{ width: 110 }}
            />

            <FrTextInput
              maxLength='100'
              value={data.endereco_comercial || ''}
              onChange={handleChange}
              name="endereco_comercial"
              id="endereco_comercial"
              color="#389492"
              label="Endereço"
              variant="outlined"
              size="small"
              required={data.tipo_pessoa == 'J'}
              style={{ width: 450 }}
            />

            <FrTextInput
              maxLength='5'
              value={data.nr_endereco_comercial || ''}
              onChange={handleChange}
              name="nr_endereco_comercial"
              id="nr_endereco_comercial"
              color="#389492"
              label="Número"
              variant="outlined"
              size="small"
              required={data.tipo_pessoa == 'J'}
              style={{ width: 100 }}
            />

            <FrTextInput
              maxLength='20'
              value={data.complemento_comercial || ''}
              onChange={handleChange}
              name="complemento_comercial"
              id="complemento_comercial"
              color="#389492"
              label="Complemento"
              variant="outlined"
              size="small"
              style={{ width: 120 }}
            />
            <FrTextInput
              maxLength='100'
              value={data.bairro_comercial || ''}
              onChange={handleChange}
              name="bairro_comercial"
              id="bairro_comercial"
              color="#389492"
              label="Bairro"
              variant="outlined"
              size="small"
              required={data.tipo_pessoa == 'J'}
              style={{ width: 250 }}
            />

            <FrSelect
              value={data.fk_cid_comercial || ''}
              onChange={handleChange}
              name="fk_cid_comercial"
              id="fk_cid_comercial"
              label='Cidade'
              data={comboCidNat}
              style={{ width: 200 }}
            />

              <br/>

            <FrTextInput
              maxLength='15'
              value={data.fone_comercial ? mTel(data.fone_comercial) : ''}
              onChange={handleChange}
              name="fone_comercial"
              id="fone_comercial"
              color="#389492"
              label="Telefone"
              variant="outlined"
              size="small"
              required={data.tipo_pessoa == 'J'}
              style={{ width: 200 }}
            />

            <FrTextInput
              maxLength='15'
              value={data.celular_comercial ? mTel(data.celular_comercial) : ''}
              onChange={handleChange}
              name="celular_comercial"
              id="celular_comercial"
              color="#389492"
              label="Celular"
              variant="outlined"
              size="small"
              required={data.tipo_pessoa == 'J'}
              style={{ width: 200 }}
            />

            <FrTextInput
              maxLength='150'
              value={data.email_comercial || ''}
              onChange={handleChange}
              name="email_comercial"
              id="email_comercial"
              color="#389492"
              label="Email Comercial"
              variant="outlined"
              size="small"
              handleBlur={handleBlurEmailComercial}
              required={data.tipo_pessoa == 'J'}
              style={{ width: 450 }}
            />

            <FrTextInput
              maxLength='150'
              value={data.url || ''}
              onChange={handleChange}
              name="url"
              id="url"
              color="#389492"
              label="URL"
              variant="outlined"
              size="small"
              required={data.tipo_pessoa == 'J'}
              style={{ width: 450 }}
            />
          </div>

          <br/>
          <div style={{textColor: 'var(--cor-fonte-hover)'} + updateType && fpg != 'N' ? {borderBottom: '2px solid #389492'}: {}}>
            Observações
            <br/>
            <br/>
            <FrTextField
              maxLength='300'
              value={data.observacoes || ''}
              onChange={handleChange}
              name="observacoes"
              id="observacoes"
              color="#389492"
              label="Observações"
              variant="outlined"
              size="small"
              multiline={true}
              rows={3}
              style={{ width: '80%' }}
            />
            {/* <FrTextInput
              maxLength='10'
              value={data.cep_comercial ? mCEP(data.cep_comercial) : ''}
              onChange={handleChange}
              name="cep_comercial"
              id="cep_comercial"
              color="#389492"
              label="CEP"
              variant="outlined"
              size="small"
              onBlur={handleCep}
              required
              style={{ width: 110 }}
            />

            <FrTextInput
              maxLength='100'
              value={data.endereco_comercial || ''}
              onChange={handleChange}
              name="endereco_comercial"
              id="endereco_comercial"
              color="#389492"
              label="Endereço"
              variant="outlined"
              size="small"
              required
              style={{ width: 450 }}
            />

            <FrTextInput
              maxLength='5'
              value={data.nr_endereco_comercial || ''}
              onChange={handleChange}
              name="nr_endereco_comercial"
              id="nr_endereco_comercial"
              color="#389492"
              label="Número"
              variant="outlined"
              size="small"
              required
              style={{ width: 100 }}
            />

            <FrTextInput
              maxLength='20'
              value={data.complemento_comercial || ''}
              onChange={handleChange}
              name="complemento_comercial"
              id="complemento_comercial"
              color="#389492"
              label="Complemento"
              variant="outlined"
              size="small"
              style={{ width: 120 }}
            />
            <FrTextInput
              maxLength='100'
              value={data.bairro_comercial || ''}
              onChange={handleChange}
              name="bairro_comercial"
              id="bairro_comercial"
              color="#389492"
              label="Bairro"
              variant="outlined"
              size="small"
              required
              style={{ width: 250 }}
            />

            <FrSelect
              value={data.fk_cid_comercial || ''}
              onChange={handleChange}
              name="fk_cid_comercial"
              id="fk_cid_comercial"
              label='Cidade'
              data={comboCidNat}
              style={{ width: 200 }}
            />

              <br/>

            <FrTextInput
              maxLength='15'
              value={data.fone_comercial ? mTel(data.fone_comercial) : ''}
              onChange={handleChange}
              name="fone_comercial"
              id="fone_comercial"
              color="#389492"
              label="Telefone"
              variant="outlined"
              size="small"
              required
              style={{ width: 200 }}
            />

            <FrTextInput
              maxLength='15'
              value={data.celular_comercial ? mTel(data.celular_comercial) : ''}
              onChange={handleChange}
              name="celular_comercial"
              id="celular_comercial"
              color="#389492"
              label="Celular"
              variant="outlined"
              size="small"
              required
              style={{ width: 200 }}
            />

            <FrTextInput
              maxLength='150'
              value={data.email_comercial || ''}
              onChange={handleChange}
              name="email_comercial"
              id="email_comercial"
              color="#389492"
              label="Email Comercial"
              variant="outlined"
              size="small"
              required
              style={{ width: 450 }}
            /> */}
          </div>
          <br/>
          <div hidden={!updateType || fpg == 'N'} style={{color: 'var(--cor-fonte-hover)'}}>
            Dados Financeiros
            <br/>
            <br/>
            <FrSelect
              value={data.fk_bco || ''}
              onChange={handleChange}
              name="fk_bco"
              id="fk_bco"
              hidden={fpg != 'B'}
              label='Banco'
              data={comboBco}
              style={{ width: 200 }}
            />

            <FrTextInput
              maxLength='45'
              value={data.agencia || ''}
              onChange={handleChange}
              name="agencia"
              id="agencia"
              color="#389492"
              label="Agência"
              variant="outlined"
              hidden={fpg != 'B'}
              size="small"
              required={data.fk_bco?.length>0}
              style={{ width: 140 }}
            />

            <FrTextInput
              maxLength='45'
              value={data.agencia_digito || ''}
              onChange={handleChange}
              name="agencia_digito"
              id="agencia_digito"
              color="#389492"
              label="Dígito Agência"
              variant="outlined"
              hidden={fpg != 'B'}
              size="small"
              required={data.fk_bco?.length>0}
              style={{ width: 140 }}
            />

            <FrTextInput
              maxLength='45'
              value={data.conta || ''}
              onChange={handleChange}
              name="conta"
              id="conta"
              color="#389492"
              label="Conta"
              variant="outlined"
              hidden={fpg != 'B'}
              size="small"
              required={data.fk_bco?.length>0}
              style={{ width: 210 }}
            />

            <FrTextInput
              maxLength='45'
              value={data.conta_digito || ''}
              onChange={handleChange}
              name="conta_digito"
              id="conta_digito"
              color="#389492"
              label="Dígito Conta"
              variant="outlined"
              hidden={fpg != 'B'}
              size="small"
              required={data.fk_bco?.length>0}
              style={{ width: 210 }}
            />

            <br/>
            <DadosCartao 
              comboBac={comboBac}
              fk_bac={fk_bac}
              codigo_seguranca={codigo_seguranca}
              numero_cartao={numero_cartao}
              ano_validade_Cartao={ano_validade_Cartao}
              mes_valid_cartao={mes_valid_cartao}
              nome_cartao={nome_cartao}
              setFk_bac={setFk_bac}
              setCodigo_seguranca={setCodigo_seguranca}
              setNumero_cartao={setNumero_cartao}
              setAno_validade_Cartao={setAno_validade_Cartao}
              setMes_valid_cartao={setMes_valid_cartao}
              setNome_cartao={setNome_cartao}
              fpg={fpg}
              callback={handleBandeira}
            />
            {/* <div style={{display: 'flex', flexDirection:'row'}}>
              <div style={{marginRight: '5px'}}>
                <Cartao 
                  numero={data.numero_cartao}
                  cvc={data.codigo_seguranca}
                  validade={data.mes_valid_cartao + '/' + data.ano_validade_Cartao}
                  nome={data.nome_cartao}
                  focused={focused}
                />

              </div>
              <div >

                <FrSelect
                  value={data.fk_bac || ''}
                  onChange={handleChange}
                  name="fk_bac"
                  id="fk_bac"
                  label='Bandeira Cartão'
                  required
                  onFocus={handleFocusCartao}
                  hidden={fpg != 'C'}
                  data={comboBan}
                  style={{ width: 140 }}
                />

                <FrTextInput
                  maxLength='19'
                  value={data.numero_cartao ? mCAR(data.numero_cartao) : ''}
                  onChange={handleChange}
                  name="numero_cartao"
                  id="numero_cartao"
                  color="#389492"
                  label="Número Cartão"
                  variant="outlined"
                  hidden={fpg != 'C'}
                  onFocus={handleFocusCartao}
                  onBlur={handleBlur}
                  required
                  size="small"
                  style={{ width: 300 }}
                />
                <br/>

                <FrTextInput
                  maxLength='80'
                  value={data.nome_cartao || ''}
                  onChange={handleChange}
                  name="nome_cartao"
                  id="nome_cartao"
                  color="#389492"
                  label="Nome Cartão"
                  variant="outlined"
                  onFocus={handleFocusCartao}
                  hidden={fpg != 'C'}
                  onBlur={handleBlur}
                  required
                  size="small"
                  style={{ width: 445 }}
                />
                <br/>
                
                <FrSelect
                  value={data.mes_valid_cartao || ''}
                  onChange={handleChange}
                  name="mes_valid_cartao"
                  id="mes_valid_cartao"
                  label='Mês Validade'
                  onFocus={handleFocusCartao}
                  hidden={fpg != 'C'}
                  data={comboMes}
                  required
                  style={{ width: 140 }}
                />

                <FrSelect
                  value={data.ano_validade_Cartao || ''}
                  onChange={handleChange}
                  name="ano_validade_Cartao"
                  id="ano_validade_Cartao"
                  label='Ano Validade'
                  onFocus={handleFocusCartao}
                  hidden={fpg != 'C'}
                  required
                  data={comboAno}
                  style={{ width: 140 }}
                />

                <FrTextInput
                  maxLength='10'
                  value={data.codigo_seguranca || ''}
                  onChange={handleChange}
                  name="codigo_seguranca"
                  id="codigo_seguranca"
                  onFocus={handleFocusCartao}
                  color="#389492"
                  label="Código Segurança"
                  variant="outlined"
                  hidden={fpg != 'C'}
                  required
                  size="small"
                  style={{ width: 153 }}
                />

                <FrTextInput
                  maxLength='100'
                  value={data.token_cartao || ''}
                  onChange={handleChange}
                  onFocus={handleFocusCartao}
                  name="token_cartao"
                  id="token_cartao"
                  color="#389492"
                  label="Token Cartão"
                  variant="outlined"
                  required
                  size="small"
                  hidden={true}
                  style={{ width: 280 }}
                />
              </div>

            </div> */}

          </div>
        </div>}

          aba2_caption="Termo de Associação" aba2_hidden={false} Aba2={

            <div className="tableDiv">
              <FrTable regName="associado_plano" columns={columns} codeName="pk_asp" page={'Associado_Plano'} data={dataPla} enableEdit={enableEdit} enableDel={false} />

            </div>

          }
        />

        
        
        
          <div >
            <Dialog aria-labelledby="simple-dialog-title" open={showModalCtr} fullWidth={false} maxWidth={'lg'} >

              <div style={{ padding: '20px', paddingTop: '40px' }}>
                <FrTable mostraCheck={true} regName="contas_receber" columns={columnsCtr} searched={false} codeName="pk_ctr" page={'contas_receber'} data={dataCtr} enableEdit={'S'} enableDel={'N'} setSelected={setSelected} selected={selected} />
              </div>

              <div className="FrModalFooter">
                <div className="FrModalButtonsDiv" >
                  <button style={{ height: '40px' }} onClick={confirmaModalCtr}> Confirmar </button>
                  <button style={{ height: '40px' }} onClick={escondeModalCtr}> Sair </button>
                </div>
              </div>
            </Dialog>
          </div>



        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={verContas}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        {!props.modal ? <FooterDelphus /> : <></>}
      </div>
    </div>
  );
}

export default Associados;
