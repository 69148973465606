import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import { fileExcel } from 'react-icons-kit/icomoon/fileExcel'
import ReactExport from "react-data-export";
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import {mCPF} from '../utils/masks'
import FooterDelphus from '../components/FooterDelphus'
import { colors } from '../utils/vars';
import { ic_done_all } from 'react-icons-kit/md/ic_done_all'
import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, getUserCode } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Contas_Pagar(props) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [selected, setSelected] = useState([])
  const [enableDel, setenableDel] = useState('N')
  const [comboTde, setComboTde] = useState([{ value: '', display: '' }])
  const [comboCla, setComboCla] = useState([{ value: '', display: '' }])
  const [comboFpg, setComboFpg] = useState([{ value: '', display: '' }])
  const [comboCta, setComboCta] = useState([{ value: '', display: '' }])
  const [comboFor, setComboFor] = useState([{ value: '', display: '' }])
  const [comboCst, setComboCst] = useState([{ value: '', display: '' }])
  const [comboSit, setComboSit] = useState([{ value: 'L', display: 'Liquidado' }, { value: 'P', display: 'Pendente' }])
  const [comboCancelado, setComboCancelado] = useState([{ value: 'S', display: 'Sim' }, { value: 'N', display: 'Não' }])
  const [dataPlanilha, setDataPlanilha] = useState([])
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
 
  const columns = [
    { id: 'numdoc', numeric: false, label: 'Número doc.', align: 'left', minWidth: 200 },
    { id: 'fornecedor', numeric: false, label: 'Fornecedor', align: 'left', minWidth: 200 },
    { id: 'formatdata_emissao', numeric: false, label: 'Dt. Emissão', align: 'left', minWidth: 200, order:'data_emissao' },
    { id: 'formatdata_vencimento', numeric: false, label: 'Dt. Vencimento', align: 'left', minWidth: 200, order: 'data_vencimento' },
    { id: 'nomefpg', numeric: false, label: 'Forma de Pagamento', align: 'left', minWidth: 200, },
    { id: 'formatValor', numeric: false, label: 'Valor', align: 'left', minWidth: 200, },
    { id: 'formatValor_pendente', numeric: false, label: 'Valor Pendente', align: 'left', minWidth: 150 , },
    { id: 'formatdata_liquidacao', numeric: false, label: 'Dt. liquidação', align: 'left', minWidth: 150 , order: 'data_liquidacao'},
  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setComboTde(await api.getComboData('tipo_despesa'))
      setComboFpg(await api.getComboData('forma_pagamento'))
      setComboCst(await api.getComboData('centro_custo'))
      setComboCla(await api.getComboData('classes'))      
      setComboCta(await api.getComboData('contas'))
      setComboFor(await api.getComboData('fornecedores'))

      setenableAdd(await api.getAcessos('Contas a Pagar', 'Inclusao'))
      setenableEdit(await api.getAcessos('Contas a Pagar', 'Alteracao'))
      setenableDel(await api.getAcessos('Contas a Pagar', 'Exclusao'))


      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        getData(event)
      }
    }

    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/celg/contas_pagar?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/contas_pagar', { params: queryObject }).then(r => {
        setData(r.data)
        console.log(r.data)
        setDataPlanilha(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/celg/contas_pagar');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    switch (e.target.name) {
      case 'cpf': auxValues.cpf = String(mCPF(e.target.value)) 
      
      default: auxValues[e.target.name] = e.target.value;
    }
    console.log(mCPF(e.target.value), auxValues)
    
    setFilter(auxValues);
  };

  const geraBoleto = async(e) => {
    e.preventDefault()
    var liquidado = ''
    var erros = {
      naoSelecionado: function () {
        return swal.fire({
          title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
          html: `<p style='color:${colors.info900}'>Nenhum registro selecionado!</p>`,
          icon: 'warning',
        })
      },

      liquidados: function () {
        return swal.fire({
          title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
          html: `<p style='color:${colors.info900}'>Registro liquidado selecionado!</p>`,
          icon: 'warning',
        })
      },
      semValor: function () {
        return swal.fire({
          title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
          html: `<p style='color:${colors.info900}'>Registro sem valor selecionado!</p>`,
          icon: 'warning',
        })
      }

    }
    if(selected.length === 0){
      return erros.naoSelecionado()
    }

    liquidado = await(api.get('/contas_pagar?ctrs='+selected))

    if (liquidado.data.length>0){
      let erro='certo'
      liquidado.data.forEach( r => {
        if (r.valor - r.valor_desconto + r.valor_juros + r.valor_multa - r.valor_liquidado <= 0){
          erro = 'liquidados'
        }
        if (r.valor == 0){
          erro = 'semValor'
        }
      })
      console.log(erro)
      if(erro != 'certo'){
        erro = erros[erro]
        return erro()
      }
    }

    console.log('continuou')

    api.post('/contas_pagar/geraBoleto',{ctrs: [...selected], fk_usu: getUserCode()}).then( r=> {
      if(r.data == 'Gerado'){
        swal.fire({
          title: `<h2 style='color: ${colors.info900}'>Sucesso!</h2>`,
          html: `<p style='color:${colors.info900}'>Boleto Gerado!</p>`,
          icon: 'success',
        })
      } else {
        swal.fire({
          title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
          html: `<p style='color:${colors.info900}'>Erro no registro do boleto!</p>`,
          icon: 'warning',
        })
      }
    })
  }

  function liquidacaoLote(e) {
    e.preventDefault();
    if (selected.length == 0) {
      swal.fire('Nenhum registro selecionado!', 'Selecione registros para liquidação em lote.', 'warning')
    } else {
      window.location.href = "/celg/contas_pagar/liquidacaoLote?pks=[" + selected + ']'
    }
  }

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Contas a Pagar</h3>
          <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
              <>
                <FrDatePicker
                  value={(filter.data_emis_ini || '').split('T')[0]}
                  name="data_emis_ini"
                  id="data_emis_ini"
                  color="#528b46"
                  label="Período de Emissão"
                  variant="outlined"
                  size="small"
                  style={{ width: (190), flex: ('') }}
                  onChange={handleChange}
                />

                <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                <FrDatePicker
                  value={(filter.data_emis_fim || '').split('T')[0]}
                  name="data_emis_fim"
                  id="data_emis_fim"
                  color="#528b46"
                  variant="outlined"
                  size="small"
                  style={{ width: 190, flex:  '' }}
                  onChange={handleChange}

                />
              </>
              <>
                <FrDatePicker
                  value={(filter.data_venc_ini || '').split('T')[0]}
                  name="data_venc_ini"
                  id="data_venc_ini"
                  color="#528b46"
                  label="Período de Vencimento"
                  variant="outlined"
                  size="small"
                  style={{ width: (190), flex: ('') }}
                  onChange={handleChange}
                />

                <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                <FrDatePicker
                  value={(filter.data_venc_fim || '').split('T')[0]}
                  name="data_venc_fim"
                  id="data_venc_fim"
                  color="#528b46"
                  variant="outlined"
                  size="small"
                  style={{ width: 190, flex:  '' }}
                  onChange={handleChange}

                />
              </>
              <>
                <FrDatePicker
                  value={(filter.data_liq_ini || '').split('T')[0]}
                  name="data_liq_ini"
                  id="data_liq_ini"
                  color="#528b46"
                  label="Período de Liquidação"
                  variant="outlined"
                  size="small"
                  style={{ width: (190), flex: ('') }}
                  onChange={handleChange}
                />

                <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                <FrDatePicker
                  value={(filter.data_liq_fim || '').split('T')[0]}
                  name="data_liq_fim"
                  id="data_liq_fim"
                  color="#528b46"
                  variant="outlined"
                  size="small"
                  style={{ width: 190, flex:  '' }}
                  onChange={handleChange}

                />
              </>
              <br/>
              <FrSelect
                value={filter.fk_tde || ''}
                style={{ width: 260 }}
                name="fk_tde"
                id="fk_tde"
                label='Tipo Despesa'
                data={comboTde}
                onChange={handleChange}
              />
              <FrSelect
                value={filter.fk_fpg || ''}
                style={{ width: 200 }}
                name="fk_fpg"
                id="fk_fpg"
                label='Forma de Pagamento'
                data={comboFpg}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.fk_cst || ''}
                style={{ width: 170 }}
                name="fk_cta"
                id="fk_cta"
                label='Contas'
                data={comboCta}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.fk_cla || ''}
                style={{ width: 170 }}
                name="fk_cla"
                id="fk_cla"
                label='Classe'
                data={comboCla}
                onChange={handleChange}
              />
              <FrSelect
                value={filter.fk_cst || ''}
                style={{ width: 170 }}
                name="fk_cts"
                id="fk_cst"
                label='Centro de Custo'
                data={comboCst}
                onChange={handleChange}
              />



            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>

              <button onClick={liquidacaoLote}>
                <Icon icon={ic_done_all} size={18} />
                Liquidação em Lote
              </button>

              {enableAdd !== 'S' ? '' :
                <Link to="/celg/contas_pagar/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>
                </Link>}

              <ExcelFile element={<button>
                <Icon icon={fileExcel} size={18} />
                Exportar</button>}>
                <ExcelSheet data={dataPlanilha} name="Contas Pagar">
                  <ExcelColumn label="Número Doc." value="numdoc" />
                  <ExcelColumn label="Dt. Emissão" value="formatdata_emissao" />
                  <ExcelColumn label="Fornecedor" value="fornecedor" />
                  <ExcelColumn label="Dt. Vencimento" value="formatdata_vencimento" />
                  <ExcelColumn label="Valor" value="formatValor" />
                  <ExcelColumn label="Dt. Liquidação" value="formatdata_liquidacao" />
                  <ExcelColumn label="Tipo Despesa" value="tipo_despesa" />
                  <ExcelColumn label="Centro de Custo" value="centro_custo" />
                  <ExcelColumn label="Classe" value="classe" />
                  <ExcelColumn label="Valor Liquidado" value="formatValor_liquidado" />
                  <ExcelColumn label="Observações" value="observacoes" />
                  <ExcelColumn label="Conta" value="conta" />
                  <ExcelColumn label="Forma Pagamento" value="nomefpg" />
                </ExcelSheet>
              </ExcelFile>  
             
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable regName="contas_pagar" mostraCheck columns={columns} selected={selected} setSelected={setSelected} searched={filtered} codeName="pk_ctp" page={'Contas_Pagar'} data={data} enableEdit={enableEdit} enableDel={enableDel} />
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Contas_Pagar;
