import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import {colors} from '../utils/vars'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FrDatePicker from '../components/FrDatePicker'
import FooterDelphus from '../components/FooterDelphus';
import consultaCep from '../services/consultaCep'
import {mVAL, mPERC} from '../utils/masks'
import { Hidden } from '@material-ui/core';

const swal = withReactContent(Swal)

function Itens_Cobranca(props) {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [comboSer, setComboSer] = useState([{ value: '', display: '' }])
  const [comboAss, setComboAss] = useState([{ value: '', display: '' }])
  const [comboTco, setComboTco] = useState([{ value: '', display: '' }])
  const [comboEstornado, setComboEstornado] = useState([{ value: 'S', display: 'Sim' }, { value: 'N', display: 'Não' }])
  const [enableEdit, setEnableEdit] = useState('N')

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      
      setComboSer(await api.getComboData('tipo_servico'))
      setComboTco(await api.getComboData('tipo_cobranca'))
      setComboAss(await api.getComboData('associados'))

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Itens de Cobrança', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Itens de Cobrança', 'Inclusao'))
      }


      if (query.codigo > 0) {
        setUpdateType(true)
        api.get('/itens_cobranca?pk_icb=' + query.codigo).then(r => {
          if (r.data[0]) {
            let auxData={...r.data}
            auxData[0].cnpj? auxData[0].pf_pj='J' : auxData[0].pf_pj='F'
            auxData[0].cpf? auxData[0].pf_pj='F' : auxData[0].pf_pj='J'
            
            setData(auxData[0])
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'data',
      'data_previsao_vcto',
      'qtde',
      'valor',
      'fk_ass',
      'fk_ser',
      'fk_tco',
    ]

    let errors = []

    

    required.forEach((curr, index) => {
      let fieldName = curr
      if(fieldName == 'data') { fieldName= 'Data de Emissão'}
      if(fieldName == 'data_previsao_vcto') { fieldName= 'Data de Previsão'}
      if(fieldName == 'qtde') { fieldName= 'Quantidade'}
      if(fieldName == 'valor') { fieldName= 'Valor'}
      if(fieldName == 'fk_ass') { fieldName= 'Associado'}
      if(fieldName == 'fk_ser') { fieldName= 'Tipo de Serviço'}
      if(fieldName == 'fk_tco') { fieldName = 'Tipo de Cobrança'}

      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color:${colors.info900}'>Deseja salvar ${updateType ? 'as alterações' : 'a inclusão'} do registro?</p>`,
      // html: `<p style='color:${colors.info900}'>Deseja salvar ${updateType ? 'as alterações' : 'a inclusão'} do registro?</p>`,
      color: colors.info900,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: colors.success500,
      cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.host = window.location.href;
        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/itens_cobranca/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Registro Alterado<h2>`,
                  html: `<p style='color: ${colors.info900}'>O registro foi alterado com sucesso!</p>`,
                  icon: 'success',
                }).then((result) => {
                  if (result.value) {
                    window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                  html: `<p style='color: ${colors.info900}'></p>`,
                  
                  icon: 'warning',
                })
              }
            })
          } else {
            try {
              api.post('/itens_cobranca/add', auxValues).then(r => {
                console.log(r.status)
                if (r.status === 200) {
                  swal.fire({
                    title: 'Registro Adicionado',
                    text: "O registro foi adicionado com sucesso!",
                    icon: 'success',
                  }).then((result) => {
                    if (result.value) {
                      window.history.back()
                    }
                  })
                } else {
                  swal.fire({
                    title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                    text: "O registro não foi adicionado!",
                    icon: 'warning',
                  })
                }
              })
            } catch {
              swal.fire({
                title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })

            }

          }
        } else {
          swal.fire({
            title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
            html: <p style={{color: colors.info900}}>Verifique {errors.length>1?'os campos': 'o campo'} {errors.join(', ')}</p>,
            icon: 'warning',
          })
        }
      }
    })
  }

  const handleCep = (e) => {
    e.preventDefault()
    const auxValues = { ...data };
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    
    consultaCep((e.target.value).replace('.','').replace('-','')+'/json').then(r =>{
      if(!r.data.erro){
        swal.close()
        console.log(r.data)
        auxValues.endereco = r.data.logradouro
        auxValues.complemento = r.data.complemento
        auxValues.nr_endereco = r.data.numero

        setData(auxValues)
      } else{
        swal.close()
        swal.fire('CEP Inválido!', 'CEP Não encontrado nos registros!', 'warning')
      }
    })

    
   

  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color: ${colors.info900}'>Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?<p>`,
      icon: 'warning',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
      cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    // auxValues[e.target.name] = e.target.value;
    switch (e.target.name) {
      case 'valor':
        auxValues[e.target.name] = mVAL(e.target.value);
        break
        case 'desconto':
          auxValues[e.target.name] = mPERC(e.target.value);
          break
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };



  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Itens de Cobrança</h3>
          <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrDatePicker
            name="data"
            id="data"
            color="#389492"
            label="Data de Emissão"
            variant="outlined"
            size="small"
            style={{ width: 180 }}
            value={(data.data || '').split("T")[0]}
            onChange={handleChange}
          />

          <FrDatePicker
            name="data_previsao_vcto"
            id="data_previsao_vcto"
            color="#389492"
            label="Data de Vencimento"
            variant="outlined"
            size="small"
            style={{ width: 180 }}
            value={(data.data_previsao_vcto || '').split("T")[0]}
            onChange={handleChange}
          />  

          <FrSelect
            value={data.fk_ass || ''}
            onChange={handleChange}
            name="fk_ass"
            id="fk_ass"
            label='Associado'
            data={comboAss}
            style={{ width: 250 }}
          />
         
         <FrSelect
            value={data.fk_ser || ''}
            onChange={handleChange}
            name="fk_ser"
            id="fk_ser"
            label='Tipo de Serviço'
            data={comboSer}
            style={{ width: 150 }}
          />

          <FrTextInput
            maxLength='100'
            value={data.descricao || ''}
            onChange={handleChange}
            name="descricao"
            id="descricao"
            color="#389492"
            label="Descrição"
            variant="outlined"
            size="small"
            required
            style={{ width: 450 }}
          />

          <FrTextInput
            maxLength='10'
            value={data.qtde || ''}
            onChange={handleChange}
            name="qtde"
            id="qtde"
            color="#389492"
            label="Quantidade"
            variant="outlined"
            size="small"
            required
            style={{ width: 150 }}
          />

          <FrTextInput
            maxLength='10'
            value={data.valor || ''}
            onChange={handleChange}
            name="valor"
            id="valor"
            color="#389492"
            label="Valor"
            variant="outlined"
            size="small"
            required
            style={{ width: 150 }}
          />
          <FrTextInput
            maxLength='100'
            value={data.desconto? mPERC(data.desconto) : ''}
            onChange={handleChange}
            name="desconto"
            id="desconto"
            color="#389492"
            label="Desconto (%)"
            variant="outlined"
            size="small"
            style={{ width: 150 }}
          />
          <FrTextInput
            maxLength='100'
            value={data.acrescimo? mVAL(data.acrescimo) : ''}
            onChange={handleChange}
            name="acrescimo"
            id="acrescimo"
            color="#389492"
            label="Acréscimo"
            variant="outlined"
            size="small"
            required
            style={{ width: 150 }}
          />

          <FrSelect
            value={data.estornado || ''}
            onChange={handleChange}
            name="estornado"
            id="estornado"
            label='Estornado'
            data={comboEstornado}
            style={{ width: 150 }}
          />

          {/* <FrSelect
            value={data.fk_ctr || ''}
            onChange={handleChange}
            name="fk_ctr"
            id="fk_ctr"
            label='Conta'
            data={comboCtr}
            style={{ width: 150 }}
          /> */}

          <FrSelect
            value={data.fk_tco || ''}
            onChange={handleChange}
            name="fk_tco"
            id="fk_tco"
            label='Tipo de Cobrança'
            data={comboTco}
            style={{ width: 150 }}
          />


        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Itens_Cobranca;
