import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import FrListSelect from '../components/FrListSelect'
import { Dialog } from '@material-ui/core';

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTableReg from '../components/FrTableReg'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import {mCPF, mCNPJ} from '../utils/masks'
import FooterDelphus from '../components/FooterDelphus'
import RegAssociados from './RegAssociados'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString, isEmpty } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Associados(props) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  const [mudaTipo, setMudaTipo] = useState('F')
  const [comboSeg, setComboSeg] = useState([{ value: '', display: '' }])
  const [comboTipo, setComboTipo] = useState([{ value: 'F', display: 'Física' },{ value: 'J', display: 'Jurídica' }])
  const [comboCidCom, setComboCidCom] = useState([{ value: '', display: '' }])
  const [comboCidRes, setComboCidRes] = useState([{ value: '', display: '' }])
  const [comboEst, setComboEst] = useState([{ value: '', display: '' }])
  const [comboCon, setComboCon] = useState([{ value: '', display: '' }])
  const [comboEsp, setComboEsp] = useState([{ value: '', display: '' }])
  const [comboCat, setComboCat] = useState([{ value: '', display: '' }])
  const [comboSit, setComboSit] = useState([{ value: '', display: '' }])
  const [checked ,setChecked] = useState([])
  const [pk_ass ,setPk_ass] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [firstSel, setFirstSel] = useState()

  const columns = [
    { id: 'cpf_formatado', numeric: false, label: 'CPF', align: 'left', minWidth: 200 },
    { id: 'nome', numeric: false, label: 'Nome', align: 'left', minWidth: 150 },
    { id: 'especialidade', numeric: false, label: 'Especialidade', align: 'left', minWidth: 200 },
    { id: 'categoria', numeric: false, label: 'Categoria', align: 'left', minWidth: 200 },
    { id: 'situacao', numeric: false, label: 'Situação', align: 'left', minWidth: 200 },
  ]

  useEffect(() => {
    const fetchData = async () => {
      // if (!props.modal)
      swal.fire({
        
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setComboCidCom(await api.getComboData('cidades'))
      setComboCidRes(await api.getComboData('cidades'))
      setComboEst(await api.getComboData('estados'))
      setComboCon(await api.getComboData('conselho'))
      setComboSeg(await api.getComboData('segmentos'))
      setComboEsp(await api.getComboData('especialidade'))
      setComboCat(await api.getComboData('categoria_associado'))
      setComboSit(await api.getComboData('situacao_associado'))

      setenableAdd(await api.getAcessos('Associados', 'Inclusao'))
      setenableEdit(await api.getAcessos('Associados', 'Alteracao'))
      setenableDel(await api.getAcessos('Associados', 'Exclusao'))


      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      console.log(query)
      setFilter(query)
      if(query.newreg) setFirstSel(query.newreg);
      populateForm(document.getElementById('searchForm'), query)

      // if (!props.modal) 
      swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        getData(event)
      }
    }

    fetchData()
    console.log(props)
  }, [])


  function removeEmpty(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != ''));
  }

  function filterObject(obj, callback) {
    

    return Object.fromEntries(Object.entries(obj).
      filter(([key, val]) => callback(val, key)));
  }



  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (!isEmpty(queryObject)) {
      queryObject.buscar = true
      setFiltered(true)
      if (!props.modal) {
        window.history.replaceState({ filtered: true }, 'filter', "/celg/associados?" + objectToQueryString(queryObject));
      }
        swal.fire({
          
          html: <FrLoading text="Buscando dados..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      console.log(removeEmpty(filter))
      api.get('/associados', { params: (props.modal ? removeEmpty(filter) : queryObject) }).then(r => {
        setData(r.data)
        console.log(r.data)
        // if (!props.modal) 
        swal.close()
      })
    } else {
      swal.fire({
        title: 'Nenhum filtro informado!',
        html: '<p>Informe ao menos um filtro para realizar a busca.',
        icon: 'warning'
      })
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    if (!props.modal){
      window.history.replaceState({ filtered: false }, 'filter', '/celg/associados');
      document.getElementById('searchForm').reset()
    }
    setFilter({})
  }
  function mostraModal(e) {
    e.preventDefault();
    setShowModal(true)
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    switch (e.target.name) {
      case 'cpf': auxValues.cpf = String(mCPF(e.target.value)) 
      
      default: auxValues[e.target.name] = e.target.value;
    }
    console.log(mCPF(e.target.value), auxValues)
    
    setFilter(auxValues);
  };

  useEffect(()=>{
    setMudaTipo(filter.tipo_pessoa)
  }, [filter.tipo_pessoa])


  return (
    <div className="Manut">
       {!props.modal ? <NavBar /> : <></>}
       <div className={props.modal ? "ManutContentModal" : "ManutContent"}>
        <div className="defaultHeader">
          <h3>Associados</h3>
          {!props.modal ? <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} /> : <></>}
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
            <FrTextInput
            maxLength='100'
            value={filter.nome || ''}
            onChange={handleChange}
            name="nome"
            id="nome"
            color="#389492"
            label="Nome"
            variant="outlined"
            size="small"
            style={{ width: 300 }}
          />

          <FrSelect
            value={filter.tipo_pessoa || ''}
            onChange={handleChange}
            name="tipo_pessoa"
            id="tipo_pessoa"
            label='Tipo Pessoa'
            data={comboTipo}
            style={{ width: 200 }}
          />
          {mudaTipo == 'J' ?
            <FrTextInput
            maxLength='18'
            value={filter.cpf_cnpj ? mCNPJ(filter.cpf_cnpj) : ''}
            onChange={handleChange}
            name="cpf_cnpj"
            id="cpf_cnpj"
            color="#389492"
            label="CNPJ"
            variant="outlined"
            size="small"
            style={{ width: 160 }}
          /> : 
          <FrTextInput
              maxLength='14'
              value={filter.cpf_cnpj ? mCPF(filter.cpf_cnpj) : ''}
              onChange={handleChange}
              name="cpf_cnpj"
              id="cpf_cnpj"
              color="#389492"
              label="CPF"
              variant="outlined"
              size="small"
              style={{ width: 160 }}
            />}
          

          <FrSelect
            value={filter.fk_cid_comercial || ''}
            onChange={handleChange}
            name="fk_cid_comercial"
            id="fk_cid_comercial"
            label='Cidade Comercial'
            data={comboCidCom}
            style={{ width: 250 }}
          />

          <FrSelect
            value={filter.fk_cid_residencial || ''}
            onChange={handleChange}
            name="fk_cid_residencial"
            id="fk_cid_residencial"
            label='Cidade Residencial'
            data={comboCidRes}
            style={{ width: 250 }}
          />

          <FrSelect
            value={filter.fk_est || ''}
            onChange={handleChange}
            name="fk_est"
            id="fk_est"
            label='Estado'
            data={comboEst}
            style={{ width: 200 }}
          />

          <br/>

          <FrSelect
            value={filter.fk_esp || ''}
            onChange={handleChange}
            name="fk_esp"
            id="fk_esp"
            label='Especialidade'
            data={comboEsp}
            style={{ width: 180 }}
          />

          <FrSelect
            value={filter.fk_cat || ''}
            onChange={handleChange}
            name="fk_cat"
            id="fk_cat"
            label='Categoria'
            data={comboCat}
            style={{ width: 180 }}
          />

          <FrSelect
            value={filter.fk_con || ''}
            onChange={handleChange}
            name="fk_con"
            id="fk_con"
            label='Conselho'
            data={comboCon}
            style={{ width: 180 }}
          />

          <FrTextInput
            maxLength='45'
            value={filter.matricula_conselho || ''}
            onChange={handleChange}
            name="matricula_conselho"
            id="matricula_conselho"
            color="#389492"
            label="Matrícula Conselho"
            variant="outlined"
            size="small"
            style={{ width: 200 }}
          />

          <FrSelect
            value={filter.fk_seg || ''}
            onChange={handleChange}
            name="fk_seg"
            id="fk_seg"
            label='Segmento'
            data={comboSeg}
            style={{ width: 200 }}
          />

          {/* <FrListSelect 
          value={filter.fk_sas || ''}
            onChange={handleChange}
            name="fk_sas"
            id="fk_sas"
            label='Situação'
            data={comboSit}
            checked={checked}
            pk='value'
            text = 'display'
            setChecked={setChecked}
            style={{ width: 80, marginBottom: 10 }}/> */}
            
          <FrSelect
            value={filter.fk_sas || ''}
            onChange={handleChange}
            name="fk_sas"
            id="fk_sas"
            label='Situação'
            data={comboSit}
            style={{ width: 140 }}
          />

          
              

            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
           
              {enableAdd !== 'S' || props.modal ?
                <button onClick={mostraModal}>
                  <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>

                :
                <Link to="/celg/associados/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}

            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
        {props.modal ? 
          <FrTableReg 
            setShowModal={setShowModal}
            setpk_ass={setPk_ass}
            editar={true}
            f2={true}
            ass={props.ass}
            setAss={props.setAss}
            regName="associados"
            columns={columns}
            searched={filtered}
            codeName="pk_ass"
            page={'Associados'}
            data={data}
            enableEdit={enableEdit}
            enableDel={enableDel}
          />
          :
          <FrTable
            regName="associados"
            columns={columns}
            searched={filtered}
            codeName="pk_ass"
            page={'Associados'}
            data={data}
            enableEdit={enableEdit}
            enableDel={enableDel}
            firstSel={firstSel}
          /> }
        </div>

        <Dialog
          aria-labelledby="simple-dialog-title"
          open={showModal}
          fullWidth={true}
          maxWidth={'lg'}
        >

          <RegAssociados 
            pk_ass={pk_ass}
            editar={true}
            setShowModal={setShowModal}
            modal={true}>              
          </RegAssociados>
        </Dialog>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Associados;
