import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import { Icon } from 'react-icons-kit'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import {colors} from '../utils/vars'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'
import Dialog from '@material-ui/core/Dialog';

import './styles/DefaultReg.css';
import FrDatePicker from '../components/FrDatePicker'
import FooterDelphus from '../components/FooterDelphus';
import consultaCep from '../services/consultaCep'
import {getUserCode} from '../utils/functions'
import {mVAL} from '../utils/masks'
import { Hidden } from '@material-ui/core';
import FrTable from '../components/FrTable'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import FrTableReg from '../components/FrTableReg'
import { MDBContainer, MDBIframe } from "mdbreact";

const swal = withReactContent(Swal)

function Contas_Receber(props) {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [selected, setSelected] = useState({});
  const [showModal, setShowModal] = useState(false)
  const [pk_bol, setPk_bol] = useState(0)
  const [dataCco, setDataCco] = useState([{ fk_cto: '' }])
  const [dataIcb, setDataIcb] = useState([{ fk_icb: '' }])
  const [comboFpg, setComboFpg] = useState([{ value: '', display: '' }])
  const [comboAss, setComboAss] = useState([{ value: '', display: '' }])
  const [cancelado, setCancelado] = useState('')
  const [valorCtr, setValorCtr] = useState('')
  const [descontoCtr, setDescontoCtr] = useState('')
  const [jurosCtr, setJurosCtr] = useState('')
  const [multaCtr, setMultaCtr] = useState('')
  const [comboCancelado, setComboCancelado] = useState([{ value: 'S', display: 'Sim' }, { value: 'N', display: 'Não' }])
  const [comboUsu, setComboUsu] = useState([{ value: '', display: '' }])
  const [comboUsuCan, setComboUsuCan] = useState([{ value: '', display: '' }])
  const [itens, setItens] = useState([])
  const [enableEdit, setEnableEdit] = useState('N')
  const [showModalAvulso, setShowModalAvulso] = useState(false)
  const [comboSer, setComboSer] = useState([{ value: '', display: '' }])
  const [comboTco, setComboTco] = useState([{ value: '', display: '' }])
  const [comboEstornado, setComboEstornado] = useState([{ value: 'S', display: 'Sim' }, { value: 'N', display: 'Não' }])
  const [comboEstornadoAvulso, setComboEstornadoAvulso] = useState([{ value: 'S', display: 'Sim' }, { value: 'N', display: 'Não' }])
  const [showModalTemBoleto, setShowModalTemBoleto] = useState(false)
  const columnsShow = [
    { id: 'pk_icb', numeric: false, label: 'Código', align: 'left', minWidth: 60 },
    { id: 'associado', numeric: false, label: 'Associado', align: 'left', minWidth: 300 },
    { id: 'tipo_servico', numeric: false, label: 'Serviço', align: 'left', minWidth: 60, order: 'dt_previsao_recbto' },
    { id: 'tipo_cobranca', numeric: false, label: 'Tipo', align: 'left', minWidth: 100 },
    { id: 'formatdata_emissao', numeric: false, label: 'Dt. Emissão', align: 'left', minWidth: 150 },
    { id: 'formatdata_vencimento', numeric: false, label: 'Dt. Vencimento', align: 'right', minWidth: 100 },
    // { id: 'valor_r', numeric: false, label: 'Valor Realizado', align: 'right', minWidth: 100 },
  ]

  const columns = [
    { id: 'pk_icb', numeric: false, label: 'Código', align: 'left', minWidth: 60 },
    { id: 'associado', numeric: false, label: 'Associado', align: 'left', minWidth: 300 },
    { id: 'formatValor', numeric: false, label: 'Valor', align: 'left', minWidth: 150 },
    { id: 'tipo_servico', numeric: false, label: 'Serviço', align: 'left', minWidth: 60, order: 'dt_previsao_recbto' },
    { id: 'tipo_cobranca', numeric: false, label: 'Tipo', align: 'left', minWidth: 100 },
    // { id: 'formatdata_vencimento', numeric: false, label: 'Dt. Vencimento', align: 'right', minWidth: 100 },
    // { id: 'valor_r', numeric: false, label: 'Valor Realizado', align: 'right', minWidth: 100 },
  ]

  // consts modal:  data_emis data_previsao_vcto fk_ass fk_ser descricao qtdevalor desconto acrescimo estornado fk_tco
  const [data_emis, setData_emis] = useState()
  const [data_previsao_vcto, setData_previsao_vcto] = useState()
  const [fk_ass, setFk_ass] = useState()
  const [fk_ser, setFk_ser] = useState()
  const [descricao, setDescricao] = useState()
  const [qtde, setQtde] = useState(0)
  const [valor, setValor] = useState(0)
  const [desconto, setDesconto] = useState(0)
  const [estornado, setEstornado] = useState()
  const [fk_tco, setFk_tco] = useState()
  const [acrescimo, setAcrescimo] = useState(0)
  const [data_cancelamento, setData_cancelamento] = useState()
  const [forma_pagamento, setForma_pagamento] = useState()
  const [pk_ctr, setPk_ctr] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      
      setComboFpg(await api.getComboData('forma_pagamento'))
      setComboAss(await api.getComboData('associados'))
      setComboUsu(await api.get('usuarios'))
      setComboSer(await api.getComboData('tipo_servico'))
      setComboTco(await api.getComboData('tipo_cobranca'))
      setComboUsuCan(await api.get('usuarios'))

      if (query.codigo > 0) {
        setEnableEdit(await api.getAcessos('Itens de Cobrança', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Itens de Cobrança', 'Inclusao'))
      }


      if (query.codigo > 0) {
        setPk_ctr(query.codigo)
        setUpdateType(true)
        api.get('/contas_receber?pk_ctr=' + query.codigo).then(r => {
          if (r.data[0]) {
            let auxData={...r.data[0]}
            console.log(auxData)
            auxData.valor_juros = mVAL(Number(auxData.valor_juros)*100)
            auxData.valor_desconto = mVAL(Number(auxData.valor_desconto)*100)
            auxData.valor_multa = mVAL(Number(auxData.valor_multa)*100)
            setValorCtr(auxData.valor || 0)
            setDescontoCtr(auxData.valor_desconto|| 0)
            setMultaCtr(auxData.valor_multa|| 0)
            setJurosCtr(auxData.valor_juros|| 0)
            // console.log
            setForma_pagamento(r.data[0].fk_fpg)

            let i = [...itens]
            api.get('/itens_cobranca?fk_ctr='+ query.codigo).then(res =>{
              if(res.data.length > 0){
                setDataCco(res.data)
                console.log(res.data)
                res.data.forEach((item) =>{
                  i.push(item.pk_icb)
                })
                setItens(i)
              }
            })
            if(auxData.data_emissao){
              auxData.data_emissao=auxData.data_emissao.split("T")[0]
            }
            if(auxData.data_vencimento) {
              auxData.data_vencimento=auxData.data_vencimento.split("T")[0]
            }
            if(auxData.data_cancelamento){
              auxData.data_cancelamento=auxData.data_cancelamento.split("T")[0]
            }
            if(auxData.data_liquidacao){
              auxData.data_liquidacao=auxData.data_liquidacao.split("T")[0]
            }
            auxData.pk_ctr = query.codigo
            auxData.valor = mVAL(auxData.valor*100)
            setCancelado(auxData.cancelado)
            setData(auxData)
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'fk_ass',
      'data_emissao',
      'data_vencimento',
      'fk_fpg',
      'valor',
    ]


    let errors = []

    if(data.cancelado=='S'){
      required.push('data_cancelamento')
    }

    required.forEach((curr, index) => {
      let fieldName = curr
      if(fieldName == 'data_emissao') { fieldName= 'Data de Emissão'}
      if(fieldName == 'data_vencimento') { fieldName= 'Data de Vencimento'}
      if(fieldName == 'fk_ass') { fieldName= 'Associado'}
      if(fieldName == 'fk_fpg') { fieldName= 'Forma de Pagamento'}
      if(fieldName == 'valor') { fieldName = 'Valor'}

      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function cancelou(canc){
    let res = false
    if (canc == cancelado){
      res = false
    } else if ( canc != cancelado && canc == 'S'){
      res = true
    }

    return res
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (1==0){
      setShowModalTemBoleto(false)
      swal.fire('Data de cancelamento inválida!', 'Data de cancelamento deve ser informada', 'warning').then((result) => {
        setShowModalTemBoleto(true)
      })
    } else {

      swal.fire({
        title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
        html: `<p style='color:${colors.info900}'>Deseja salvar ${updateType ? 'as alterações' : 'a inclusão'} do registro?</p>`,
        // html: `<p style='color:${colors.info900}'>Deseja salvar ${updateType ? 'as alterações' : 'a inclusão'} do registro?</p>`,
        color: colors.info900,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: colors.success500,
        cancelButtonColor: colors.danger500,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.value) {
          const auxValues = { ...data };
          auxValues.host = window.location.href;
          auxValues.dataIcb=[...itens]
          auxValues.fk_usu = getUserCode()
          auxValues.valor_juros = mVAL(auxValues.valor_juros)
          auxValues.valor_desconto = mVAL(auxValues.valor_desconto)
          auxValues.valor_multa = mVAL(auxValues.valor_multa)
          if(cancelou(auxValues.cancelado)){
            auxValues.fk_usu_cancela = getUserCode()
          }
          let errors = consistData(auxValues)
          console.log(errors.length)
          if (errors.length === 0) {
            if (updateType) {
              api.post('/contas_receber/edit', auxValues).then(r => {
                if (r.status === 200) {
                  if(pk_bol>0){
                    api.post('boletos/cancelarBoleto', { id: pk_bol}).then(res=>{
                      if (res.data == 'cancelado'){
                        swal.fire({
                          title: `<h2 style='color: ${colors.info900}'>Registro Alterado<h2>`,
                          html: `<p style='color: ${colors.info900}'>O registro foi alterado com sucesso!</p>`,
                          icon: 'success',
                        }).then((result) => {
                          if (result.value) {
                            window.history.back()
                          }
                        })
                      } else {
                        swal.fire({
                          title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                          html: `<p style='color: ${colors.info900}'>Erro ao cancelar boleto</p>`,
                          
                          icon: 'warning',
                        })
                      }
                    })

                  }
                } else {
                  swal.fire({
                    title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                    html: `<p style='color: ${colors.info900}'></p>`,
                    
                    icon: 'warning',
                  })
                }
              })
            } else {
              try {
                api.post('/contas_receber/add', auxValues).then(r => {
                  console.log(r.status)
                  if (r.status === 200) {
                    swal.fire({
                      title: 'Registro Adicionado',
                      text: "O registro foi adicionado com sucesso!",
                      icon: 'success',
                    }).then((result) => {
                      if (result.value) {
                        window.history.back()
                      }
                    })
                  } else {
                    swal.fire({
                      title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                      text: "O registro não foi adicionado!",
                      icon: 'warning',
                    })
                  }
                })
              } catch {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                  text: "O registro não foi adicionado!",
                  icon: 'warning',
                })
  
              }
  
            }
          } else {
            swal.fire({
              title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
              html: <p style={{color: colors.info900}}>Verifique {errors.length>1?'os campos': 'o campo'} {errors.join(', ')}</p>,
              icon: 'warning',
            })
          }
        }
      })
    }
  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color: ${colors.info900}'>Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?<p>`,
      icon: 'warning',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
      cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    // auxValues[e.target.name] = e.target.value;
    switch (e.target.name) {
      case 'valor':
        auxValues[e.target.name] = mVAL(e.target.value);
        break
      case 'data_emissao' :
        auxValues[e.target.name] = e.target.value.split("T")[0];
        break
      case 'data_vencimento' :
        auxValues[e.target.name] = e.target.value.split("T")[0];
        break
      case 'data_cancelamento' :
        auxValues[e.target.name] = e.target.value.split("T")[0];
        break
      
      case 'data_liquidacao' :
        auxValues[e.target.name] = e.target.value.split("T")[0];
        break
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  const handleClick = (e) => {
    e.preventDefault()
    setSelected([])

    if (!data.fk_ass)
      swal.fire({
        title: "Erro!",
        text: "Informe o Associado!",
        icon: "warning",
      });

    else {
      let url = "/itens_cobranca?fk_ass="+data.fk_ass+'&fk_ctrnull=1'
      
      api.get(url).then((r) => {
        if (r.data.length > 0) {
          setDataIcb(r.data);

          setShowModal(true);
        } else
          swal.fire({
            title: "Erro!",
            text: "Nenhum contrato encontrado!",
            icon: "warning",
          });
      })
    }
  };

  const handleClickAvulso = (e) => {
    e.preventDefault()
    setShowModalAvulso(true)
    setData_emis()
    setData_previsao_vcto()
    setFk_ser()
    setDescricao()
    setQtde(0)
    setValor(0)
    setDesconto(0)
    setEstornado()
    setFk_tco()
    setAcrescimo(0)
    
  };

  function mostraModalTemBoleto(e) {
    e.preventDefault()
    console.log('oir')
    setShowModalTemBoleto(true)
    setData_cancelamento('')
  }

  function closeModalTemBoleto(e) {
    e.preventDefault()
    setShowModalTemBoleto(false)
  }

  function closeModal(e) {
    e.preventDefault()
    setShowModal(false)
  }
  function closeModalAvulso(e) {
    e.preventDefault()
    setShowModalAvulso(false)
  }

  const adicionaItem = async (param) => {
    console.log(param)
   
    let i = [...itens]
      console.log(itens, 'testando adiciona itens')
      param.forEach(select =>{
        if(itens.indexOf(select) == -1){
          i.push(select)
        }
      })
      setItens(i)  
      console.log("/itens_cobranca?codIcb=" + i)
      api.get("/itens_cobranca?codIcb=" + i).then((r) => {
        if (r.data.length > 0){
          setDataCco(r.data);

        }
          
      })
      return i
  }

  async function confirmaModalTemBoleto(e) {
    e.preventDefault()
    if (selected[0]) {
      console.log(selected)
      setShowModal(false);

      let i = await adicionaItem(selected)

      console.log(itens)
      // alert(selected)
      
    } else {
      setShowModal(false);

      swal.fire({
        title: "Erro!",
        text: "Nenhum contrato selecionado!",
        icon: "warning",
      }).then((result) => {
        if (result.value) {
          setShowModal(true);
        }
      });
    }
  }

  async function confirmaModal(e) {
    e.preventDefault()
    if (selected[0]) {
      console.log(selected)
      setShowModal(false);

      let i = await adicionaItem(selected)

      console.log(itens)
      // alert(selected)
      
    } else {
      setShowModal(false);

      swal.fire({
        title: "Erro!",
        text: "Nenhum contrato selecionado!",
        icon: "warning",
      }).then((result) => {
        if (result.value) {
          setShowModal(true);
        }
      });
    }
  }

  const confirmaModalAvulso = (e) => {
    e.preventDefault()

    if((!data_emis) || (!data_previsao_vcto) || (!qtde) || (!valor) || (!fk_ass) || (!fk_ser) || (!fk_tco)){
      setShowModalAvulso(false)
      swal.fire({
        title: "Erro!",
        text: "Campos obrigatórios: Data Emissão, Data Vencimento, Quantidade, Valor, Associado, Tipo Serviço, Tipo de Cobraça.",
        icon: "warning",
      }).then(r => {
        if (r.value){
          setShowModalAvulso(true)
        } else {
          setShowModalAvulso(true)
        }
      });
    } else {
      api.post('/itens_cobranca/add', {
        data: data_emis,
        data_previsao_vcto: data_previsao_vcto,
        fk_ass: fk_ass,
        fk_ser: fk_ser,
        descricao: descricao,
        qtde: qtde,
        valor: valor,
        desconto: desconto,
        estornado: estornado,
        fk_tco: fk_tco,
        acrescimo: acrescimo,}).then(r=> {
          console.log('postou')
          setShowModalAvulso(false)
          if (r.status==200){
            swal.fire({
              title: 'Registro Adicionado',
              text: "O registro foi adicionado com sucesso!",
              icon: 'success',
            }).then(res => {
              
              setSelected([r.data.insertId])
              console.log(r, 'results')
              adicionaItem([r.data.insertId])
            })
          } else {
            swal.fire({
              title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
              text: "O registro não foi adicionado!",
              icon: 'warning',
            }).then(res => {
              if (res.value) {
                setShowModalAvulso(true)
              } else {
                setShowModalAvulso(true)
              }
            })
          }
        })
    }



    
  }

  const handleConfirma = (e) => {
    e.preventDefault();

    if (data.fk_fpg != forma_pagamento && forma_pagamento == 1){
      api.get('boletos/pegaBoletoAbertoPorCtr?ctr=' + pk_ctr).then(r =>{
        console.log(r.data)
        if (r.data.length > 0 && updateType){
          setPk_bol(r.data[0].pk_bol)
          swal.fire({title: `<h2 style='color:${colors.info900}'>Boleto em Aberto</h2>`,
          html: `<p style='color: ${colors.info900}'>Caso altere a forma de pagamento, o boleto em aberto deve ser cancelado. Confirma?<p>`,
          icon: 'warning',
          color: colors.info900,
          showCancelButton: true,
          confirmButtonColor: colors.success500,
          cancelButtonColor: colors.danger500,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'}).then(r=>{
            if (r.isConfirmed){
              handleSubmit(e)
            }
          })
          // mostraModalTemBoleto(e)
        } else {
          handleSubmit(e)
        }
      })
    } else if( cancelou(data.cancelado)){
      api.get('boletos/pegaBoletoAbertoPorCtr?ctr=' + pk_ctr).then(r =>{
        console.log(r.data)
        if (r.data.length > 0 && updateType){
          setPk_bol(r.data[0].pk_bol)
          swal.fire({title: `<h2 style='color:${colors.info900}'>Boleto em Aberto</h2>`,
          html: `<p style='color: ${colors.info900}'>Caso cancele o registro, o boleto em aberto deve ser cancelado. Confirma?<p>`,
          icon: 'warning',
          color: colors.info900,
          showCancelButton: true,
          confirmButtonColor: colors.success500,
          cancelButtonColor: colors.danger500,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'}).then(r=>{
            if (r.isConfirmed){
              handleSubmit(e)
            }
          })
          // mostraModalTemBoleto(e)
        } else {
          handleSubmit(e)
        }
      })
    } else if (valorCtr != Number(data.valor.replace('.','').replace('.','').replace(',','.')) || multaCtr != data.valor_multa || descontoCtr != data.valor_desconto || jurosCtr != data.valor_juros  ) {
      console.log(valorCtr , Number(data.valor.replace('.','').replace('.','').replace(',','.')) , multaCtr , mVAL(data.valor_multa) , descontoCtr , mVAL(data.valor_desconto) , jurosCtr ,mVAL(data.valor_juros))
      api.get('boletos/pegaBoletoAbertoPorCtr?ctr=' + pk_ctr).then(r =>{
        console.log(r.data)
        if (r.data.length > 0 && updateType){
          setPk_bol(r.data[0].pk_bol)
          swal.fire({title: `<h2 style='color:${colors.info900}'>Boleto em Aberto</h2>`,
          html: `<p style='color: ${colors.info900}'>Caso altere algum valor do registro, o boleto em aberto deve ser cancelado. Confirma?<p>`,
          icon: 'warning',
          color: colors.info900,
          showCancelButton: true,
          confirmButtonColor: colors.success500,
          cancelButtonColor: colors.danger500,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'}).then(r=>{
            if (r.isConfirmed){
              handleSubmit(e)
            }
          })
          // mostraModalTemBoleto(e)
        } else {
          handleSubmit(e)
        }
      })    
    } else {
      handleSubmit(e)
    }
  }

  const handleChangeModalTemBoleto = (e) => {
    e.preventDefault()
    setData_cancelamento(e.target.value.split("T")[0])
  }

  useEffect(()=>{ 
    let auxData = {...data}
    let sum = 0 
    dataCco.forEach(item => {
      console.log(item.valor*100)
      sum = sum+Number(item.valor*100)
    })
    
    auxData.valor = mVAL(sum)

    setData(auxData)
  },[dataCco])

  const handleChangeModal = (e) => {
    e.preventDefault()
    if (e.target.id == 'data_emis') {setData_emis(e.target.value)}
    if (e.target.id == 'data_previsao_vcto') {setData_previsao_vcto(e.target.value)}
    if (e.target.id == 'fk_ass') {setFk_ass(e.target.value)}
    if (e.target.id == 'fk_ser') {setFk_ser(e.target.value)}
    if (e.target.id == 'descricao') {setDescricao(e.target.value)}
    if (e.target.id == 'qtde') {setQtde(e.target.value)}
    if (e.target.id == 'valor') {setValor(mVAL(e.target.value))}
    if (e.target.id == 'desconto') {setDesconto(mVAL(e.target.value))}
    if (e.target.id == 'estornado') {setEstornado((e.target.value))}
    if (e.target.id == 'fk_tco') {setFk_tco(e.target.value)}
    if (e.target.id == 'acrescimo') {setAcrescimo(mVAL(e.target.value))}
    console.log(e.target.id)
  }

  useEffect(()=>{
    setFk_ass(data.fk_ass)
  }, [data.fk_ass])

  return (
    <div className="defaultReg">
      <NavBar />
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Contas a Receber</h3>
          <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
        </div>
        <br />
        <form id="regForm" onSubmit={handleConfirma}>

          <FrSelect
            value={data.fk_ass || ''}
            onChange={handleChange}
            name="fk_ass"
            id="fk_ass"
            label='Associado'
            data={comboAss}
            style={{ width: 250 }}
          />
          <FrTextInput
            maxLength='15'
            value={data.numdoc || ''}
            onChange={handleChange}
            name="numdoc"
            id="numdoc"
            color="#389492"
            label="Nr. Documento"
            variant="outlined"
            size="small"
            required
            style={{ width: 150 }}
          />
          <FrDatePicker
            name="data_emissao"
            id="data_emissao"
            color="#389492"
            label="Data de Emissão"
            variant="outlined"
            size="small"
            style={{ width: 180 }}
            value={(data.data_emissao || '').split("T")[0]}
            onChange={handleChange}
          />

          <FrDatePicker
            name="data_vencimento"
            id="data_vencimento"
            color="#389492"
            label="Data de Vencimento"
            variant="outlined"
            size="small"
            style={{ width: 180 }}
            value={(data.data_vencimento || '').split("T")[0]}
            onChange={handleChange}
          /> 
          <FrSelect
            value={data.fk_fpg || ''}
            onChange={handleChange}
            name="fk_fpg"
            id="fk_fpg"
            label='Forma de Pagamento'
            data={comboFpg}
            style={{ width: 190 }}
          />

          <FrSelect
            value={data.cancelado || ''}
            onChange={handleChange}
            name="cancelado"
            id="cancelado"
            label='Cancelado'
            data={comboCancelado}
            style={{ width: 190 }}
          />

          <br/>
          <FrTextInput
            maxLength='15'
            value={data.valor || ''}
            onChange={handleChange}
            name="valor"
            id="valor"
            color="#389492"
            label="Valor"
            variant="outlined"
            size="small"
            required
            style={{ width: 150 }}
          />

          <FrTextInput
            maxLength='15'
            value={data.valor_desconto? mVAL(data.valor_desconto) : ''}
            onChange={handleChange}
            name="valor_desconto"
            id="valor_desconto"
            color="#389492"
            label="Desconto"
            variant="outlined"
            size="small"
            style={{ width: 150 }}
          />

          <FrTextInput
            maxLength='15'
            value={data.valor_juros? mVAL(data.valor_juros) : ''}
            onChange={handleChange}
            name="valor_juros"
            id="valor_juros"
            color="#389492"
            label="Juros"
            variant="outlined"
            size="small"
            style={{ width: 150 }}
          />

          <FrTextInput
            maxLength='15'
            value={data.valor_multa? mVAL(data.valor_multa) : ''}
            onChange={handleChange}
            name="valor_multa"
            id="valor_multa"
            color="#389492"
            label="Multa"
            variant="outlined"
            size="small"
            style={{ width: 150 }}
          />


          <FrDatePicker
            name="data_liquidacao"
            id="data_liquidacao"
            color="#389492"
            label="Data de Liquidação"
            variant="outlined"
            size="small"
            style={{ width: 180 }}
            value={(data.data_liquidacao || '').split("T")[0]}
            onChange={handleChange}
          />  

         
         
          <br/>
          
          
          
          <FrDatePicker
            name="data_cancelamento"
            id="data_cancelamento"
            color="#389492"
            label="Data de Cancelamento"
            variant="outlined"
            size="small"
            style={{ width: 180 }}
            value={(data.data_cancelamento || '').split("T")[0]}
            onChange={handleChange}
          />  

          {/* <FrSelect
            value={data.fk_usu || ''}
            onChange={handleChange}
            name="fk_usu"
            id="fk_usu"
            label='Usuário'
            data={comboUsu}
            style={{ width: 190 }}
          /> */}

          {/* <FrSelect
            value={data.fk_usu_cancela || ''}
            onChange={handleChange}
            name="fk_usu_cancela"
            id="fk_usu_cancela"
            label='Usuário Resp. Cancelamento'
            data={comboUsuCan}
            style={{ width: 220 }}
          /> */}
          

          <div className="grupoEdits">
          {/* <button onClick={(e) => handleClick(e)}>
              <Icon icon={ic_add_box} size={18} />
              Incluir
            </button> */}
            <button onClick={(e) => handleClickAvulso(e)}>
              <Icon icon={ic_add_box} size={18} />
              Incluir Item Avulso
            </button>
            <div style={{ padding: '20px' }}>
              <FrTableReg
                setDataGrid={setDataCco}
                columns={columns}
                searched={false}
                codeName="pk_icb"
                desvinculaCtr={true}
                setItens={setItens}
                page={""}
                itens={itens}
                data={dataCco}
              />
            </div>


            <div >
              <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={false} maxWidth={'lg'} >

                <div style={{ padding: '20px' }}>
                  <FrTable
                    mostraCheck={'S'}
                    readOnly={true}
                    columns={columnsShow}
                    searched={false}
                    codeName="pk_icb"
                    page={""}
                    data={dataIcb}
                    setSelected={setSelected}
                  />
                </div>

                <div className="FrModalFooter">
                  <div className="FrModalButtonsDiv" >
                    <button style={{ height: '40px' }} onClick={confirmaModal}> Selecionar Itens </button>
                    <button style={{ height: '40px' }} onClick={closeModal}> Sair </button>
                  </div>
                </div>
              </Dialog>
            </div>

            <div>
                <Dialog aria-labelledby="simple-dialog-title" open={showModalAvulso} fullWidth={false} maxWidth={'lg'}>
                  
                  <div className="FrModalFooter">
                    <div className="FrModalButtonsDiv">
                      <MDBContainer className="text-center">

                        <div className="fields">
                        <FrDatePicker
                          name="data_emis"
                          id="data_emis"
                          color="#389492"
                          label="Data de Emissão"
                          variant="outlined"
                          size="small"
                          style={{ width: 180 }}
                          value={(data_emis || '').split("T")[0]}
                          onChange={handleChangeModal}
                        />

                        <FrDatePicker
                          name="data_previsao_vcto"
                          id="data_previsao_vcto"
                          color="#389492"
                          label="Data de Vencimento"
                          variant="outlined"
                          size="small"
                          style={{ width: 180 }}
                          value={(data_previsao_vcto || '').split("T")[0]}
                          onChange={handleChangeModal}
                        />  

                        <FrSelect
                          value={fk_ass || ''}
                          onChange={handleChangeModal}
                          name="fk_ass"
                          id="fk_ass"
                          label='Associado'
                          disabled
                          data={comboAss}
                          style={{ width: 250 }}
                        />
                      
                        <FrSelect
                          value={fk_ser || ''}
                          onChange={handleChangeModal}
                          name="fk_ser"
                          id="fk_ser"
                          label='Tipo de Serviço'
                          data={comboSer}
                          style={{ width: 150 }}
                        />

                        <FrTextInput
                          maxLength='100'
                          value={descricao || ''}
                          onChange={handleChangeModal}
                          name="descricao"
                          id="descricao"
                          color="#389492"
                          label="Descrição"
                          variant="outlined"
                          size="small"
                          required
                          style={{ width: 450 }}
                        />

                        <FrTextInput
                          maxLength='10'
                          value={qtde || ''}
                          onChange={handleChangeModal}
                          name="qtde"
                          id="qtde"
                          color="#389492"
                          label="Quantidade"
                          variant="outlined"
                          size="small"
                          required
                          style={{ width: 150 }}
                        />

                        <FrTextInput
                          maxLength='10'
                          value={valor? mVAL(valor) : ''}
                          onChange={handleChangeModal}
                          name="valor"
                          id="valor"
                          color="#389492"
                          label="Valor"
                          variant="outlined"
                          size="small"
                          required
                          style={{ width: 150 }}
                        />
                        <FrTextInput
                          maxLength='100'
                          value={desconto? mVAL(desconto) : ''}
                          onChange={handleChangeModal}
                          name="desconto"
                          id="desconto"
                          color="#389492"
                          label="Desconto"
                          variant="outlined"
                          size="small"
                          required
                          style={{ width: 150 }}
                        />

                        <FrTextInput
                          maxLength='100'
                          value={acrescimo? mVAL(acrescimo) : ''}
                          onChange={handleChangeModal}
                          name="acrescimo"
                          id="acrescimo"
                          color="#389492"
                          label="Acríscimo"
                          variant="outlined"
                          size="small"
                          required
                          style={{ width: 150 }}
                        />

                        <FrSelect
                          value={estornado || ''}
                          onChange={handleChangeModal}
                          name="estornado"
                          id="estornado"
                          label='Estornado'
                          data={comboEstornadoAvulso}
                          style={{ width: 150 }}
                        />

                        {/* <FrSelect
                          value={data.fk_ctr || ''}
                          onChange={handleChange}
                          name="fk_ctr"
                          id="fk_ctr"
                          label='Conta'
                          data={comboCtr}
                          style={{ width: 150 }}
                        /> */}

                        <FrSelect
                          value={fk_tco || ''}
                          onChange={handleChangeModal}
                          name="fk_tco"
                          id="fk_tco"
                          label='Tipo de Cobrança'
                          data={comboTco}
                          style={{ width: 150 }}
                        />
                        </div>
                      </MDBContainer>
                      <button onClick={confirmaModalAvulso}>Salvar</button>
                      <button onClick={closeModalAvulso}>Cancelar</button>
                    </div>
                  </div>
                </Dialog>
              </div>
            
          </div>
          <div >
            <Dialog aria-labelledby="simple-dialog-title" open={showModalTemBoleto} fullWidth={false} maxWidth={'lg'} >
              <div style={{backgroundColor:'var(--cor-tema)'  }}>
                <div className="defaultHeader" style={{borderRadius:0 }}>

                  <h3>Cancelar Boletos em aberto</h3>
                </div>
              </div>
              <div className="FrModalFooter">
                <div className="FrModalButtonsDiv">
                  <MDBContainer className="text-center">

                    <div className="fields">
                      <FrDatePicker
                        name="data_emis"
                        id="data_emis"
                        color="#389492"
                        label="Data de Cancelamento"
                        variant="outlined"
                        size="small"
                        style={{ width: 180 }}
                        value={(data_cancelamento || '').split("T")[0]}
                        onChange={handleChangeModalTemBoleto}
                      />
                    </div>  
                  </MDBContainer>  
                </div>
              </div>
              

              <div className="FrModalFooter">
                <div className="FrModalButtonsDiv" >
                  <button style={{ height: '40px' }} onClick={handleSubmit}> Confirmar </button>
                  <button style={{ height: '40px' }} onClick={closeModalTemBoleto}> Sair </button>
                </div>
              </div>
            </Dialog>
          </div>

        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleConfirma}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Contas_Receber;
