import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import api from '../services/api';

const swal = withReactContent(Swal)


class ProtectedRoute extends Component {
     constructor(props, context) {
        super(props, context);
        this.state = {
            auth : JSON.parse(localStorage['authcelg'] || JSON.stringify({ authenticated: false, user: 0 }))
        
        };
        // this.verificaHash = this.verificaHash.bind(this);
    }

    // async verificaHash (){
    //     let strAuth = localStorage['authcelg'] || JSON.stringify({ authenticated: false, user: 0 })
    //     let auth = JSON.parse(strAuth)
    //     await api.get('/usuarios/hash?hash='+ strAuth.hash).then( r =>{
    //         // window.alert(JSON.stringify(auth.hash))
            
    //         if (auth.hash == r.data[0].hash) {
    //             // let inactivityTime = Math.abs((new Date(auth.last_activity) - new Date()))
    //             // let inactivityTimeInMinutes = inactivityTime/60000
    //             // console.log(inactivityTimeInMinutes)
    //             // if (inactivityTimeInMinutes >= 60) {
    //                 // localStorage['authcelg'] = JSON.stringify({ authenticated: false, user: 0 })
    //                 // this.setState({ auth: { authenticated: false, user: 0 } })
    //                 // swal.fire({
    //                 //     title: 'Desconectado por inatividade',
    //                 //     text: "Sessão expirada. Por favor, faça o login novamente.",
    //                 //     icon: 'warning',
    //                 //     confirmButtonColor: '#3085d6',
    //                 //     confirmButtonText: 'Ok',
    //                 //     allowOutsideClick: false
    //                 // })
    //             // } else {
    //                 auth.last_activity = new Date()
    //                 localStorage['authcelg'] =  JSON.stringify(auth)
    //             // }
    //         } else {
    //             this.setState({ auth: { authenticated: false, user: 0 } })
    //             localStorage['authcelg'] =  JSON.stringify({ authenticated: false, user: 0 })
    //             window.location.href = '/celg/login'
    //         }
    //     })
        
    // }

    componentWillMount() {      
        let strAuth = localStorage['authcelg'] || JSON.stringify({ authenticated: false, user: 0 })
        let auth = JSON.parse(strAuth) 

        if (auth.last_activity) {
            // let inactivityTime = Math.abs((new Date(auth.last_activity) - new Date()))
            // let inactivityTimeInMinutes = inactivityTime/60000
            // console.log(inactivityTimeInMinutes)
            // if (inactivityTimeInMinutes >= 60) {
                // localStorage['authcelg'] = JSON.stringify({ authenticated: false, user: 0 })
                // this.setState({ auth: { authenticated: false, user: 0 } })
                // swal.fire({
                //     title: 'Desconectado por inatividade',
                //     text: "Sessão expirada. Por favor, faça o login novamente.",
                //     icon: 'warning',
                //     confirmButtonColor: '#3085d6',
                //     confirmButtonText: 'Ok',
                //     allowOutsideClick: false
                // })
            // } else {
                auth.last_activity = new Date()
                localStorage['authcelg'] =  JSON.stringify(auth)
            // }
        } else {
            this.setState({ auth: { authenticated: false, user: 0 } })
            localStorage['authcelg'] =  JSON.stringify({ authenticated: false, user: 0 })
            window.location.href = '/celg/login'
        }
       
    }

    render() {
        const { component: Component, ...props } = this.props
        return (
            
            <Route 
                {...props} 
                render={props => (
                this.state.auth.authenticated ?
                    <Component {...props} {...this.props}/> :
                    <Redirect to='/celg/login' />
                )} 
            />
        )
    }
}

export default ProtectedRoute;