import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import {colors} from '../utils/vars'

import api from '../services/api'
import { paramsToObject } from '../utils/functions'

import './styles/DefaultReg.css';
import FooterDelphus from '../components/FooterDelphus';
import consultaCep from '../services/consultaCep'
import {mCPF, mCNPJ, mCEP, mTel} from '../utils/masks'
import { validarCNPJ, validarCPF } from '../utils/functions';

const swal = withReactContent(Swal)

function Fornecedores(props) {
  const [data, setData] = useState({})
  const [updateType, setUpdateType] = useState(false)
  const [comboCid, setComboCid] = useState([{ value: '', display: '' }])
  const [comboTpf, setComboTpf] = useState([{ value: '', display: '' }])
  const [comboSif, setComboSif] = useState([{ value: '', display: '' }])
  const [comboCla, setComboCla] = useState([{ value: '', display: '' }])
  const [comboPessoa, setComboPessoa] = useState([{ value: 'F', display: 'Pessoa Física' }, { value: 'J', display: 'Pessoa Jurídica' }])
  const [enableEdit, setEnableEdit] = useState('N')
  const cpfCnpj = useRef()

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      let codigo = props.pk_cad ? props.pk_cad : query.codigo
      
      setComboCid(await api.getComboData('cidades'))
      setComboCla(await api.getComboData('classes'))
      setComboTpf(await api.getComboData('tipo_fornecedor'))
      setComboSif(await api.getComboData('situacao_fornecedor'))

      if (codigo > 0) {
        setEnableEdit(await api.getAcessos('Fornecedores', 'Alteracao'))
      } else {
        setEnableEdit(await api.getAcessos('Fornecedores', 'Inclusao'))
      }


      if (codigo > 0) {
        setUpdateType(true)
        api.get('/fornecedores?pk_for=' + codigo).then(r => {
          if (r.data[0]) {
            let auxData={...r.data}
            auxData[0].cnpj? auxData[0].pf_pj='J' : auxData[0].pf_pj='F'
            auxData[0].cpf? auxData[0].pf_pj='F' : auxData[0].pf_pj='J'
            
            setData(auxData[0])
            swal.close()
          } else {
            swal.fire('Código inválido', 'Código informado não encontrado!', 'warning')
              .then((result) => {
                if (result.value) {
                  window.history.back()
                }
              })
          }
        })
      } else swal.close()
    }

    fetchData()
  }, [])

  function consistData(data) {
    let required = [
      'pf_pj',
      'cep',
      'razao_social',
      'endereco',
      'nr_endereco',
      'fk_cid',
      'fk_tpf',
      'fk_cla',
      'fone1',
      'email_contato',
      'email_financeiro',
      'fk_sif',
    ]

    if(data.pf_pj == 'F'){
      required.unshift('cpf')
    } else {
      required.unshift('cnpj')
    }

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if(fieldName == 'fk_cid') { fieldName= 'Cidade'}
      if(fieldName == 'cep') { fieldName= 'CEP'}
      if(fieldName == 'fk_sif') { fieldName= 'Situação'}
      if(fieldName == 'fk_tpf') { fieldName= 'Tipo'}
      if(fieldName == 'fk_cla') { fieldName= 'Classe'}
      if(fieldName == 'pf_pj') { fieldName= 'Pessoa Física/Jurídica'}
      if(fieldName == 'razao_social') { fieldName = 'Razão Social'}
      if(fieldName == 'razao_social' && data.pf_pj == 'F') { fieldName = 'Nome'}
      if(fieldName == 'endereco') { fieldName= 'Endereço'}
      if(fieldName == 'nr_endereco') { fieldName= 'Número'}
      if(fieldName == 'fone1') { fieldName= 'Fone 1'}
      if(fieldName == 'email_contato') { fieldName= 'Email Contato'}
      if(fieldName == 'cpf') { fieldName= 'CPF'}
      if(fieldName == 'cnpj') { fieldName= 'CNPJ'}
      if(fieldName == 'email_financeiro') { fieldName= 'Email Financeiro'}

      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function handleSubmit(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color:${colors.info900}'>Deseja salvar ${updateType ? 'as alterações' : 'a inclusão'} do registro?</p>`,
      // html: `<p style='color:${colors.info900}'>Deseja salvar ${updateType ? 'as alterações' : 'a inclusão'} do registro?</p>`,
      color: colors.info900,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: colors.success500,
      cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        const auxValues = { ...data };
        auxValues.host = window.location.href;
        let errors = consistData(auxValues)
        console.log(errors.length)
        if (errors.length === 0) {
          if (updateType) {
            api.post('/fornecedores/edit', auxValues).then(r => {
              if (r.status === 200) {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Registro Alterado<h2>`,
                  html: `<p style='color: ${colors.info900}'>O registro foi alterado com sucesso!</p>`,
                  icon: 'success',
                }).then((result) => {
                  if (result) {
                    if (props.modal)
                      props.setShowModal(false)

                    else
                      window.history.back()
                  }
                })
              } else {
                swal.fire({
                  title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                  html: `<p style='color: ${colors.info900}'></p>`,
                  
                  icon: 'warning',
                })
              }
            })
          } else {
            try {
              api.post('/fornecedores/add', auxValues).then(r => {
                console.log(r.status)
                if (r.status === 200) {
                  swal.fire({
                    title: 'Registro Adicionado',
                    text: "O registro foi adicionado com sucesso!",
                    icon: 'success',
                  }).then((result) => {
                    if (result) {
                      if (props.modal) {
                        if (props.setShowModal)
                          props.setShowModal(false)
                      } else
                        window.history.back()
                    }
                  })
                } else {
                  swal.fire({
                    title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                    text: "O registro não foi adicionado!",
                    icon: 'warning',
                  })
                }
              })
            } catch {
              swal.fire({
                title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
                text: "O registro não foi adicionado!",
                icon: 'warning',
              })

            }

          }
        } else {
          swal.fire({
            title: `<h2 style='color: ${colors.info900}'>Erro!</h2>`,
            html: <p style={{color: colors.info900}}>Verifique {errors.length>1?'os campos': 'o campo'} {errors.join(', ')}</p>,
            icon: 'warning',
          })
        }
      }
    })
  }

  const handleCep = (e) => {
    e.preventDefault()
    const auxValues = { ...data };
    swal.fire({
      html: <FrLoading text="Carregando..." />,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    })
    
    consultaCep((e.target.value).replace('.','').replace('-','')+'/json').then(r =>{
      if(!r.data.erro){
        auxValues.endereco = r.data.logradouro
        auxValues.complemento = r.data.complemento
        auxValues.bairro = r.data.bairro
        // setData(auxValues)
        console.log(r.data)
        api.get('/cidades?cod_ibge='+r.data.ibge).then(res =>{ 
          if (res.data.length>0){
            console.log(res.data[0])

            auxValues.fk_cid = res.data[0].pk_cid
          }
          setData(auxValues)
          swal.close()
        })
        

      } else{
        swal.close()
        swal.fire('CEP Inválido!', 'CEP Não encontrado nos registros!', 'warning')
      }
    })

    
   

  }

  function handleCancel(e) {
    e.preventDefault();
    swal.fire({
      title: `<h2 style='color:${colors.info900}'>ATENÇÃO</h2>`,
      html: `<p style='color: ${colors.info900}'>Caso cancele a alteração/inclusão, todos os dados digitados serão perdidos. Confirma?<p>`,
      icon: 'warning',
      color: colors.info900,
      showCancelButton: true,
      confirmButtonColor: colors.success500,
      cancelButtonColor: colors.danger500,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result) {
        if (props.modal)
          props.setShowModal(false)
        else
          window.history.back()
      }
    })
  }

  const handleChange = (e) => {
    const auxValues = { ...data };
    // auxValues[e.target.name] = e.target.value;
    switch (e.target.name) {
      default: auxValues[e.target.name] = e.target.value;
    }
    setData(auxValues);
  };

  const validaCpfCnpj = (e) =>{
    e.preventDefault()
    let id=e.target.id
    console.log(cpfCnpj.current)
    if (e.target.name == 'cpf'){
      if(!validarCPF(e.target.value)){
        swal.fire({
          title: `<h2 style='color: ${colors.info900}'>ATENÇÃO!</h2>`,
          html: `<p style='color: ${colors.info900}'>CPF Inválido</p>`,
          icon: 'warning',
        }).then(r=>{
          document.getElementById(id).focus()
        })
      } 
    } else if(e.target.name == 'cnpj'){
      if(!validarCNPJ(e.target.value)){
        swal.fire({
          title: `<h2 style='color: ${colors.info900}'>ATENÇÃO!</h2>`,
          html: `<p style='color: ${colors.info900}'>CNPJ Inválido</p>`,
          icon: 'warning',
        }).then(r=>{
          document.getElementById(id).focus()
        })
      } 
    }
  }


  return (
    <div className="defaultReg">
      {!props.modal ? <NavBar /> : <></>}
      <div className="defaultRegContent">
        <div className="defaultHeader">
          <h3>{updateType ? 'Edição' : 'Inclusão'} de Fornecedor</h3>
          {!props.modal ? <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} /> : <></>}
        </div>
        <br />
        <form id="regForm" onSubmit={handleSubmit}>

          <FrSelect
            value={data.pf_pj || ''}
            onChange={handleChange}
            name="pf_pj"
            id="pf_pj"
            label='Pessoa Física/Jurídica '
            data={comboPessoa}
            style={{ width: 190 }}
          />

          <FrTextInput
            maxLength='100'
            value={data.razao_social || ''}
            onChange={handleChange}
            name="razao_social"
            id="razao_social"
            color="#389492"
            label={data.pf_pj=='F' ? 'Nome' : 'Razão Social'}
            variant="outlined"
            size="small"
            required
            style={{ width: 500 }}
          />

          <FrTextInput
            ref={cpfCnpj}
            maxLength={data.pf_pj=='F' ? '14' : '18'}
            value={(data.pf_pj=='F' ? (data.cpf ? mCPF(data.cpf) :'') : (data.cnpj? mCNPJ(data.cnpj) : '')) || ''}
            onChange={handleChange}
            name={data.pf_pj=='F' ? 'cpf' :"cnpj"}
            id={data.pf_pj=='F' ? 'cpf' :"cnpj"}
            color="#389492"
            label={data.pf_pj=='F' ? 'CPF' : 'CNPJ'}
            variant="outlined"
            size="small"
            onBlur={validaCpfCnpj}
            required
            style={{ width: 220 }}
          />

          <br/>   
          <FrTextInput
            maxLength='10'
            value={data.cep ? mCEP(data.cep) : ''}
            onChange={handleChange}
            name="cep"
            id="cep"
            color="#389492"
            label="CEP"
            variant="outlined"
            size="small"
            onBlur={handleCep}
            required
            style={{ width: 110 }}
          />

          <FrTextInput
            maxLength='100'
            value={data.endereco || ''}
            onChange={handleChange}
            name="endereco"
            id="endereco"
            color="#389492"
            label="Endereço"
            variant="outlined"
            size="small"
            required
            style={{ width: 450 }}
          />

          <FrTextInput
            maxLength='5'
            value={data.nr_endereco || ''}
            onChange={handleChange}
            name="nr_endereco"
            id="nr_endereco"
            color="#389492"
            label="Número"
            variant="outlined"
            size="small"
            required
            style={{ width: 120 }}
          />

          <FrTextInput
            maxLength='45'
            value={data.complemento || ''}
            onChange={handleChange}
            name="complemento"
            id="complemento"
            color="#389492"
            label="Complemento"
            variant="outlined"
            size="small"
            style={{ width: 160 }}
          />  

          <FrSelect
            value={data.fk_cid || ''}
            onChange={handleChange}
            name="fk_cid"
            id="fk_cid"
            label='Cidade'
            data={comboCid}
            style={{ width: 150 }}
          />

          <br/>

          <FrSelect
            value={data.fk_tpf || ''}
            onChange={handleChange}
            name="fk_tpf"
            id="fk_tpf"
            label='Tipo'
            data={comboTpf}
            style={{ width: 250 }}
          />
          <FrSelect
            value={data.fk_cla || ''}
            onChange={handleChange}
            name="fk_cla"
            id="fk_cla"
            label='Classe'
            data={comboCla}
            style={{ width: 150 }}
          />

          <FrTextInput
            maxLength='15'
            value={data.fone1? mTel(data.fone1) : ''}
            onChange={handleChange}
            name="fone1"
            id="fone1"
            color="#389492"
            label="Fone 1"
            variant="outlined"
            size="small"
            required
            style={{ width: 180 }}
          />  

          <FrTextInput
            maxLength='15'
            value={data.fone2? mTel(data.fone2) : ''}
            onChange={handleChange}
            name="fone2"
            id="fone2"
            color="#389492"
            label="Fone 2"
            variant="outlined"
            size="small"
            style={{ width: 180 }}
          />  

          <FrTextInput
            maxLength='15'
            value={data.celular? mTel(data.celular) : ''}
            onChange={handleChange}
            name="celular"
            id="celular"
            color="#389492"
            label="Celular"
            variant="outlined"
            size="small"
            style={{ width: 180 }}
          />  

          <FrTextInput
            maxLength='100'
            value={data.email_contato || ''}
            onChange={handleChange}
            name="email_contato"
            id="email_contato"
            color="#389492"
            label="Email Contato"
            variant="outlined"
            size="small"
            required
            style={{ width: 450 }}
          />  

          <FrTextInput
            maxLength='100'
            value={data.email_financeiro || ''}
            onChange={handleChange}
            name="email_financeiro"
            id="email_financeiro"
            color="#389492"
            label="Email Financeiro"
            variant="outlined"
            size="small"
            required
            style={{ width: 450 }}
          />  

          <FrTextInput
            maxLength='100'
            value={data.url || ''}
            onChange={handleChange}
            name="url"
            id="url"
            color="#389492"
            label="Url"
            variant="outlined"
            size="small"
            style={{ width: 350 }}
          />  

          <FrSelect
            value={data.fk_sif || ''}
            onChange={handleChange}
            name="fk_sif"
            id="fk_sif"
            label='Situação'
            data={comboSif}
            style={{ width: 150 }}
          />
          <br/>
          <FrTextInput
            maxLength='500'
            value={data.observacoes || ''}
            onChange={handleChange}
            name="observacoes"
            id="observacoes"
            color="#389492"
            label="Observações"
            variant="outlined"
            size="small"
            style={{ width: 600 }}
          />  

          


        </form>
        <div className="regButtonsDiv">
          {enableEdit === 'S' ?
            (<button onClick={handleSubmit}>Salvar</button>) : (<></>)}
          <button onClick={handleCancel}>{enableEdit === 'S' ? 'Cancelar' : 'Fechar'}</button>
        </div>
        {!props.modal ? <FooterDelphus /> : <></>}
      </div>
    </div>
  );
}

export default Fornecedores;
