import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Dialog } from '@material-ui/core';

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTableReg from '../components/FrTableReg'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'
import RegFornecedores from './RegFornecedores'


import api from '../services/api'
import {mCPF, mCNPJ} from '../utils/masks'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';
import RegTipoFornecedor from './RegTipo_Fornecedor';

const swal = withReactContent(Swal)

function Fornecedores(props) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')

  const [comboCid, setComboCid] = useState([{ value: '', display: '' }])
  const [comboTpf, setComboTpf] = useState([{ value: '', display: '' }])
  const [comboSif, setComboSif] = useState([{ value: '', display: '' }])
  const [pk_for, setPk_for] = useState(0)
  const [showModal, setShowModal] = useState(false)

  const columns = [
    { id: 'CPF_CNPJ', numeric: false, label: 'CPF/CNPJ', align: 'left', minWidth: 200 },
    { id: 'razao_social', numeric: false, label: 'Nome', align: 'left', minWidth: 200 },
    { id: 'cidade', numeric: false, label: 'Cidade', align: 'left', minWidth: 200 },
    { id: 'tipo_fornecedor', numeric: false, label: 'Tipo', align: 'left', minWidth: 150 },
    { id: 'situacao_fornecedor', numeric: false, label: 'Situação', align: 'left', minWidth: 150 },

  ]

  useEffect(() => {
    const fetchData = async () => {
      if (!props.modal)
      swal.fire({
        
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setComboCid(await api.getComboData('cidades'))
      setComboTpf(await api.getComboData('tipo_fornecedor'))
      setComboSif(await api.getComboData('situacao_fornecedor'))

      setenableAdd(await api.getAcessos('Fornecedores', 'Inclusao'))
      setenableEdit(await api.getAcessos('Fornecedores', 'Alteracao'))
      setenableDel(await api.getAcessos('Fornecedores', 'Exclusao'))


      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      if (!props.modal) swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        getData(event)
      }
    }

    fetchData()
  }, [])

  function mostraModal(e) {
    e.preventDefault();
    setShowModal(true)
  }

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      setFiltered(true)
      if (!props.modal) {
        window.history.replaceState({ filtered: true }, 'filter', "/celg/fornecedores?" + objectToQueryString(queryObject));
        swal.fire({
          
          html: <FrLoading text="Buscando dados..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      }
      api.get('/fornecedores', { params: queryObject }).then(r => {
        setData(r.data)
        console.log(r.data)
        if (!props.modal) swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    if (!props.modal)
      window.history.replaceState({ filtered: false }, 'filter', '/celg/fornecedores');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      {!props.modal ? <NavBar /> : <></>}
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Fornecedores</h3>
          {!props.modal ? <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} /> : <></>}
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
              <FrTextInput
                maxLength='18'
                value={filter.cpf_cnpj? (filter.cpf_cnpj.length>14? mCNPJ(filter.cpf_cnpj) : mCPF(filter.cpf_cnpj) ): ''}
                style={{ width: 330 }}
                name="cpf_cnpj"
                id="cpf_cnpj"
                color="#389492"
                label="CPF/CNPJ"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />
              
              <FrTextInput
                maxLength='18'
                value={filter.razao_social || ''}
                style={{ width: 330 }}
                name="razao_social"
                id="razao_social"
                color="#389492"
                label="Razão Social"
                variant="outlined"
                size="small"
                onChange={handleChange}
              />

              <FrSelect
                value={filter.fk_cid || ''}
                style={{ width: 170 }}
                name="fk_cid"
                id="fk_cid"
                label='Cidade'
                data={comboCid}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.fk_tpf || ''}
                style={{ width: 250 }}
                name="fk_tpf"
                id="fk_tpf"
                label='Tipo'
                data={comboTpf}
                onChange={handleChange}
              />
              
              <FrSelect
                value={filter.fk_sif || ''}
                style={{ width: 170 }}
                name="fk_sif"
                id="fk_sif"
                label='Situação'
                data={comboSif}
                onChange={handleChange}
              />
              

            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' || props.modal ?
                <button onClick={mostraModal}>
                  <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>

                :
                <Link to="/celg/fornecedores/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                    Novo Registro
                  </button>
                </Link>}
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          {props.modal ? <FrTableReg setShowModal={setShowModal} setpk_ass={setPk_for} editar={true} f2={true} forn={props.forn} setForn={props.setForn} regName="fornecedores" columns={columns} searched={filtered} codeName="pk_for" page={'Fornecedores'} data={data} enableEdit={enableEdit} enableDel={enableDel}/> : <FrTable regName="fornecedores" columns={columns} searched={filtered} codeName="pk_for" page={'Fornecedores'} data={data} enableEdit={enableEdit} enableDel={enableDel} /> }
        </div>
        
        <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={true} maxWidth={'lg'}>

          <RegFornecedores pk_for={pk_for} editar={true} setShowModal={setShowModal} modal={true}></RegFornecedores>
        </Dialog>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Fornecedores;
