
import {useState} from 'react'
import { ic_chevron_right } from 'react-icons-kit/md/ic_chevron_right'
import Tooltip from '@material-ui/core/Tooltip';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import './index.css';
import { Icon } from 'react-icons-kit'
import {colors} from '../../utils/vars.js'
import api from '../../services/api'
import FrSelect from '../FrSelect'
import FrLoading from '../FrLoading'
import { getUserCode } from '../../utils/functions';

const swal = withReactContent(Swal)

export function GeracaoAnuidades(props){
  
  const [ano, setAno] = useState()
  const [comboAno, setComboAno] = useState([{ value: '2023', display: '2023' },
  { value: '2024', display: '2024' },
  { value: '2025', display: '2025' },
  { value: '2026', display: '2026' },
  { value: '2027', display: '2027' },
  { value: '2028', display: '2028' },
  { value: '2029', display: '2029' },
  { value: '2030', display: '2030' },
  { value: '2031', display: '2031' },
  { value: '2032', display: '2032' }, 
 ])
  
  function modalGeracaoAnuidade (){
    return <div>
      <p style={{color: colors.info900}}>Selecione o ano para geração:</p>
      <br/> 
      {/* <FrSelect
        value={ano || ''}
        style={{ width: 140 }}
        name="ano"
        id="ano"
        label='Ano'
        data={comboAno}
        onChange={handleChange}
      /> */}
    </div>
  }

  const handleChange = (e) => {
    
    switch (e.target.name) {
      case 'ano':
        setAno(e.target.value);
        break;
      
    }
  };
    const geraAnuidades = (e) => {
        e.preventDefault()
        swal.fire({
            title: `<h2 style='color:${colors.info900}'>Geração de Anuidades</h2>`,
            html: modalGeracaoAnuidade(),
            icon: 'question',
            color: colors.info900,
            showCancelButton: true,
            confirmButtonColor: colors.success500,
            cancelButtonColor: colors.danger500,
            input: 'select',
            inputOptions: { 2023: '2023' ,
              2024 : '2024' ,
              2025 : '2025' ,
              2026 : '2026' ,
              2027 : '2027' ,
              2028 : '2028' ,
              2029 : '2029' ,
              2030 : '2030' ,
              2031 : '2031' ,
              2032 : '2032' ,},
            inputPlaceholder: 'Ano',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then((result) => {
            if (result.isConfirmed) {
                swal.fire({
                    html: <FrLoading text="Carregando..." />,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  })
                  console.log(result)
              api.post('/associado_plano/geraAnuidade', {ano: result.value, fk_usu : getUserCode()}).then(r=> {
                if(r.data == 'pronto'){
                    swal.fire({
                        title: `<h2 style='color:${colors.info900}'>Registros Adicionados</h2>`,
                        html: `<p style='color: ${colors.info900}'>Os registros foram adicionados com sucesso!</p>`,
                        icon: 'success',
                      })
                }else {
                    swal.fire({
                        title: `<h2 style='color:${colors.info900}'>Erro!</h2>`,
                        html:  `<p style='color: ${colors.info900}'>Houve algum problema no processo!</p>
                                <p style='color: ${colors.info900}'>Verifique o banco de dados e tente novamente.</p>`,
                        icon: 'warning',
                      })
                }

              })
            }
          })
    }

    return <>
        <Tooltip title='Geração de Anuidades' > 
            <div onClick={geraAnuidades} className={(props.hide ? 'hide-' : '') + "toggleMenuItem"} >
                <Icon icon={ic_chevron_right} size={15} />
                {props.hide ? '' : <strong style={{ paddingLeft: '5px' }}>Gerar Anuidades</strong>}
            </div>
            </Tooltip>
    
    </>
}


