import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrSelect from '../components/FrSelect'
import FrDatePicker from '../components/FrDatePicker'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import {mCPF, mCNPJ} from '../utils/masks'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function Itens_Cobranca(props) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')

  const [comboSer, setComboSer] = useState([{ value: '', display: '' }])
  const [comboAss, setComboAss] = useState([{ value: '', display: '' }])
  const [comboTco, setComboTco] = useState([{ value: '', display: '' }])
  const [comboSit, setComboSit] = useState([{ value: 'P', display: 'Pendente' }, { value: 'C', display: 'Cobrado' }, { value: 'E', display: 'Estornado' }])

  const columns = [
    { id: 'associado', numeric: false, label: 'Associado', align: 'left', minWidth: 200 },
    { id: 'tipo_servico', numeric: false, label: 'Serviço', align: 'left', minWidth: 200 },
    { id: 'tipo_cobranca', numeric: false, label: 'Tipo', align: 'left', minWidth: 200 },
    { id: 'formatdata_emissao', numeric: false, label: 'Data Emissão', align: 'left', minWidth: 100, order: 'data'},
    { id: 'formatdata_vencimento', numeric: false, label: 'Data Vencimento', align: 'left', minWidth: 100, order: 'data_previsao_vcto' },
    { id: 'formatValor', numeric: false, label: 'Valor', align: 'left', minWidth: 150, },
  ]

  useEffect(() => {
    const fetchData = async () => {
      swal.fire({
        html: <FrLoading text="Carregando..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      setComboSer(await api.getComboData('tipo_servico'))
      setComboTco(await api.getComboData('tipo_cobranca'))
      setComboAss(await api.getComboData('associados'))

      setenableAdd(await api.getAcessos('Itens de Cobrança', 'Inclusao'))
      setenableEdit(await api.getAcessos('Itens de Cobrança', 'Alteracao'))
      setenableDel(await api.getAcessos('Itens de Cobrança', 'Exclusao'))


      const urlParams = new URLSearchParams(window.location.search);
      let query = paramsToObject(urlParams)
      setFilter(query)
      populateForm(document.getElementById('searchForm'), query)

      swal.close()
      if (query.buscar === 'true') {
        let event = new Event('build');
        getData(event)
      }
    }

    fetchData()
  }, [])

  function getData(e) {
    e.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    // if (!isEmpty(queryObject)) {
    if (true) {
      queryObject.buscar = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/celg/itens_cobranca?" + objectToQueryString(queryObject));
      swal.fire({
        html: <FrLoading text="Buscando dados..." />,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      api.get('/itens_cobranca', { params: queryObject }).then(r => {
        setData(r.data)
        console.log(r.data)
        swal.close()
      })
    } else {
      swal.fire('Nenhum filtro informado!', 'Informe ao menos um filtro para realizar a busca.', 'warning')
    }
  }

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/celg/itens_cobranca');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    auxValues[e.target.name] = e.target.value;
    setFilter(auxValues);
  };

  return (
    <div className="Manut">
      <NavBar />
      <div className="ManutContent">
        <div className="defaultHeader">
          <h3>Itens de Cobrança</h3>
          <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
        </div>
        <br />
        <div className="filtersDiv">
          <form id="searchForm" onSubmit={getData}>
            <div>
              <>
                <FrDatePicker
                  value={(filter.data_emis_ini || '').split('T')[0]}
                  name="data_emis_ini"
                  id="data_emis_ini"
                  color="#528b46"
                  label="Período de Emissão"
                  variant="outlined"
                  size="small"
                  style={{ width: (190), flex: ('') }}
                  onChange={handleChange}
                />

                <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                <FrDatePicker
                  value={(filter.data_emis_fim || '').split('T')[0]}
                  name="data_emis_fim"
                  id="data_emis_fim"
                  color="#528b46"
                  variant="outlined"
                  size="small"
                  style={{ width: 190, flex:  '' }}
                  onChange={handleChange}

                />
              </>

              <>
                <FrDatePicker
                  value={(filter.data_venc_ini || '').split('T')[0]}
                  name="data_venc_ini"
                  id="data_venc_ini"
                  color="#528b46"
                  label="Período de Vencimento"
                  variant="outlined"
                  size="small"
                  style={{ width: (190), flex: ( '') }}
                  onChange={handleChange}
                />

                <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                <FrDatePicker
                  value={(filter.data_venc_fim || '').split('T')[0]}
                  name="data_venc_fim"
                  id="data_venc_fim"
                  color="#528b46"
                  variant="outlined"
                  size="small"
                  style={{ width: ( 190), flex: ('') }}
                  onChange={handleChange}

                />
              </>
              <br/>
              
              <FrSelect
                value={filter.fk_ass || ''}
                style={{ width: 320 }}
                name="fk_ass"
                id="fk_ass"
                label='Associado'
                data={comboAss}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.fk_ser || ''}
                style={{ width: 170 }}
                name="fk_ser"
                id="fk_ser"
                label='Tipo de Serviço'
                data={comboSer}
                onChange={handleChange}
              />

              <FrSelect
                value={filter.fk_tco || ''}
                style={{ width: 170 }}
                name="fk_tco"
                id="fk_tco"
                label='Tipo de Cobrança'
                data={comboTco}
                onChange={handleChange}
              />
              <FrSelect
                value={filter.situacao || ''}
                style={{ width: 170 }}
                name="situacao"
                id="situacao"
                label='Situação'
                data={comboSit}
                onChange={handleChange}
              />
              

            </div>
            <div>
              <button onClick={getData}>
                <Icon icon={ic_search} size={18} />
                Pesquisar
              </button>
              <button onClick={clearForm}>
                <Icon icon={ic_delete} size={18} />
                Limpar
              </button>
              {enableAdd !== 'S' ? '' :
                <Link to="/celg/itens_cobranca/registro">
                  <button >
                    <Icon icon={ic_add_box} size={18} />
                  Novo Registro
                </button>
                </Link>}
            </div>
          </form>
        </div>
        <br />
        <div className="tableDiv">
          <FrTable regName="itens_cobranca" columns={columns} searched={filtered} codeName="pk_icb" page={'Itens_Cobranca'} data={data} enableEdit={enableEdit} enableDel={enableDel} />
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Itens_Cobranca;
