import React, { useEffect, useState } from 'react';
import ReactExport from "react-data-export";
import { Link } from 'react-router-dom'
import { Icon } from 'react-icons-kit'
import { ic_add_box } from 'react-icons-kit/md/ic_add_box'
import { ic_search } from 'react-icons-kit/md/ic_search'
import { filePdf } from 'react-icons-kit/icomoon/filePdf'
import { ic_delete } from 'react-icons-kit/md/ic_delete'
import { fileExcel } from 'react-icons-kit/icomoon/fileExcel'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { MDBContainer, MDBIframe } from "mdbreact";
import Dialog from '@material-ui/core/Dialog';

import NavBar from '../components/NavBar'
import FrTable from '../components/FrTable'
import FrTextInput from '../components/FrTextInput'
import FrDatePicker from '../components/FrDatePicker'
import UserOptions from '../components/UserOptions'
import FrLoading from '../components/FrLoading'
import FrSelect from '../components/FrSelect'
import FooterDelphus from '../components/FooterDelphus'


import api from '../services/api'
import { paramsToObject, formToObject, populateForm, objectToQueryString } from '../utils/functions'

import './styles/DefaultMainScreen.css';
import './styles/Manut.css';

const swal = withReactContent(Swal)

function RelContas_Receber(props) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({})
  const [filtered, setFiltered] = useState(false)
  const [enableAdd, setenableAdd] = useState('N')
  const [enableEdit, setenableEdit] = useState('N')
  const [enableDel, setenableDel] = useState('N')
  // const auth = (JSON.parse(localStorage['authprimavera']));
  const [arquivo, setArquivo] = useState('')
  const [comboPac, setComboPac] = useState([{ value: '', display: '' }])
  const [comboSituacao, setComboSituacao] = useState([{ value: '', display: '' }])
  const [comboFpg, setComboFpg] = useState([{ value: '', display: '' }])
  const [mostraArquivo, setMostraArquivo] = useState(false)
  const [showModal, setshowModal] = React.useState(false);
  const [dataPlanilha, setDataPlanilha] = useState([])  
  const [dataCancelados, setDataCancelados] = useState([])  
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const columns = [
    { id: 'titulo', numeric: false, label: 'Título', align: 'left', minWidth: 60 },
    { id: 'format_data_emissao', numeric: false, label: 'Data Emissão', align: 'left', minWidth: 150 },
    { id: 'nome', numeric: false, label: 'Associado', align: 'left', minWidth: 300 },
    { id: 'format_data_vencimento', numeric: false, label: 'Data Vencimento', align: 'left', minWidth: 150 },
    { id: 'valor', numeric: false, label: 'Valor', align: 'left', minWidth: 80 },
    { id: 'liquidado', numeric: false, label: 'Liquidado', align: 'left', minWidth: 80 },
    { id: 'saldo', numeric: false, label: 'Saldo', align: 'left', minWidth: 80 },
    { id: 'situacao', numeric: false, label: 'Situação', align: 'left', minWidth: 120 },

  ]

  useEffect(() => {
    const fetchData = async () => {

      let tela = 'Relatório Contas Receber'
      const consulta = await api.getAcessos(tela, 'Consulta')

      if (consulta !== 'S') {
        swal.fire('Usuário sem acesso', 'Usuário sem acesso a essa tela!', 'warning')
          .then((result) => {
            if (result.value) {
              window.history.back()
            }
          })
      } else {

        swal.fire({
          html: <FrLoading text="Carregando..." />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })

        setenableAdd(await api.getAcessos(tela, 'Inclusao'))
        setenableEdit(await api.getAcessos(tela, 'Alteracao'))
        setenableDel(await api.getAcessos(tela, 'Exclusao'))
        setComboPac(await api.getComboData('associados'))
        setComboSituacao([
          {
            value: "P",
            display: "Pendentes",
          },
          {
            value: "L",
            display: "Liquidados",
          },
          ])
        setComboFpg(await api.getComboData('forma_pagamento'))

        const urlParams = new URLSearchParams(window.location.search);
        let query = paramsToObject(urlParams)
        setFilter(query)
        populateForm(document.getElementById('searchForm'), query)

        swal.close()
        if (query.buscar === 'true') {
          let event = new Event('build');
          getData(event)
        }
      }
    }
    fetchData()
  }, [])

  function getData(e) {
    e?.preventDefault()
    var formData = new FormData(document.getElementById('searchForm'))
    var queryObject = formToObject(formData)
    const auxValues = {... filter}
    // if (!isEmpty(queryObject)) {
    var errors = consistData(auxValues)
    //console.log(filter.dataini_vencimento != null || filter.datafim_vencimento != null || filter.dataini_liquidacao != null || filter.datafim_liquidacao != null)
    // if (filter.dataini_vencimento != null || filter.datafim_vencimento != null || filter.dataini_liquidacao != null || filter.datafim_liquidacao != null) {
      if  (1==1){
      // if(1==1){
      queryObject.buscar = true
      setFiltered(true)
      window.history.replaceState({ filtered: true }, 'filter', "/celg/rel_contas_receber?" + objectToQueryString(queryObject));
      // swal.fire({
      //   html: <FrLoading text="Buscando dados..." />,
      //   showConfirmButton: false,
      //   allowOutsideClick: false,
      //   allowEscapeKey: false
      // })
      api.get('/relcontas_receber', { params: queryObject }).then(r => {
        setData(r.data)
        setDataPlanilha(r.data.filter((i)=> {return i.cancelado != 'S' && i.situacao == 'P'} ))
        setDataCancelados(r.data.filter((i) => {return i.cancelado == 'S'}))
        if(r.data.length == 0){
          // swal.fire('Nenhum Registro Encontrado!', 'Não foi encontrado registro com esses parâmetros', 'warning')
        } else {
          swal.close()

        }
      })
    } else {
      swal.fire({
        title: 'Erro!',
        text: "Informe ao menos uma data de vencimento ou liquidação.",
        icon: 'warning',
      })
    }
  }

  useEffect(() =>{
    getData(null)
  },[filter])

  async function imprimeDataAssociado(e){
    e.preventDefault()
    const auxValues = {... filter}
    var errors = consistData(auxValues)
    // if (errors.length === 0) {
      if(1==1){
      setMostraArquivo(false)
      var formData = new FormData(document.getElementById('searchForm'))
      var queryObject = formToObject(formData)
      console.log(queryObject)
      let situacao = queryObject.situacao ? '&situacao=' + queryObject.situacao : ''
      let dataIniVcto = queryObject.dataini_vencimento ? '&dataIni=' + queryObject.dataini_vencimento : ''
      let dataFimVcto = queryObject.datafim_vencimento ? '&dataFim=' + queryObject.datafim_vencimento : ''
      let fk_ass = queryObject.fk_ass ? '&fk_ass=' + queryObject.fk_ass : ''
      let fk_fpg = queryObject.fk_fpg ? '&fk_fpg=' + queryObject.fk_fpg : ''

      setArquivo(api.backendUrl + '/api/relcontas_receber/relatorio_associado?1=1'  + situacao + dataIniVcto + dataFimVcto + fk_ass  + fk_fpg)

      setshowModal(true)
    } else {
      swal.fire({
        title: 'Erro!',
        text: "Verifique o(s) campo(s) " + errors.join(','),
        icon: 'warning',
      })
    }
  }

  async function imprimeData(e) {
    e.preventDefault()
    const auxValues = {... filter}
    var errors = ''
    // var errors = consistData(auxValues)
    // if (errors.length === 0) {
      if (1==1){
      setMostraArquivo(false)
      var formData = new FormData(document.getElementById('searchForm'))
      var queryObject = formToObject(formData)
      let situacao = queryObject.situacao ? '&situacao=' + queryObject.situacao : ''
      let dataIniVcto = queryObject.dataini_vencimento ? '&dataIni=' + queryObject.dataini_vencimento : ''
      let dataFimVcto = queryObject.datafim_vencimento ? '&dataFim=' + queryObject.datafim_vencimento : ''
      let fk_ass = queryObject.fk_ass ? '&fk_ass=' + queryObject.fk_ass : ''
      let fk_fpg = queryObject.fk_fpg ? '&fk_fpg=' + queryObject.fk_fpg : ''

      console.log(api.backendUrl + '/api/relcontas_receber/relatorio_mensal?1=1'  + situacao + dataIniVcto + dataFimVcto + fk_ass + fk_fpg)
      setArquivo(api.backendUrl + '/api/relcontas_receber/relatorio_mensal?1=1'  + situacao + dataIniVcto + dataFimVcto + fk_ass + fk_fpg)

      setshowModal(true)
    } else {
      swal.fire({
        title: 'Erro!',
        text: "Verifique o(s) campo(s) " + errors.join(','),
        icon: 'warning',
      })
    }


  }

  function consistData(data) {
    let required = [
      'datafim_vencimento',
      'dataini_vencimento',
    ]

    let errors = []

    required.forEach((curr, index) => {
      let fieldName = curr
      if (curr === 'datafim_vencimento') fieldName = 'Período de vencimento'
      if (curr === 'dataini_vencimento') fieldName = 'Período de vencimento'


      if (!data[curr]) return errors.push(fieldName)
    })

    return errors
  }

  function escondeModal(e) {
    e.preventDefault()
    setshowModal(false)
}

  function clearForm(e) {
    e.preventDefault();
    console.log('limpa');
    window.history.replaceState({ filtered: false }, 'filter', '/primavera/relcontas_receber');
    document.getElementById('searchForm').reset()
    setFilter({})
  }

  const handleChange = (e) => {
    const auxValues = { ...filter };
    console.log(auxValues)
    switch (e.target.name){
    default: auxValues[e.target.name] = e.target.value;
  }
    setFilter(auxValues);
  };

  return (
    <div>
      <div>
        <Dialog aria-labelledby="simple-dialog-title" open={showModal} fullWidth={true} maxWidth={'lg'}>
          <div className="FrModalFooter">
            <div className="FrModalButtonsDiv">
              <MDBContainer className="text-center">
                <MDBIframe src={arquivo} />
              </MDBContainer>
              <button onClick={escondeModal}> Sair </button>
            </div>
          </div>
        </Dialog>
      </div>
      <div className="Manut">
        <NavBar />
        <div className="ManutContent">
          <div className="defaultHeader">
            <h3>Relatório Contas Receber</h3>
            <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
          </div>
          <br />
          <div className="filtersDiv">
            <form id="searchForm" onSubmit={getData}>
              <div>
                <FrSelect
                  value={filter.fk_ass || ''}
                  onChange={handleChange}
                  name="fk_ass"
                  id="fk_ass"
                  label="Associado"
                  data={comboPac}
                  style={{ width: 450 }}
                  // required
                />

                <FrDatePicker
                  value={(filter.dataini_vencimento || '').split('T')[0]}
                  name="dataini_vencimento"
                  id="dataini_vencimento"
                  color="#003459"
                  label="Período de Vencimento"
                  variant="outlined"
                  size="small"
                  style={{ width: 190, flex: ('') }}
                  onChange={handleChange}
                />

                <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                <FrDatePicker
                  value={(filter.datafim_vencimento || '').split('T')[0]}
                  name="datafim_vencimento"
                  id="datafim_vencimento"
                  color="#003459"
                  variant="outlined"
                  size="small"
                  style={{ width: (190), flex: ('') }}
                  onChange={handleChange}
                />

                <br/>
                
                <FrDatePicker
                  value={(filter.dataini_liquidacao || '').split('T')[0]}
                  name="dataini_liquidacao"
                  id="dataini_liquidacao"
                  label="Período de Liquidação"
                  color="#528b46"
                  variant="outlined"
                  size="small"
                  style={{ width: 190 }}
                  onChange={handleChange}
                />

                <label style={{ paddingRight: '7px', paddingTop: '7px' }}>a</label>

                <FrDatePicker
                  value={(filter.datafim_liquidacao || '').split('T')[0]}
                  onChange={handleChange}
                  name="datafim_liquidacao"
                  id="datafim_liquidacao"
                  color="#528b46"
                  variant="outlined"
                  size="small"
                  style={{ width: 190 }}
                />

                <FrSelect
                  value={filter.situacao || ''}
                  name="situacao"
                  id="situacao"
                  label='Situação'
                  data={comboSituacao}
                  style={{ width: 150, flex:  '' }}
                  onChange={handleChange}
                />              

                <FrSelect
                  value={filter.fk_fpg || ''}
                  name="fk_fpg"
                  id="fk_fpg"
                  label='Forma de Pagamento'
                  data={comboFpg}
                  style={{ width: 200, flex:  '' }}
                  onChange={handleChange}
                />

              </div>
              <div>
                {/* <button onClick={getData}>
                  <Icon icon={ic_search} size={18} />
                  Pesquisar
                </button> */}
                <button onClick={clearForm}>
                  <Icon icon={ic_delete} size={18} />
                  Limpar
                </button> 
                  <ExcelFile element={<button onClick={console.log(data)}>
                    <Icon icon={fileExcel} size={18} />
                    Exportar</button>} filename="Relatório Contas Receber">
                    <ExcelSheet data={data} name="Relatório Contas Receber">
                      <ExcelColumn label="Data Emissão" value="format_data_emissao" />
                      <ExcelColumn label="Associado" value="nome" />
                      <ExcelColumn label="Data Vencimento" value="format_data_vencimento" />
                      <ExcelColumn label="Valor" value="valor" />
                      <ExcelColumn label="Liquidado" value="liquidado" />
                      <ExcelColumn label="Saldo" value="saldo" />
                      <ExcelColumn label="Situação" value="situacao" />
                    </ExcelSheet>
                  </ExcelFile>
                  <ExcelFile element={<button>
                <Icon icon={fileExcel} size={18} />
                Exportar Cancelados</button>}>
                <ExcelSheet data={dataCancelados} name="Contas Receber">
                  <ExcelColumn label="Número Doc." value="numdoc" />
                  <ExcelColumn label="Dt. Emissão" value="formatdata_emissao" />
                  <ExcelColumn label="Associado" value="associado" />
                  <ExcelColumn label="Forma de Pagamento" value="formapgto" />
                  <ExcelColumn label="Dt. Vencimento" value="formatdata_vencimento" />
                  <ExcelColumn label="Valor" value="formatValor" />
                  <ExcelColumn label="Dt. Liquidação" value="formatdata_liquidacao" />
                  <ExcelColumn label="Valor Liquidado" value="formatValor_liquidado" />
                  <ExcelColumn label="ID Banco" value="id_banco" />
                  <ExcelColumn label="Conta" value="conta" />
                </ExcelSheet>
              </ExcelFile>  
                <button onClick={imprimeData} style={{width: 190}}>
                  <Icon icon={filePdf} size={18} />
                  Relatório por Vencimento
                </button>
                <button onClick={imprimeDataAssociado} style={{width: 190}}>
                  <Icon icon={filePdf} size={18} />
                  Relatório por Associado
                </button>
              </div>
            </form>
          </div>
          <br />
          <div className="tableDiv">
            {/* <FrTable regName="relcontas_receber" columns={columns} searched={filtered} readOnly={true} codeName="pk_ctr" page={'RelContas_Receber'} data={data} enableEdit={enableEdit} enableDel={enableDel} /> */}
          </div>
          <FooterDelphus />
        </div>
      </div>
    </div>  
  );
}

export default RelContas_Receber;
