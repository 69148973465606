import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav'
import NavBar from '../components/NavBar'
import { Icon } from 'react-icons-kit'
import { iosPeople } from 'react-icons-kit/ionicons/iosPeople'
import { iosPerson } from 'react-icons-kit/ionicons/iosPerson'
import { ic_work } from 'react-icons-kit/md/ic_work'
import {documentInverted} from 'react-icons-kit/entypo/documentInverted'
import { ic_launch } from 'react-icons-kit/md/ic_launch'
import Button from '@material-ui/core/Button';
import {ic_assignment_ind} from 'react-icons-kit/md/ic_assignment_ind';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { coinDollar } from 'react-icons-kit/icomoon/coinDollar'
import { calculator } from 'react-icons-kit/icomoon/calculator'
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Tooltip from '@material-ui/core/Tooltip';
import UserOptions from '../components/UserOptions'
import './styles/Home.css';
import FooterDelphus from '../components/FooterDelphus';
import api from '../services/api'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Home(props) {
  const [auth, setAuth] = useState(JSON.parse(localStorage['authcelg'] || JSON.stringify({ authenticated: false, user: 0 })));
  const [atalhos, setAtalhos] = useState([
    {
      label: 'Associados',
      icon: iosPeople,
      path: '/associados'
    },
    {
      label: 'Fornecedores',
      icon: iosPerson,
      path: '/fornecedores'
    },
    {
      label: 'Termos de Associação',
      icon: documentInverted,
      path: '/associado_plano'
    },
    {
      label: 'Itens de Cobrança',
      icon: ic_work,
      path: '/itens_cobranca'
    },
    { label: 'Contas a Pagar',
      icon: calculator,
      path:'/contas_pagar'  
    },
    { label: 'Contas a Receber',
      icon: coinDollar,
      path:'/contas_receber'  
    },
    { label: 'Matrículas',
      icon: ic_assignment_ind,
      path:'/associado_curso'  
    }
   
  ])

  

 

  const controles = (item, index) => {
    return (
      <Nav.Link href={"/tarento/" + String(item.origem).toLowerCase() + '/registro?codigo=' + Number(item.cod_origem)} target="_blank" className="atalhoControle">
        <Icon icon={ic_launch} size={20} />
        <strong>{'[' + item.origem + '] ' + item.modelo + ' - ' + item.tipo + ' (Prazo Limite: ' + item.prazo_limite_formatado + ')'}</strong>
      </Nav.Link>
    )
  }

  return (
    <div className="Home">
      {/* {console.log(props)} */}
      <NavBar />
      <div className="HomeContent">
        <div className="defaultHeader">
          <h3>Seja bem vindo(a), <strong>{auth.name}.</strong>Seu último login foi em {auth.ultimo_login}.</h3>
          <UserOptions temaEscuro={props.temaEscuro} setTemaEscuro={props.setTemaEscuro} />
        </div>
        <br />
        <div className="atalhos">

          {/*////////////////////////// ADICIONAR OBJETOS NA CONST 'ATALHOS' SEGUINDO PADRÃO */}
          
          {atalhos.map(atalho => {
            return (
            <Tooltip title="Segure Ctrl para abrir em nova aba">
              <Nav.Link href={"/celg"+atalho.path} className="atalho">
                <div className='iconeAtalho'>
                  <Icon icon={atalho.icon} size={atalho.size || 40} />
                  <strong>{atalho.label}</strong>

                </div>
              </Nav.Link>
            </Tooltip>)
          })}
          
          
        </div>
        <FooterDelphus />
      </div>
    </div>
  );
}

export default Home;
