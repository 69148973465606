import React, { useState, useEffect, Component } from 'react';
import './index.css';
import FrTextInput from '../FrTextInput'
import { Icon } from 'react-icons-kit'
import { ic_search } from 'react-icons-kit/md/ic_search'
import {ic_done} from 'react-icons-kit/md/ic_done'
import {ic_close} from 'react-icons-kit/md/ic_close'
import Dialog from '@material-ui/core/Dialog';
import Associado_Curso from '../../pages/Associado_Curso';
import Associados from '../../pages/Associados';
import Fornecedores from '../../pages/Fornecedores';


function FrameF2(props) {
    const [showModal, setShowModal] = useState(props.open);

    const [name, setName] = useState(props.name)
    const [codeName, setCodeName] = useState(props.codeName)
    const [ass, setAss]= useState(0)
    const [forn, setForn]= useState(0)

    const [tipoModal, setTipoModal] = useState(props.tipoModal);
    const [text, setText] = useState(null)
    // const [codeValue, setCodeValue] = useState(props.codeValue)
    // const [handleChangeCode, setHandleChangeCode] = useState(props.handleChangeCode)

    function buildComponent() {
        return React.createElement(props.tela, {frame: true})
    }

    useEffect(() => {
        const fetchCad = async () => {
            if (ass > 0) {
                props.setAss(ass)
                setShowModal(false)
            }
        }
        fetchCad()
    }, [ass])

    useEffect(() => {
        const fetchFor = async () => {
            if (forn > 0) {
                props.setForn(forn)
                setShowModal(false)
            }
        }
        fetchFor()
    }, [forn])


    function mostraModal(e) {
        e.preventDefault()
        setShowModal(true)
    }


    function abreF2(e) {
        e.preventDefault()
        if (e.keyCode === 113) {
            setShowModal(true)
        }
    }


    function abreF2Codigo(e) {
        if (e.keyCode === 113) {
            e.preventDefault()
            setShowModal(true)
        }
    }

    function procuraCodigo(e) {
        e.preventDefault()
        setText('Teste')
    }

    const handleChange = (e) => {
        const auxValues = text;
        console.log(auxValues)
        auxValues = e.target.value;
        setText(auxValues);
    };

    function escondeModal(e) {
        e.preventDefault()
        setShowModal(false)
    }

    console.log(tipoModal)
    return (
        <div >
            <Dialog  aria-labelledby="simple-dialog-title" open={showModal} fullWidth={true} maxWidth={'lg'}>
                <div className='manutFrameContent'>
                    {(tipoModal === 'Associados') || (tipoModal === '') ? <Associados ass={ass} setAss={setAss} modal={true} ></Associados> :
                        (tipoModal === 'Fornecedores') || (tipoModal === '') ? <Fornecedores forn={forn} setForn={setForn} modal={true} ></Fornecedores> :        ''}
                    <div className="FrModalFooter">
                        <div className="FrModalButtonsDiv">

                            <button onClick={escondeModal}> Sair </button>
                        </div>
                    </div>

                </div>
            </Dialog>

            <button hidden={(showModal)} className="buttonF2" style={{ width: '35px', marginBottom:'10px', borderRadius: '4px' }} onClick={mostraModal}>
            <Icon icon={ic_search} size={20} />
            </button>



        </div>
    );
}

export default FrameF2;